import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useNavigate,
  Navigate
} from 'react-router-dom'
import Cookies from 'js-cookie'
import './App.css'
import './i18n'
import Login from './screens/Login'
import Register from './screens/Register'
import Admin from './screens/Admin'
import UpdatedBuyer from './screens/UpdatedBuyer'
import SideBar from './components/buyer/SideBar'
import BuyerRequest from './screens/buyer/BuyerRequest'
import BuyerRequestDetails from './screens/buyer/BuyerRequestDetails'
import BuyerUrgentCR from './screens/buyer/BuyerUrgentCR'
import MyTasks from './screens/buyer/MyTasks'
import NewPos from './screens/NewPos'
import PoDetailsUpdated from './screens/buyer/PoDetailsUpdated'
import VendorRequest from './screens/vendor/VendorRequest'
import VendorCRDetails from './screens/vendor/VendorCRDetails'
import VendorUrgentCR from './screens/vendor/VendorUrgentCR'
import VendorSidebar from './components/vendor/VendorSibebar'
import Test from './screens/Test'
import NewPoList from './screens/buyer/NewPoList'
import ConfirmPo from './screens/vendor/ConfirmPo'
import ReconfirmPo from './screens/vendor/ReconfirmPo'
import React, { useEffect, useRef, useState } from 'react'
import UrgentOrderBuyer from './screens/buyer/UrgentOrdersBuyer'
// import VendorContactCreate from "./screens/Admin/VendorContactCreate";
import CreateHFTUser from './screens/CreateHFTUser'
import AdminSideBar from './components/AdminSideBar'
import HFTUser from './screens/Admin/HFTUser'
import EditCOnfiguration from './screens/Admin/EditCOnfiguration'
import Roles from './screens/Admin/Roles'
import Group from './screens/Admin/Group'
import RoleMapping from './screens/Admin/RollMapping'

import MyPos from './screens/vendor/MyPos'
import Dashboard from './screens/buyer/Dashboard'
import CRHistory from './components/buyer/CRHistory'
import UrgentOrder from './screens/vendor/UrgentOrders'
import Notification from './screens/Notification'
import MyReport from './screens/buyer/MyReport'
import VendorMyTasks from './screens/vendor/VendorMyTasks'
import VendorDashboard from './screens/vendor/VendorDashboard'
import CreateSupplier from './screens/Admin/CreateSupplier'
import VendorList from './screens/Admin/VendorList'
import Header from './components/Header'
import PageNotFound from './screens/PageNotFound'
import Defer from './screens/vendor/Defer'
import Divert from './screens/vendor/Divert'
import Expedite from './screens/vendor/Expedite'
import SSOLogin from './screens/SSOLogin'
import ErrorPage from './screens/ServerErrorPage'
import SessionExpired from './screens/SessionExpired'
import EnsureAuthenticated from './components/EnsureAuthenticated'
import RefreshLogin from './screens/RefreshLogin'
import Configuration from './screens/Admin/Configuration'
import EmailConfig from './screens/Admin/EmailConfig'
import CronJobConfig from './screens/Admin/CronJobConfig'
import LoginErrorPage from './screens/ErrorPage'
import { isScreenBigForSideBar, isScreenTooSmall, ValidateAPI } from './utils/Utils'
import SerVerIssue from './screens/ServerIssue'
import axios from 'axios'

const AdminRoutes = () => {
  return (
    <>
      {/* <Route exact path='/admin' element={<AdminHome />} /> */}
      <Route
        exact
        path='/admin/vendor/vendor_contact_create'
      // element={<VendorContactCreate />}
      />
      <Route
        exact
        path='/admin/vendor/vendor-contact'
        element={<VendorList />}
      />
      <Route exact path='/admin/vendor-create' element={<CreateSupplier />} />
      <Route exact path='/admin/create_hft_user' element={<CreateHFTUser />} />
      <Route exact path='/admin/hft_user' element={<HFTUser />} />
      <Route
        exact
        path='/admin/configuration'
        element={<EditCOnfiguration />}
      />
      <Route exact path='/admin/roles' element={<Roles />} />
      <Route exact path='/admin/groups' element={<Group />} />
      <Route exact path='/admin/roleMapping' element={<RoleMapping />} />
      <Route exact path='/admin' element={<Admin />} />
    </>
  )
}

const UnauthorizedAlert = ({ message }) => {
  return (
    <alert>
      <p>{message}</p>
      {/* You can customize the appearance and content of the unauthorized access message here */}
    </alert>
  )
}

const Layout = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('userData')))
  const navigate = useNavigate()
  const [showSidebar, setShowSidebar] = useState(true)
  const [screenTooSmall, setScreenTooSmall] = useState(isScreenTooSmall());

  useEffect(() => {
    const handleResize = () => {
      setScreenTooSmall(isScreenTooSmall());
      setShowSidebar(isScreenBigForSideBar());
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  
  return (
    <div className='w-full h-screen flex flex-col font-pop'>
      {screenTooSmall ? (
        <div className='w-full h-full flex justify-center items-center'>
          <p className=' pl-4'
          >Your screen size is too small. If you are viewing from a phone, try rotating it.</p>
        </div>
      ) : (
        <>
          <Header showSidebar={setShowSidebar} />
          <div className='w-full flex justify-start items-start font-pop gap-4 relative'>
            {showSidebar && (
              <div
                className={`max-w-[220px] transition-transform duration-300 min-sidebar-screen ${showSidebar ? '' : 'transform -translate-x-full'}`}
              >
                {user && user.roleName === 'ADMIN_ROLE' && <AdminSideBar />}
                {user && user.roleName === 'BUYER_ROLE' && (
                  <div
                    className={`transition-transform duration-300 ${showSidebar ? '' : '-translate-x-full'}`}
                  >
                    <SideBar />
                  </div>
                )}
                {user && user.roleName === 'SUPPLIER_ROLE' && (
                  <div
                    className={`transition-transform duration-300 ${showSidebar ? '' : '-translate-x-full'}`}
                  >
                    <VendorSidebar />
                  </div>
                )}
              </div>
            )}

            <div
              className={`px-2 py-4 min-sidebar-screen w-full overflow-y-scroll sticky top-0`}
            >
              <Outlet />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function App() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('userData')))

  useEffect(() => {
    const handleStorageChange = () => {
      setUser(JSON.parse(localStorage.getItem('userData')))
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // console.log("user data updated: ", user)
  }, [user])

  
  // if (!window?.location?.pathname?.includes('/vendor/my_tasks')) {
  //   if (user?.roleName === undefined || user?.roleName === null) {
  //     return (
  //       <Router>
  //         <Routes>
  //           <Route path='/*' element={<Login updateUser={setUser} />} />
  //         </Routes>
  //       </Router>
  //     )
  //   }
  // }

  const [token, setToken] = useState(null);
  const lastActivityRef = useRef(Date.now());
  const initialLoadRef = useRef(true)
  // Track user activity
  const resetUserActivity = () => {
    lastActivityRef.current = Date.now();
  };

  useEffect(() => {
    window.addEventListener('mousemove', resetUserActivity);
    window.addEventListener('keydown', resetUserActivity);

    return () => {
      window.removeEventListener('mousemove', resetUserActivity);
      window.removeEventListener('keydown', resetUserActivity);
    };
  }, []);



  return (
    <>
       <Routes>
         <Route element={<EnsureAuthenticated />}>
          {user?.roleName === 'BUYER_ROLE' ? (
            <Route exact path='/' element={<Layout />} >
               <Route exact path='/' element={<Dashboard />} />
              <Route exact path='/buyer' element={<Dashboard />} />
              <Route exact path='/buyer/po_list' element={<NewPoList />} />
              <Route
                exact
                path='/buyer/po_list/po_record/:id'
                element={<PoDetailsUpdated />}
              />
              <Route
                exact
                path='/buyer/hft_buyer_change_requests'
                element={<BuyerRequest />}
              />
              <Route
                exact
                path='/buyer/hft_buyer_change_requests/request_details/:id'
                element={<BuyerRequestDetails />}
              />
              <Route
                exact
                path='/buyer/urgent_orders/request_details/:id'
                element={<BuyerUrgentCR />}
              />
              <Route
                exact
                path='/buyer/urgent_orders'
                element={<UrgentOrderBuyer />}
              />
              <Route exact path='/buyer/open_tasks' element={<MyTasks />} />
              <Route exact path='/buyer/my_report' element={<MyReport />} />
              <Route exact path='/buyer/new_pos' element={<NewPos />} />
            </Route>
          ) : (
            user?.roleName === "ADMIN_ROLE" ? (
              <Route exact path='/' element={<Layout />}>
                <Route exact path='/' element={<VendorList />} />
                <Route exact path='/admin' element={<VendorList />} />
                <Route
                  exact
                  path='/admin/vendor/vendor_contact_create'
                // element={<VendorContactCreate />}
                />
                <Route
                  exact
                  path='/admin/vendor/vendor-contact'
                  element={<VendorList />}
                />
                <Route exact path='/admin/vendor-create' element={<CreateSupplier />} />
                <Route exact path='/admin/create_hft_user' element={<CreateHFTUser />} />
                <Route exact path='/admin/hft_user' element={<HFTUser />} />
                <Route
                  exact
                  path='/admin/configuration'
                  element={<Configuration />}
                />
                 <Route exact path='/admin/cron-job-config' element={<CronJobConfig />} />
                 <Route exact path='/admin/email-temp-config' element={<EmailConfig />} />
                <Route exact path='/admin/roles' element={<Roles />} />
                <Route exact path='/admin/groups' element={<Group />} />
                <Route exact path='/admin/roleMapping' element={<RoleMapping />} />
                <Route exact path='/admin' element={<Admin />} />
              </Route>
            ) : (
              <Route exact path='/' element={<Layout />}>
                <Route exact path='/' element={<VendorDashboard />} />
                <Route exact path='/vendor' element={<VendorDashboard />} />
                <Route
                  exact
                  path='/vendor/po_list/po_record/:id'
                  element={<PoDetailsUpdated />}
                />
                <Route
                  exact
                  path='/vendor/my_tasks/:requestType?'
                  element={<VendorMyTasks />}
                />
                <Route exact path='/vendor/open_pos' element={<MyPos />} />
                <Route exact path='/vendor/po_confirm' element={<ConfirmPo />} />
                <Route
                  exact
                  path='/vendor/po_reconfirm'
                  element={<ReconfirmPo />}
                />
                <Route
                  exact
                  path='/vendor/urgent_orders'
                  element={<UrgentOrder />}
                />
                <Route
                  exact
                  path='/vendor/po_change_requests'
                  element={<VendorRequest />}
                />
                <Route exact path='/vendor/defer-request' element={<Defer />} />
                <Route exact path='/vendor/divert-request' element={<Divert />} />
                <Route
                  exact
                  path='/vendor/expedite-request'
                  element={<Expedite />}
                />
                <Route
                  exact
                  path='/vendor/po_change_requests/request_details/:id'
                  element={<VendorCRDetails />}
                />
                <Route
                  exact
                  path='/vendor/urgent_orders/request_details/:id'
                  element={<VendorUrgentCR />}
                />
              </Route>
            )
          )}
          </Route>
          <Route exact path='/notification' element={<Notification />} />
          <Route exact path='/login' element={<Login updateUser={setUser} />} />
          <Route path="/session-expired" element={<SessionExpired />} />
          <Route path="/refresh-login" element={<RefreshLogin />} />
          <Route
            exact
            path='/sso-login'
            element={<SSOLogin updateUser={setUser} />}
          />
          <Route exact path='/register' element={<Register />} />
          <Route
            exact
            path='/cr_history/:poNumber/:sku'
            element={<CRHistory />}
          />
          <Route path='/error' element={<ErrorPage />} />
          <Route path='/login-error' element={<LoginErrorPage />} />
          <Route path='/server-error' element={<SerVerIssue />} />
          <Route path='/*' element={<PageNotFound />} />
        </Routes>
    </>
  )
}

export default App
