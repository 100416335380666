import React, { useState, useEffect } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  PaginationState,
  SortingState,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues
} from '@tanstack/react-table'

import {
  RankingInfo,
  rankItem,
  compareItems
} from '@tanstack/match-sorter-utils'
import { CalculateTableArray, Filter, downloadCSV } from './../utils/Utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CommonAPICall } from '../CommonAPiContext/context'

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

const BasicTable = ({ tableId, tableData,
  columns, setColumnVisibility,
  columnVisibility, colheight = 9, hftid, filterData={}, setFilterData
}) => {
  
  const location = useLocation()
  const [data, setData] = useState(tableData ?? [])
  const paramname = useLocation()
  const setPathName = paramname?.pathname?.split('/')
  const [csvFileName, setFileName] = useState(setPathName[2])
  const [sorting, setSorting] = useState([]);
  const [selectedArray, setSelectedArray] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const { t } = useTranslation()
  const { Searchallcolumns, CreateChangeRequest
    , Page, Gotopage, Show } = t("BasicTable")
  const [values, setValues] = useState([]);

  const [columnResizeMode, setColumnResizeMode] = React.useState('onChange');

  const [columnResizeDirection, setColumnResizeDirection] = React.useState('ltr');
  // console.log(tableId);
  const [ClearState, setClearState] = useState(false)
  const {addFilterData } = CommonAPICall()
  const table = useReactTable({
    data,
    columns,
    columnResizeMode,
    columnResizeDirection,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      globalFilter
    },
    defaultColumn: {
      size: 50, //starting column size
      // minSize: 50, //enforced during column resizing
      // maxSize: 500, //enforced during column resizing
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
    // debugTable: true,
  })

  //   const requiredOutput = () => {
  //     const selectedRowRaw = table?.getSelectedRowModel().flatRows;
  //     const requiredData = [];
  //     selectedRowRaw?.map((row, index) => requiredData.push(row?.original));
  //     selection(requiredData);
  //     return requiredData;
  //   };

  useEffect(() => {
    setData(tableData ?? [])
    // if (location?.state?.includes("dashboard") && location?.pathname?.includes("/vendor/urgent_orders")) {
    //   setColumnFilters([
    //     {
    //       "id": "supplierResponse",
    //       "value": "Awaiting Response"
    //     },
    //     {
    //       "id": "status",
    //       "value": "OPEN"
    //     }
    //   ])
    // }
    // else if (location?.state?.includes("dashboard")) {
    //   setColumnFilters([
    //     {
    //     "id": "supplier_response",
    //     "value": "Awaiting Response"
    //   },
    //   {
    //     "id": "status",
    //     "value": "OPEN"
    //   }
    // ])
    // }

    if (
      location?.pathname?.includes('/vendor/po_change_requests') &&
      location?.state
    ) {
      console.log( location?.state);
      
      setColumnFilters(location?.state)
    } 
    // else if (location?.state?.includes('dashboard')) {

    //   setColumnFilters([
    //     {
    //       id: 'supplier_response',
    //       value: 'Awaiting Response'
    //     },
    //     {
    //       id: 'status',
    //       value: 'OPEN'
    //     }
    //   ])

    // }
    //   const tableid = tableId
    //   const id = location[0]?.id
    //   localStorage.setItem('all_selected_values',JSON.stringify({
    //     "VendorChangeReqTable_request_type": [
    //         {
    //             "value": location?.state[0]?.value,
    //             "label": location?.state[0]?.value
    //         }
    //     ],
    // }))
    // console.log('columnFilters0', table)
  }, [tableData])

  // generate csv data
  const generateCSVData = (table) => {
    const data = table?.getFilteredRowModel()?.rows?.map((row) => {
      return row?.original
    })
    return data
  }
  //   useEffect(() => {
  //     console.log("selected actual data : ", requiredOutput());
  //   }, [rowSelection]);
  const clearFilter = () => {
    setColumnFilters([])
  }

  const cleared = () => {
    // const localStorageKey = 'all_selected_values'
    // localStorage.removeItem(localStorageKey)
    setColumnFilters([])
    setClearState((prevState) => !prevState) // Toggle the state
    if(hftid){
      addFilterData(
        {
          "hftUserId": hftid,
          "tableName": tableId,
          "clFilter": []
        }
      )
      setFilterData({})
    }
  }
  // useEffect(() => {
  //   const localStorageKey = 'all_selected_values';
  //   localStorage.removeItem(localStorageKey);  // Clear localStorage
  //   setColumnFilters([]);
  //   // setFilterData([]);
  // }, []);



  const handleColumnResizeEnd = (header, tableId) => {
    const newWidth = header.getSize();
    const storedWidths = JSON.parse(localStorage.getItem('column-widths')) || {};
    if (!storedWidths[tableId]) {
      storedWidths[tableId] = {};
    }
    storedWidths[tableId][header.id] = newWidth;
    localStorage.setItem('column-widths', JSON.stringify(storedWidths));
  };

  const loadColumnWidths = (columns, tableId) => {
    const storedWidths = JSON.parse(localStorage.getItem('column-widths')) || {};
    if (storedWidths[tableId]) {
      columns.forEach(column => {
        if (storedWidths[tableId][column.id]) {
          column.columnDef.size = Number(storedWidths[tableId][column.id]);
        }
      });
    }
  };

  useEffect(() => {
    loadColumnWidths(table.getAllColumns(), tableId);
    // localStorage.removeItem('column-widths'); // Uncomment if you want to clear storage
  }, [table]);

  // When using handleColumnResizeEnd, make sure to pass the tableId
  const onResizeEnd = (header) => {
    handleColumnResizeEnd(header, tableId);
  };

  // Example usage in your column definition or where you handle resize
  columns.forEach(column => {
    column.onResizeEnd = onResizeEnd;
  });

  useEffect(() => {
    // Event listener to remove item from localStorage on window reload
    const handleBeforeUnload = (event) => {
      // Custom logic to determine if you want to clear localStorage on reload
      localStorage.removeItem('column-widths');
    };
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount



  return (
    <div className='w-full flex justify-start items-start text-xs flex-col gap-4 max-h-screen'>
      <div className='w-full justify-between flex items-center text-xs'>
        <div>
          <input
            value={globalFilter ?? ''}
            onChange={(e) => setGlobalFilter(e.target.value)}
            className='p-1 py-2 rounded-sm border border-gray-300 outline-none'
            placeholder={Searchallcolumns}
          />
        </div>
        {selectedArray?.length > 0 && (
          <div className=''>
            <button
              className='bg-[#415da1] px-4 h-[30px] rounded-md outline-none text-white min-w-max'
              onClick={() => setShowConfirm(!showConfirm)}
            >
              {CreateChangeRequest}
            </button>
          </div>
        )}
        {/*Pagination */}

        <div className="flex items-center gap-2">
          {columnFilters.length > 0 && (
            <div>
              <button
                className="font-medium text-white outline-none bg-[#415da1] w-[90px] rounded-md h-[30px]"
                onClick={() => {
                  setColumnFilters([]);
                  cleared()
                }}
              >
                Clear Filters
              </button>
            </div>
          )}          {
            data?.length !== 0
            &&
            downloadCSV(csvFileName, generateCSVData(table))
          }

          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span className='min-w-max flex items-center gap-1'>
            <div>{Page}</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </strong>
          </span>
          <span className='min-w-max flex items-center gap-1'>
            | {Gotopage}
            <input
              type='number'
              id='pagination_input'
              name='pagination_input'
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                table.setPageIndex(page)
              }}
              className='border p-1 rounded w-16'
            />
          </span>
          <select
            className='border p-1 rounded'
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {CalculateTableArray(table?.getFilteredRowModel()?.rows?.length).map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {Show} {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* <div className={`w-full overflow-x-auto ${window?.location?.pathname?.includes('/po_list/po_record')?' min-h-[5cm]':'min-h-[14cm]'} `}>
        <table className="text-xs border border-gray-300 relative">
          <thead className="sticky top-0 z-10"
          >
            {table?.getHeaderGroups()?.map((headerGroup, index) => (
              <tr
                key={headerGroup?.id + index}
                className='divide-x-[1px] bg-[#e0ecfc] divide-gray-300 border-[#ccd9e4] border'
              >
                {headerGroup?.headers?.map((header) => (
                  <th key={header?.id} className='font-medium px-2 py-3 sticky top-0'
                  {...{
                    key: header.id,
                    colSpan: header.colSpan,
                    style: {
                      width: header.getSize(),
                    },
                  }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className='w-full flex flex-col items-center justify-center gap-2'
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler()
                        }}
                      >
                        <div className="flex justify-center items-center"> 
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          &nbsp;&nbsp;
                          <div >
                            {{
                              asc: (
                                <svg
                                  className="w-3 h-4 text-gray-500"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 10 14"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 13V1m0 0L1 5m4-4 4 4"
                                  />
                                </svg>
                              ),
                              desc: (
                                <svg
                                  className="w-3 h-4 text-gray-500"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 10 14"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 1v12m0 0 4-4m-4 4L1 9"
                                  />
                                </svg>
                              ),
                            }[header.column.getIsSorted()] ?? (
                                <svg
                                  className="w-3 h-4 text-gray-500"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 16 20"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3"
                                  />
                                </svg>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                    {header.column.getCanFilter() ? (
                      <div>
                        <Filter column={header.column} table={table} columnFilters={columnFilters} values={values} setValues={setValues} header={header} />
                      </div>
                    ) : null}
                          <div
                            onDoubleClick={() => header.column.resetSize()}
                            onMouseDown={(e) => {
                              header.getResizeHandler()(e);
                              document.addEventListener('mouseup', () => handleColumnResizeEnd(header), { once: true });
                            }}
                            onTouchStart={(e) => {
                              header.getResizeHandler()(e);
                              document.addEventListener('touchend', () => handleColumnResizeEnd(header), { once: true });
                            }}
                            className={`resizer ${table.options.columnResizeDirection} ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                            style={{
                              transform: columnResizeMode === 'onEnd' && header.column.getIsResizing()
                                ? `translateX(${(table.options.columnResizeDirection === 'rtl' ? -1 : 1) * (table.getState().columnSizingInfo.deltaOffset ?? 0)}px)`
                                : '',
                            }}
                          />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table?.getRowModel().rows?.map((row, index) => {
              const rowClassName =
                index % 2 !== 0 ? 'bg-[#ecedf2]' : 'bg-[#ffffff]'
              return (
                <tr
                  key={row?.id + index}
                  className={`${rowClassName} text-center divide-x-[1px] divide-gray-300 border border-gray-300`}
                >
                  {row?.getVisibleCells()?.map((cell) => (
                    <td className='px-2 py-3'
                    {...{
                      key: cell.id,
                      style: {
                        width: cell.column.getSize(),
                      },
                    }}
                >
                      {flexRender(
                        cell?.column?.columnDef?.cell,
                        cell?.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div> */}


      <div className='w-full overflow-x-auto' style={{ minHeight: '70vh', minWidth: '660px' }}>
        <table className='text-xs border border-gray-300 w-full'>
          <thead
            className='sticky top-0 z-10'
          >
            {table.getHeaderGroups()?.map((headerGroup, index) => (
              <tr
                key={headerGroup?.id + index}
                className='divide-x-[1px] bg-[#e0ecfc] divide-gray-300 border-[#ccd9e4] border'
              >
                {headerGroup?.headers?.map((header) => (
                  <th
                    key={header?.id}
                    className={`font-medium p-2 sticky top-0 `}
                    {...{
                      key: header.id,
                      colSpan: header.colSpan,
                      style: {
                        width: header.getSize(),
                      },
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler()
                        }}
                      >
                        <div className={`flex justify-center h-${colheight}`}>
                          {' '}
                          {/* Container with Flexbox */}
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          &nbsp;&nbsp;
                          {/* <div>
                            {{
                              asc: (
                                <svg
                                  className='w-3 h-4 text-gray-500'
                                  // className="w-4 h-5 text-gray-500" // Increased size with w-4 and h-5
                                  aria-hidden='true'
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 10 14'
                                // viewBox="0 0 14 20" // Adjusted viewBox for larger arrow
                                >
                                  <path
                                    stroke='currentColor'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='M5 13V1m0 0L1 5m4-4 4 4'
                                  // d="M1 5h12m0 0-4 4m4-4L9 1"
                                  />
                                </svg>
                              ),
                              desc: (
                                <svg
                                  className='w-3 h-4 text-gray-500'
                                  // className="w-4 h-5 text-gray-500" // Increased size with w-4 and h-5
                                  aria-hidden='true'
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 10 14'
                                // viewBox="0 0 14 20" // Adjusted viewBox for larger arrow
                                >
                                  <path
                                    stroke='currentColor'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='M5 1v12m0 0 4-4m-4 4L1 9'
                                  // d="M13 5H1m0 0 4 4m-4-4 3-3m0 6V1m0 0 3 3m-3-3 3 3"
                                  />
                                </svg>
                              )
                            }[header.column.getIsSorted()] ?? (
                                <svg
                                  className='w-3 h-4 text-gray-500'
                                  aria-hidden='true'
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 16 20'
                                >
                                  <path
                                    stroke='currentColor'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3'
                                  // d="M4 6h10m0 0-3-3m3 3-3 3m1 8H1m0 0 3-3m-3 3 3 3"
                                  />
                                </svg>
                              )}
                          </div> */}
                          {header.column.columnDef.header !== 'SL_NO' && (
                            <div>
                              {{
                                asc: (
                                  <svg
                                    className='w-3 h-4 text-gray-500'
                                    aria-hidden='true'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 10 14'
                                  >
                                    <path
                                      stroke='currentColor'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      strokeWidth='2'
                                      d='M5 13V1m0 0L1 5m4-4 4 4'
                                    />
                                  </svg>
                                ),
                                desc: (
                                  <svg
                                    className='w-3 h-4 text-gray-500'
                                    aria-hidden='true'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 10 14'
                                  >
                                    <path
                                      stroke='currentColor'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      strokeWidth='2'
                                      d='M5 1v12m0 0 4-4m-4 4L1 9'
                                    />
                                  </svg>
                                )
                              }[header.column.getIsSorted()] ?? (
                                  <svg
                                    className='w-3 h-4 text-gray-500'
                                    aria-hidden='true'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 16 20'
                                  >
                                    <path
                                      stroke='currentColor'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      strokeWidth='2'
                                      d='M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3'
                                    />
                                  </svg>
                                )}
                            </div>
                          )}

                        </div>
                      </div>
                    )}
                    {header.column.getCanFilter() ? (
                      <div>
                        <Filter
                          value={location?.state}
                          column={header.column}
                          table={table} columnFilters={columnFilters}
                          values={values}
                          setValues={setValues}
                          header={header}
                          tableId={tableId}
                          ClearState={ClearState}
                          hftid={hftid}
                          filterValue={filterData}
                        />

                      </div>
                    ) : null}

                    <div
                      onDoubleClick={() => header.column.resetSize()}
                      onMouseDown={(e) => {
                        header.getResizeHandler()(e);
                        document.addEventListener('mouseup', () => handleColumnResizeEnd(header), { once: true });
                      }}
                      onTouchStart={(e) => {
                        header.getResizeHandler()(e);
                        document.addEventListener('touchend', () => handleColumnResizeEnd(header), { once: true });
                      }}
                      className={`resizer ${table.options.columnResizeDirection} ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                      style={{
                        transform: columnResizeMode === 'onEnd' && header.column.getIsResizing()
                          ? `translateX(${(table.options.columnResizeDirection === 'rtl' ? -1 : 1) * (table.getState().columnSizingInfo.deltaOffset ?? 0)}px)`
                          : '',
                      }}
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table?.getRowModel().rows?.map((row, index) => {
              const rowClassName =
                index % 2 !== 0 ? 'bg-[#ecedf2]' : 'bg-[#ffffff]'
              return (
                <tr
                  key={'mypos-tr' + index}
                  className={`${rowClassName} text-center divide-x-[1px] divide-gray-300 border border-gray-300 
                    ${row.getIsSelected() ? 'bg-blue-200' : ''}
                    `}
                >
                  {row?.getVisibleCells()?.map((cell) => (
                    <td
                      className={`px-2 py-2`}
                      {...{
                        key: cell.id,
                        style: {
                          width: cell.column.getSize(),
                        },
                      }}
                    >
                      {flexRender(
                        cell?.column?.columnDef?.cell,
                        cell?.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>


    </div>
  )
}

export default BasicTable
