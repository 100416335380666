import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { clearLocalStorage, deleteCookie } from '../utils/Utils'
import LandingPage from '../components/LandingPage'

const SessionExpired = () => {
  const navigate = useNavigate()

  useEffect(() => {
    deleteCookie('idcs_id_token')
    deleteCookie('idcs_user_assertion')
    deleteCookie('role')
    clearLocalStorage()
    setTimeout(() => {
      navigate('/login')
    }, 2000) // Wait for 2 seconds before redirecting
  }, [navigate])

  return (
    //replaced this message
    // <div>
    //   <h1>Your session seems to have expired, logging you out...</h1>
    // </div>
     <LandingPage>
     <div className="flex flex-col items-center justify-center ">
           <div className="bg-white p-6 rounded-lg "> 
             <h1 className="text-2xl font-bold mb-4">Session timed out!</h1>
             {/* Removed old message from here  */}
          
           </div>
         </div>
    
     </LandingPage>
  )
}

export default SessionExpired
