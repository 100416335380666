import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import UserProfile from '../../components/UserProfile'
import { formatDateFit } from '../buyer/BuyerRequestDetails'
import DatePicker from 'react-datepicker'
import { getWeek } from '../buyer/NewPoList'
import 'react-datepicker/dist/react-datepicker.css'
import { handleDownload, ValidateAPI } from '../../utils/Utils'
import {
  reasonCodes,
  reasonCodesEN,
  reasonCodesMN,
  updateActionHistory
} from '../../utils/Utils'
import Comments from '../../components/Comments'
import Stepper from '../../components/Stepper'
// import { useTranslation } from "react-i18next";
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import StepperUrgentOrder from '../../components/StepperUrgentOrder'

const VendorUrgentCR = () => {
  const { id } = useParams()
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [reqAction, setReqAction] = useState(null)
  const [reasonArray, setReasonArray] = useState([])
  const [showAcceptDatePicker, setShowAcceptDatePicker] = useState(false)
  const [date, setDate] = useState(new Date())
  const [reason_codes, setReasonCode] = useState([])
  const { t } = useTranslation()
  const {
    POChangeRequestDetails,
    Home,
    POChangeRequests,
    ChangeRequest,
    ReasonCode,
    VendorResponse,
    RequestType,
    CreateDate,
    LastUpdateDate,
    POChangeRequest,
    SKU,
    Description,
    RequestedSW,
    CurrentConfirmSW,
    DC,
    QTY,
    LastUpdated,
    Action,
    AreyousureyouwanttoacceptthePOchangerequest,
    Changetheshipweek,
    SelctedShipWeek,
    SelectShipweek,
    Confirm,
    Cancel,
    Pleaseselectreasoncodeforrejectingtheorder,
    Selectareason
  } = t('VendorUrgentCR')

  const handleReasonChange = (selectedReason) => {
    if (!reasonArray.includes(selectedReason)) {
      setReasonArray((prevReasonArray) => [...prevReasonArray, selectedReason])
    }
  }

  const steps = ['Awaiting Response', 'Vendor Responded', 'Closed']
  const stepsCancel = [
    'Awaiting Response',
    'Cancelled',
    'Vendor Responded',
    'Closed'
  ]
  const [requestData, setRequestData] = useState()
  const [status, setStatus] = useState('')
  const currentStepIndex = steps?.indexOf(status)
  const currentStepIndexCancel = stepsCancel?.indexOf(status)
  const navigate = useNavigate()
  const [requestSW, setRequestSW] = useState(null)

  const [commentsData, setCommentsData] = useState([])
  const [commentInput, setCommentInput] = useState('')

  const getStartDate = (weekString) => {
    const [week, year] = weekString.split('.').map(Number)
    const januaryFirst = new Date(year, 0, 1)
    const dayOffset = (8 - januaryFirst.getDay()) % 7
    const calculatedDate = new Date(
      januaryFirst.getTime() +
        (week - 1) * 7 * 24 * 60 * 60 * 1000 +
        dayOffset * 24 * 60 * 60 * 1000
    )
    calculatedDate.setFullYear(year + 2000)
    console.log('Calculated Date:', calculatedDate)
    return calculatedDate
  }

  const handleChangeDate = (date) => {
    setDate(date)
    setRequestSW(getWeek(date))
  }

  const nullOriginal = (requestedShipWeek) => {
    const [requestedWeek, requestedYear] = requestedShipWeek
      ?.split('.')
      ?.map(Number)
    const newWeek = requestedWeek + 4
    const originalShipWeek = `${newWeek}.${requestedYear}`
    return getStartDate(originalShipWeek)
  }

  const fetchRequestData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/po/urgentOrderByHFTID?hftId=${id}`,ValidateAPI()
      )
      .then((response) => {
        console.log('response from request: ', response?.data)
        setRequestData(response?.data)
        if (response?.data?.supplierResponse === 'Accept') {
          setReqAction('Accept')
        } else if (response?.data?.supplierResponse === 'Reject') {
          setReqAction('Reject')
        }
      })
      .catch((error) => {
        if(error.response.status===401 || error.response.status===403){
          navigate('/login-error')
        }
      })
  }

  const handleAcceptRequest = (action) => {
    const finalpayload = {
      ...requestData,
      status: action,
      vendorSelectedShipweek: requestSW
    }
    console.log(finalpayload)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/po/UrgentPoApproveOrReject`,
        finalpayload
      )
      .then((res) => {
        setShowAcceptModal(false)
        setShowRejectModal(false)
        fetchRequestData()
        setRequestSW(null)
        updateActionHistory('Accepted', [finalpayload])
        window?.location?.reload()
      })
      .catch((err) => {
        // alert("error while accepting the request" + err);
        alert(i18next.t('VendorPopUp.errorwhileacceptingtherequest' + err))
      })
  }

  const handleRejectRequest = (action) => {
    if (!reasonArray?.length > 0)
      return alert(i18next.t('VendorPopUp.Pleaseselectareasoncode'))
    const reasons = reasonArray.join(', ')

    const finalpayload = {
      ...requestData,
      status: action,
      reasonCode: reasons
    }
    console.log(finalpayload)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/po/UrgentPoApproveOrReject`,
        finalpayload
      )
      .then((res) => {
        setShowAcceptModal(false)
        setShowRejectModal(false)
        fetchRequestData()
        updateActionHistory('Rejected', [finalpayload])
        window?.location?.reload()
      })
      .catch((err) => {
        alert(i18next.t('VendorPopUp.errorwhileacceptingtherequest' + err))
      })
  }
  // GET reason code for en and mn
  useEffect(() => {
    const handleStorage = () => {
      if (window.localStorage?.getItem('language') === 'en') {
        setReasonCode(reasonCodesEN)
      } else {
        setReasonCode(reasonCodesMN)
      }
    }

    window.addEventListener('storage', handleStorage())
    return () => window.removeEventListener('storage', handleStorage())
  }, [window.localStorage?.getItem('language')])
  useEffect(() => {
    fetchRequestData()
  }, [id])

  useEffect(() => {
    if (requestData?.status?.toLowerCase() === 'open') {
      if (requestData?.supplierResponse === 'Awaiting Response') {
        setStatus('Awaiting Response')
      } else if (
        requestData?.supplierResponse === 'Accept' ||
        requestData?.supplierResponse === 'ACCEPTED'
      ) {
        setStatus('Vendor Responded')
      } else if (
        requestData?.supplierResponse === 'Reject' ||
        requestData?.supplierResponse === 'REJECTED'
      ) {
        setStatus('Vendor Responded')
      }
    } else if (requestData?.status?.toLowerCase() === 'closed') {
      setStatus('Closed')
    } else {
      setStatus('Cancelled')
    }
  }, [requestData?.supplier_response, requestData?.status])
  return (
    <div className='w-full flex flex-col gap-6 justify-start items-start p-4'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl font-medium'>{POChangeRequestDetails}</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/vendor')}
          >
            {Home}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/vendor/po_change_requests')}
          >
            {POChangeRequestDetails}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span>
            {ChangeRequest} - {id}
          </span>
        </p>
      </div>
      {/*Stepper to show current status */}
      {/* <div className="w-full flex justify-center items-start relative">
        <div className="w-[70%] border border-gray-200 absolute top-3" />
        <div className="w-[80%] flex justify-between items-center">
          {(requestData?.status?.toLowerCase() === "cancelled"
            ? stepsCancel
            : steps
          )?.map((step, index) => (
            <div
              className="flex flex-col z-40 justify-start items-center gap-2 h-[100px]"
              key={index + step}
            >
              <div
                className={`rounded-full border-2 p-1 ${
                  (requestData?.status?.toLowerCase() === "cancelled"
                    ? currentStepIndexCancel
                    : currentStepIndex) >= index
                    ? "border-blue-600"
                    : "border-gray-300"
                } w-6 h-6 flex justify-center items-center `}
              >
                <div
                  className={`p-1.5 rounded-full ${
                    (requestData?.status?.toLowerCase() === "cancelled"
                      ? currentStepIndexCancel
                      : currentStepIndex) >= index
                      ? "bg-blue-600"
                      : "bg-gray-300"
                  }`}
                ></div>
              </div>
              <p
                className={`${
                  (requestData?.status?.toLowerCase() === "cancelled"
                    ? currentStepIndexCancel
                    : currentStepIndex) >= index
                    ? "text-blue-600"
                    : "text-gray-400"
                } text-center text-xs font-medium w-[160px]`}
              >
                {step}
              </p>
              <p
                  className={`${
                    (requestData?.status?.toLowerCase() === "cancelled"
                      ? currentStepIndexCancel
                      : currentStepIndex) >= index
                      ? "text-blue-600"
                      : "text-gray-400"
                  } text-center text-xs font-medium w-[160px]`}
                  >
                    {step==="Awaiting Response" &&formatDateFit(requestData?.date)}
                   { requestData?.status?.toLowerCase() === "cancelled" ?
                   index==currentStepIndexCancel &&formatDateFit(requestData?.lastUpdate) :
                   index==currentStepIndex &&formatDateFit(requestData?.lastUpdate)
                   }
                    </p>
            </div>
          ))}
        </div>
      </div> */}
      <StepperUrgentOrder
        status={status}
        crId={requestData?.idopenrequest}
        iscancelled={requestData?.status?.toLowerCase() === 'cancelled'}
      />
      <div className='w-full border border-[#E6EDFF] rounded-md p-3'>
        <table className='w-full'>
          <tbody className='divide-y-[1px] divide-[#E6EDFF]'>
            <tr className='text-center'>
              <td className='text-xs p-2 py-4'>
                <div className='flex flex-col gap-1'>
                  <p className='font-medium'>{ReasonCode}</p>
                  <p>{requestData?.reasonCode}</p>
                </div>
              </td>
              <td className='text-xs p-2 py-4'>
                <div className='flex flex-col gap-1'>
                  <p className='font-medium'>{VendorResponse}</p>
                  <p>{requestData?.supplierResponse}</p>
                </div>
              </td>
              <td className='text-xs p-2 py-4'>
                <div className='flex flex-col gap-1'>
                  <p className='font-medium'>{RequestType}</p>
                  <p className='capitalize'>{requestData?.potype}</p>
                </div>
              </td>
              <td className='text-xs p-2 py-4'>
                <div className='flex flex-col gap-1'>
                  <p className='font-medium'>{CreateDate}</p>
                  <p>{formatDateFit(requestData?.date)}</p>
                </div>
              </td>
              <td className='text-xs p-2 py-4'>
                <div className='flex flex-col gap-1'>
                  <p className='font-medium'>{LastUpdateDate}</p>
                  <p>{formatDateFit(requestData?.lastUpdate)}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='w-full flex justify-start items-start mt-1 flex-col gap-4'>
        <div>
          <p className='font-semibold text-xl'>{POChangeRequest}</p>
        </div>
        <div className='w-full p-3 border border-[#E6EDFF] rounded-md'>
          <table className='w-full'>
            <thead>
              <tr className='border-b border-[#E6EDFF]'>
                <th className='p-2 py-4 font-medium text-xs'>{SKU}</th>
                <th className='p-2 py-4 font-medium text-xs'>{Description}</th>
                <th className='p-2 py-4 font-medium text-xs'>{RequestedSW}</th>
                <th className='p-2 py-4 font-medium text-xs'>
                  {CurrentConfirmSW}
                </th>
                <th className='p-2 py-4 font-medium text-xs'>{QTY}</th>
                <th className='p-2 py-4 font-medium text-xs'>{DC}</th>
                <th className='p-2 py-4 font-medium text-xs'>{LastUpdated}</th>
                <th className='p-2 py-4 font-medium text-xs'>{Action}</th>
              </tr>
            </thead>
            <tbody>
              <tr className='text-center'>
                <td className='p-2 py-4 text-xs'>{requestData?.sku}</td>
                <td className='p-2 py-4 text-xs'>
                  {requestData?.skuDescription}
                </td>
                <td className='p-2 py-4 text-xs'>{requestData?.shipweek}</td>
                <td className='p-2 py-4 text-xs'>
                  {requestData?.supplierResponse === 'ACCEPTED'
                    ? requestData?.vendorSelectedShipweek
                      ? requestData?.vendorSelectedShipweek
                      : requestData?.shipweek
                    : ''}
                </td>
                <td className='p-2 py-4 text-xs'>{requestData?.quantity}</td>
                <td className='p-2 py-4 text-xs'>{requestData?.dcValue}</td>
                <td className='p-2 py-4 text-xs'>
                  {formatDateFit(requestData?.lastUpdate)}
                </td>
                <td className='p-2 py-4 text-xs'>
                  {requestData?.status === 'OPEN' ? (
                    <div className='flex gap-2 justify-center items-center'>
                      <button onClick={() => setShowAcceptModal(true)}>
                        <svg
                          className='w-6 h-6 text-green-600 '
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                        >
                          <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
                        </svg>
                      </button>
                      <button onClick={() => setShowRejectModal(true)}>
                        <svg
                          className='w-6 h-6 text-red-500'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                        >
                          <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z' />
                        </svg>
                      </button>
                    </div>
                  ) : (
                    <div className='flex justify-center items-center'>
                      {requestData?.supplierResponse === 'ACCEPTED' && (
                        <svg
                          className='w-6 h-6 text-green-600 '
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                        >
                          <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
                        </svg>
                      )}
                      {requestData?.supplierResponse === 'REJECTED' && (
                        <svg
                          className='w-6 h-6 text-red-500'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                        >
                          <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z' />
                        </svg>
                      )}
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/*Accept Request Dialog Box */}
      <dialog
        open={showAcceptModal}
        className='p-3 inset-0 fixed z-40 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
            <div className='w-full flex justify-end items-center'>
              <button
                className='border-2 border-gray-300 p-2 rounded-md text-sm'
                onClick={() => setShowAcceptModal(false)}
              >
                ❌
              </button>
            </div>
            <div className='w-full flex justify-center items-center mt-2 text-center'>
              <p className='text-sm font-medium'>
                {AreyousureyouwanttoacceptthePOchangerequest}
              </p>
            </div>
            <div className='text-xs flex gap-2'>
              <input
                type='checkbox'
                name='date_change_cb'
                id='date_change_cb'
                value={showAcceptDatePicker}
                onChange={(e) => setShowAcceptDatePicker(e?.target?.checked)}
              />
              <label htmlFor='date_change_cb'>{Changetheshipweek}</label>
            </div>
            {showAcceptDatePicker && (
              <div className='flex flex-col gap-3 text-xs w-full'>
                <div className='flex justify-between items-center w-full'>
                  <p>{SelectShipweek}</p>
                  <DatePicker
                    showWeekNumbers
                    filterDate={(date) => date.getDay() === 1}
                    selected={date}
                    onChange={(date) => handleChangeDate(date)}
                    className='p-1 border border-gray-300 rounded-md'
                    calendarStartDay={1}
                    minDate={new Date()}
                    showYearDropdown
                  />
                </div>
                <div className='w-full flex justify-between items-center'>
                  <p>{SelctedShipWeek}</p>
                  <p>{requestSW}</p>
                </div>
              </div>
            )}
            <div className='w-full flex justify-center items-center gap-4 mt-4'>
              <button
                className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-green-600 outline-none'
                onClick={() => handleAcceptRequest('APPROVED')}
              >
                {Confirm}
              </button>
              <button
                className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-red-500 outline-none'
                onClick={() => setShowAcceptModal(false)}
              >
                {Cancel}
              </button>
            </div>
          </div>
        </div>
      </dialog>
      {/*Reject Request Dialog Box */}
      <dialog
        open={showRejectModal}
        className='p-3 inset-0 fixed z-40 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
            <div className='w-full flex justify-end items-center'>
              <button
                className='border-2 border-gray-300 p-2 rounded-md'
                onClick={() => setShowRejectModal(false)}
              >
                ❌
              </button>
            </div>
            <div className='w-full flex justify-center items-center mt-2 text-center'>
              <p className='text-sm font-medium'>
                {Pleaseselectreasoncodeforrejectingtheorder}
              </p>
            </div>
            <div className='w-full flex flex-wrap justify-center items-center gap-3'>
              {reasonArray?.map((reason, index) => (
                <div
                  className='rounded-lg bg-[#4472C4] flex gap-2 justify-center items-center p-2 text-xs text-white font-medium'
                  key={'reason' + index}
                >
                  <span>{reason}</span>
                  <span
                    className='cursor-pointer'
                    onClick={() =>
                      setReasonArray(reasonArray?.filter((e) => e !== reason))
                    }
                  >
                    X
                  </span>
                </div>
              ))}
            </div>
            <div className='w-full flex justify-center items-center'>
              <select
                onChange={(e) => handleReasonChange(e.target.value)}
                name='reason_code_select'
                id='reason_code_select'
                className='p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium'
                value=''
              >
                <option value='' disabled>
                  {Selectareason}
                </option>
                {reason_codes?.map((reason) => (
                  <option key={'reason_code' + reason} value={reason}>
                    {reason}
                  </option>
                ))}
              </select>
            </div>
            <div className='w-full flex justify-center items-center gap-4 mt-4'>
              <button
                className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-green-600 outline-none'
                onClick={() => handleRejectRequest('REJECTED')}
              >
                {Confirm}
              </button>
              <button
                className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-red-500 outline-none'
                onClick={() => {
                  setShowRejectModal(false)
                  setReasonArray([])
                }}
              >
                {Cancel}
              </button>
            </div>
          </div>
        </div>
      </dialog>
      {/*Comments Section */}
      <div className='w-full'>
        {requestData?.idopenrequest && (
          <Comments
            crId={requestData?.idopenrequest}
            poNumber={requestData?.po_number}
            status ={requestData?.status==="CLOSED"}
          />
        )}
      </div>
    </div>
  )
}

export default VendorUrgentCR
