import React, { useEffect, useRef, useState } from 'react'
import CommonBarChart from '../CommonBarChart'
import Loading from '../Loading'
import axios from 'axios'
import { FilterComp } from '../../screens/buyer/Dashboard'
import { useTranslation } from 'react-i18next'
import {  ValidateAPI, useOutsideClick } from '../../utils/Utils'
import NameComp from './NameComp'

const DelayRequestChart = ({ vendorList, buyerList, user ,type}) => {
  const [reqTypeData, setReqTypeData] = useState([])
  const [barload, setBarload] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [selectedBuyer, setSelectedBuyer] = useState(() => localStorage.getItem('selectedBuyer') || '');
  const [selectedVendor, setSelectedVendor] = useState(() => localStorage.getItem('selectedVendor') || '');
  const { t } = useTranslation()
  const {
    DistributionbyShipweekDelay,
    Filter,
    Norelateddatafound,
    Analyst,
    Vendor
  } = t('barChart')

  // const fetchReqTypeData = () => {
  //   setBarload(true)
  //   axios
  //     .get(
  //       `${
  //         process.env.REACT_APP_API_DOMAIN
  //       }:8088/api/admin/get-all-counts-shipweekdelays-filter?createdBy=${encodeURIComponent(
  //         user?.fullName
  //       )}`, ValidateAPI()
  //     )
  //     .then((response) => {
  //       console.log('Data from reqtype: ', response?.data)
  //       setReqTypeData(response?.data)
  //       setBarload(false)
  //     })
  //     .catch((error) => {
  //       console.log('error while fetching reqtype: ', error)
  //       setBarload(false)
  //     })
  // }

  const fetchReqTypeData = () => {
    setBarload(true);
  
  // Check if the user role is "MANAGER"
  const shipWeekDelaysApiUrl = user?.roleType === "MANAGER"
    ? `${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/get-all-counts-shipweekdelays-filter`
    : `${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/get-all-counts-shipweekdelays-filter?createdBy=${encodeURIComponent(user?.fullName)}`;

  console.log("user type", user?.roleType);

  axios
    .get(shipWeekDelaysApiUrl,  ValidateAPI())
      .then((response) => {
        console.log('Data from reqtype: ', response?.data);
        setReqTypeData(response?.data);
        setBarload(false);
      })
      .catch((error) => {
        console.log('Error while fetching reqtype: ', error);
        setBarload(false);
      });
  }
  
useEffect(() => {
    if (selectedBuyer) localStorage.setItem('selectedBuyer', selectedBuyer);
    if (selectedVendor) localStorage.setItem('selectedVendor', selectedVendor);
  }, [selectedBuyer, selectedVendor]);  

  useEffect(() => {
    fetchReqTypeData()
  }, [])
  const ref = useRef(null)
  useOutsideClick(ref,()=>{
    setShowFilters(false)
  })
  return (
    <div className="w-[50%] flex justify-center items-center flex-col gap-4 sm:w-[100%]
    shadow-lg rounded-md border border-gray-300 p-3 max-h-[360px] min-h-[360px]">
      <p className="text-sm font-medium">{DistributionbyShipweekDelay}</p>
      <div className="relative flex justify-between items-center w-full">
        <div className="flex gap-3 relative ">
          {user?.roleType === "MANAGER" && (
            <div className="relative">
              <button
                onClick={() => setShowFilters(!showFilters)}
                className='flex outline-none text-sm gap-2 items-center focus:outline-none justify-center rounded-md p-2 border border-gray-300'
              >
                <span className='px-2 text-xs font-medium'>{Filter}</span>
                <span>
                  <svg
                    class='w-3 h-3 text-gray-800 dark:text-white'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='currentColor'
                    viewBox='0 0 24 24'
                  >
                    <path d='M10.8 5a3 3 0 0 0-5.6 0H4a1 1 0 1 0 0 2h1.2a3 3 0 0 0 5.6 0H20a1 1 0 1 0 0-2h-9.2ZM4 11h9.2a3 3 0 0 1 5.6 0H20a1 1 0 1 1 0 2h-1.2a3 3 0 0 1-5.6 0H4a1 1 0 1 1 0-2Zm1.2 6H4a1 1 0 1 0 0 2h1.2a3 3 0 0 0 5.6 0H20a1 1 0 1 0 0-2h-9.2a3 3 0 0 0-5.6 0Z' />
                  </svg>
                </span>
              </button>

              {showFilters && (
                <div ref={ref}>
                  <FilterComp
                  buyerUpdate={setSelectedBuyer}
                  vendorUpdate={setSelectedVendor}
                  fetchActual={fetchReqTypeData}
                  showFilter={setShowFilters}
                  filteredData={setReqTypeData}
                  graph={'shipWeekDelay'}
                  vendorList={vendorList}
                  buyerList={buyerList}
                  selectedBuyer={selectedBuyer}
                  selectedVendor={selectedVendor}
                />
                  </div>
              )}
            </div>
          )}
          <div className='flex gap-2'>
          {selectedBuyer && (
              <NameComp Heading={Analyst} Value={selectedBuyer}/>
              
            )}
            {selectedVendor && (
              <NameComp Heading={Vendor} Value={selectedVendor}/>
              
            )}
          </div>
        </div>
      </div>

      {!barload ? (
        reqTypeData?.length > 0 ? (
          <CommonBarChart
            chartData={reqTypeData}
            color={'#e6696e'}
            type={'range'}
            value={'Count'}
            checkDataType={type}
          />
        ) : (
          <p>{Norelateddatafound}</p>
        )
      ) : (
        <div className='w-full min-h-[250px] justify-center items-center flex'>
          <Loading />
        </div>
      )}
    </div>
  )
}

export default DelayRequestChart
