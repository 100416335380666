import React, { useEffect, useMemo, useState } from 'react'
import UserProfile from '../../components/UserProfile'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import BasicTable from '../../components/BasicTable'
import { DatefilterFn, ValidateAPI, formatDate } from '../../utils/Utils'
import { AdminTabledateFormat } from './HFTUser'
import { formatDateFit } from '../buyer/BuyerRequestDetails'
const VendorList = () => {
  const navigate = useNavigate()
  const [supplierContactList, setContactList] = useState([])
  
  const column = useMemo(() => [
    // {
    //   header: 'SL_NO',
    //   cell: (info) => info.row.index + 1,
    //   // accessorKey: 'ID_VENDOR_CONTACT',
    //   // filterFn: (row, id, value) => {
    //   //   return value.includes(row.getValue(id))
    //   // }

    // },
    
    {
      header: 'VENDOR CONTACT ORACLE ID',
      accessorKey: 'VENDOR_CONTACT_ORACLE_ID',
      // filterFn: (row, id, value) => {
      //   return value.includes(row.getValue(id))
      // }
    },
    {
      header: 'CONTACT NAME',
      accessorKey: 'CONTACT_NAME',
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id))
      }
    },
    {
      header: 'EMAIL',
      accessorKey: 'CONTACT_MAIL',
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id))
      }
    },
    {
      header: 'VENDOR_ID',
      accessorKey: 'VENDOR_ID',
     
    },
    {
      header: 'USER GROUP ROLE',
      accessorKey: 'USER_GROUP_ROLE',
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id))
      }
    },
    {
      header: 'PHONE NUMBER',
      accessorKey: 'PHONENUMBER',
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id))
      }
    },
    {
      header: 'COUNTRY',
      accessorKey: 'COUNTRY',
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id))
      }
    },
    {
      header: 'CITY',
      accessorKey: 'CITY',
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id))
      }
    },

    {
      header: 'Status ',
      accessorKey: 'ENABLED',
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id))
      }
    },
    {
      header: 'CREATED AT',
      accessorKey: 'CREATION_DATE',
      cell: (info) =>
        info?.getValue() !== null ? formatDateFit(info?.getValue())  : '',
      filterFn:DatefilterFn
    },
    {
      header: 'UPDATED AT',
      accessorKey: 'UPDATEDAT',
      cell: (info) =>
        info?.getValue() !== null ? formatDateFit(info?.getValue()) : '',
      filterFn:DatefilterFn
    }
  ])
  const getAllVendorContact = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/get-all-hft-vendor-contacts`, ValidateAPI()
      )
      .then((res) => {
        setContactList(res?.data)
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }
  useEffect(() => {
    getAllVendorContact()
  }, [])

  return (
    <div className='p-2 w-full  flex flex-col  gap-5'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl font-medium'>Vendor List</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      {/* BreadCrumb */}
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/admin')}
          >
            Home
          </span>
          <span>{'>'}</span>
        </p>

        <p className='flex gap-1 items-center justify-center'>
          <span>Vendor </span>
        </p>
      </div>

      {/* ALL Contac List*/}
      {supplierContactList?.length !== 0 && (
        <BasicTable tableData={supplierContactList} columns={column} colheight="12" />
      )}
    </div>
  )
}

export default VendorList
