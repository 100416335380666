import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { formatDateFit } from '../screens/buyer/BuyerRequestDetails'
import {  ValidateAPI } from '../utils/Utils'

const Stepper = ({ status, crId, iscancelled ,refresh}) => {
  const firstStep =[
    { suppRes: 'Awaiting Response', updatedAT: '' },
    { suppRes: 'Vendor Responded', updatedAT: '' },
    { suppRes: 'Closed', updatedAT: '' }
  ]
  const [steps, setSteps] = useState(firstStep)
  const secondStep =[
    { suppRes: 'Awaiting Response', updatedAT: '' },
    { suppRes: 'Cancelled', updatedAT: '' },
    { suppRes: 'Vendor Responded', updatedAT: '' },
    { suppRes: 'Closed', updatedAT: '' }
  ]
  const [stepsCancel, setStepsCancel] = useState(secondStep)
  const [loading,setloading] = useState(false)
  // //for canceled and calelled it will sore calcelled value as status bcz in
  // let value = status ==='Canceled'? 'Cancelled':status
  // console.log(value);
  let currentStepIndex = iscancelled
    ? stepsCancel?.findIndex((item) => item.suppRes === status)
    : steps?.findIndex((item) => item.suppRes === status)
    // console.log('currentStepIndex',);
  const fetchCommentsData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-all-cr-activity-by-crid?hftcrid=${crId}`, ValidateAPI()
      )
      .then((response) => {
        // console.log("responace",response?.data);
        const newArray = iscancelled ? [...secondStep] : [...firstStep]
        // console.log(newArray);
        response?.data?.map((item) => {
          // console.log(item);
          if (item?.MESSAGE?.includes('Created'))
            newArray[0] = { ...newArray[0], ...{ updatedAT: item?.CREATION_DATE } }
          else if (
            item?.MESSAGE?.includes('Accepted') ||
            item?.MESSAGE?.includes('Vendor Initiated')
          ) {
            // console.log("Accepted    ", item?.CREATED_AT);
            newArray[1] = { ...newArray[1], ...{ updatedAT: item?.CREATION_DATE } }
          } else if (item?.MESSAGE?.includes('Rejected'))
            newArray[2] = { ...newArray[2], ...{ updatedAT: item?.CREATION_DATE } }
          else if (item?.MESSAGE?.includes('Closed'))
            newArray[2] = { ...newArray[2], ...{ updatedAT: item?.CREATION_DATE } }
          else if (item?.MESSAGE?.includes('Cancelled'))
            newArray[1] = { ...newArray[1], ...{ updatedAT: item?.CREATION_DATE } }
          // console.log(newArray);
          iscancelled ? setStepsCancel(newArray) : setSteps(newArray)
          // console.log(newArray);
          // let end = new Date();
          // console.log(end-start);
        })
        setloading(true)
      })
      .catch((error) => {
        console.log('error while fetching comments: ', error)
      })
  }
  let start
  useEffect(() => {
    fetchCommentsData()
    //  start = new Date();

  }, [crId,iscancelled,status,refresh])
  
  return (
    <>
    {
      loading &&
      <div className='w-full flex justify-center items-start relative'>
      <div className='w-[70%] border border-gray-200 absolute top-3' />
      <div className='w-[80%] flex justify-between items-center'>
        {(iscancelled ? stepsCancel : steps)?.map((step, index) => (
          <div
            className='flex flex-col z-10 justify-start items-center gap-2 h-[100px]'
            key={index + step}
          >
            <div
              className={`rounded-full border-2 p-1 ${
                currentStepIndex >= index
                  ? 'border-blue-600'
                  : 'border-gray-300'
              } w-6 h-6 flex justify-center items-center `}
            >
              <div
                className={`p-1.5 rounded-full ${
                  currentStepIndex >= index ? 'bg-blue-600' : 'bg-gray-300'
                }`}
              ></div>
            </div>
            <div
              className={`${
                currentStepIndex >= index ? 'text-blue-600' : 'text-gray-400'
              } text-center text-xs font-medium w-[160px]`}
            >
              <p>{step?.suppRes}</p>
              <p>{step?.updatedAT && formatDateFit(step?.updatedAT)}</p>
            </div>
          </div>
        ))}
      </div>
      {/* <pre>{JSON.stringify(step, null, "\t")}</pre> */}
    </div>
       
    }
    {/* <pre>{JSON.stringify(steps, null, "\t")}</pre>  */}
    </>
  )
}
export default Stepper