import React, { useEffect, useMemo, useRef, useState } from 'react'
import { formatDateFit } from './BuyerRequestDetails'
import { forwardRef } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  ColumnResizeMode,
  ColumnDef,
  ColumnResizeDirection
} from '@tanstack/react-table'
import axios, { all } from 'axios'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  Filter,
  checkPd,
  downloadCSV,
  handlePODetailsClick,
  updateActionHistory,
  MultiSelectColumnFilter,
  getUserPreference,
  updateUserPreference,
  createUserPreference,
  getallDcList,
  getPresentShipWeek,
  ValidateAPI,
  DatefilterFn,
  changeDateFormat,
  filterFnSWDate,
  getStartDate,
  CalculateTableArray,
  highLightDateField,
  OpenSpecificDate,
  getPossibleShipWeek,
  getDateofMonday,
  validateShipWeekInput,
  validateShipWeekInput1
} from '../../utils/Utils'
import Loading from '../../components/Loading'
import { useLocation } from 'react-router-dom'
import { EditedRowState } from '../../LineContext/Context'
import { weeksInBetween } from '../vendor/ConfirmPo'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import PopupModal from '../../components/PopupModal'
import PopupBanner from '../../components/PopupBanner'
import MessageBox from '../../components/MessageBox'
import {
  MessageIconRead,
  MessageIconUnread
} from '../../components/MessageIcon'
import { useNavigate } from 'react-router'
import Cookies from 'js-cookie'
import Select from 'react-select'
import SelectList from '../../components/buyer/SelectList'
import { CommonAPICall } from '../../CommonAPiContext/context'
import Loader from '../../components/Loader'

function getISOWeek(date) {
  // Create a copy of the date object to avoid mutating the original one
  const target = new Date(date.valueOf())

  // Set to the nearest Thursday (required for ISO week calculation)
  target.setHours(0, 0, 0, 0)
  target.setDate(target.getDate() + 3 - ((target.getDay() + 6) % 7))

  // January 4th is always in week 1 (ISO 8601)
  const firstThursday = new Date(target.getFullYear(), 0, 4)

  // Get the nearest Thursday of the first week of the year
  firstThursday.setDate(
    firstThursday.getDate() + 3 - ((firstThursday.getDay() + 6) % 7)
  )

  // Calculate the week number
  const weekNumber = Math.ceil(((target - firstThursday) / 86400000 + 1) / 7)

  return weekNumber
}

function getISOYear(date) {
  const target = new Date(date.valueOf())
  target.setDate(target.getDate() + 3 - ((target.getDay() + 6) % 7))
  return target.getFullYear()
}

export function getWeek(date) {

  let year = getISOYear(date)
  let week = getISOWeek(date)
  week = week.toString().padStart(2, '0')
  return `${week}.${year % 100}`
}

export const getWeekDifferenceStatus = (currentShipWeek, updatedShipWeek) => {
  console.log(`currentSW: ${currentShipWeek} and updated: ${updatedShipWeek}`)

  if (!currentShipWeek) {
    return 'Expedite'
  } else {
    const [currentWeek, currentYear] = currentShipWeek.split('.')
    const [updatedWeek, updatedYear] = updatedShipWeek.split('.')

    const currentYearNumber = Number(currentYear)
    const updatedYearNumber = Number(updatedYear)
    const currentWeekNumber = Number(currentWeek)
    const updatedWeekNumber = Number(updatedWeek)

    if (currentYearNumber > updatedYearNumber) {
      return 'Expedite'
    } else if (currentYearNumber < updatedYearNumber) {
      return 'Defer'
    } else {
      if (currentWeekNumber > updatedWeekNumber) {
        return 'Expedite'
      } else if (currentWeekNumber < updatedWeekNumber) {
        return 'Defer'
      } else {
        return ''
      }
    }
  }
}

export function IndeterminateCheckbox({
  indeterminate,
  isdisabled,
  row,
  className = '',
  ...rest
}) {
  const ref = React.useRef(null)
  const {
    state: { editArray },
    dispatch
  } = EditedRowState()

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = indeterminate
    }
  }, [ref, indeterminate])

  const handleClick = () => {
    const isChecked = ref.current.checked
    if (!isChecked) {
      console.log('uncheck condition passed')
      dispatch({
        type: 'REMOVE_FROM_LIST',
        payload: {
          SKU: row?.SKU,
          PO_NUMBER: row?.PO_NUMBER
        }
      })
      console.log('post dispatch ', editArray)
    }
  }

  return (
    <input
      type='checkbox'
      ref={ref}
      className={className + ' cursor-pointer'}
      {...rest}
      disabled={isdisabled}
      onClick={handleClick}
    />
  )
}

// const dcList = [
//   {
//     DC_NAME: "MOR",
//   },
//   {
//     DC_NAME: "CAM",
//   },
//   {
//     DC_NAME: "DIL",
//   },
//   {
//     DC_NAME: "CHI",
//   },
//   {
//     DC_NAME: "DFW",
//   },

const EditableSw = ({
  row,

  updatedRows,
  selectTableRow,
  rowSelection,
  updateRowSelection
}) => {
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState('')
  const [date, setDate] = useState(new Date())
  const [inputsw, setinputsw] = useState('')
  const [validSw, setValidSw] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')
  const [selectedSW, setSelectedSW] = useState(null)

  const user = JSON.parse(localStorage.getItem('userData'))
  const {
    state: { editArray },
    dispatch
  } = EditedRowState()
  const inputRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setEditing(false)
      }
    }

    document.body.addEventListener('click', handleClickOutside)

    return () => {
      document.body.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleEdit = () => {
    if (user?.userGroupName === 'OVERSEAS_ROLE') return
    if (
      editArray?.some((c) => c?.SKU === row?.original?.SKU) &&
      editArray?.some((c) => c?.PO_NUMBER === row?.original?.PO_NUMBER)
    ) {
      alert(i18next.t('PopUpMessage.YouvealreadychangedavalueinthisPOLine'))
    } else if (row?.original?.HFT_REQUEST) {
      alert(i18next.t('PopUpMessage.chnagePOLine'))
    }
    //  else if (checkPd(row?.original?.CURRENT_SW ?? row?.original?.REQ_SW)) {
    //   alert(i18next.t('PopUpMessage.POLinepastdue'))
    // }
    else if (
      row?.original?.CURRENT_SW == null &&
      row?.original?.ORG_SW === null
    ) {
      alert(i18next.t('PopUpMessage.POLinebyvendoryet'))
    } else {
      setEditing(true)
    }
  }

  const handleChangeDate = (date) => {
    setDate(date)
    setValue(getWeek(date))
    handleUpdate(getWeek(date))
    setinputsw(getWeek(date))
    setEditing(false)
  }

  const resetValue = () => {
    setEditing(false)
    console.log(row)
    setinputsw('')
    setValue(row?.original?.REQ_SW)
    dispatch({
      type: 'REMOVE_FROM_LIST',
      payload: {
        PO_SKU: row?.original?.PO_SKU
      }
    })
    updateRowSelection((prevSelection) => ({
      ...prevSelection,
      [row.id]: false
    }))
    setEditing(false)
  }

  const handleUpdate = (date) => {
    console.log('entire row data: ', row)
    if (row?.original?.CURRENT_SW !== date) {
      const updatedRow = { UPDATED_SW: date, ...row.original }
      console.log('updated row: ', updatedRow)
      dispatch({
        type: 'ADD_TO_LIST',
        payload: updatedRow
      })
      const updatedRowId = row.id
      updateRowSelection((prevSelection) => ({
        ...prevSelection,
        [updatedRowId]: !prevSelection[updatedRowId]
      }))
      console.log('is the row selected: ', row?.getIsSelected())
      setEditing(false)
    } else {
      setEditing(false)
    }
  }

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '')
    setinputsw(value)
    if (value === row?.original?.CURRENT_SW) {
      setValidSw(true) // Exit if the value is the same as CURRENT_SW
    } else if (value.length === 5) {
      // New validation logic
      const validation = validateShipWeekInput(value, row?.original?.CURRENT_SW)

      if (!validation.isValid) {
        setValidSw(true)
        setValidationMessage(validation.message)
      } else {
        setValidSw(false)
        setValidationMessage('')

        // Original functionality
        const sw = getPossibleShipWeek(value)
        setValue(sw)
        // setRequestSW(sw);
        setDate(getDateofMonday(sw))
        handleUpdate(sw)
        setEditing(false)
        datePickerRef.current.setFocus()
      }
    } else {
      // Reset message if input is incomplete
      setValidSw(false)
      setValidationMessage('')
    }
  }

  const datePickerRef = useRef(null)

  useEffect(() => {
    setValue(row?.original?.CURRENT_SW)
  }, [row?.original?.CURRENT_SW])

  return editing ? (
    <div
      className='flex flex-col justify-between items-center w-full'
      style={{ position: 'relative', zIndex: 0 }}
    >
      <div className='border border-gray-300 rounded-md p-1 w-full relative'>
        <p className='bg-gray-400 text-white p-1'>CURSW : {row?.original?.CURRENT_SW}</p>
        <p>Select New SW </p>
        <input
          type='number'
          className='border mt-1 p-1 bg-yellow-300 placeholder-black'
          placeholder='SW.YY(e.g.,00.00)'
          value={inputsw}
          onChange={handleInputChange}
        />
        <p className='mt-1'>{inputsw}</p>
        {validSw ? <p className='text-red-500'>Enter Valid SW!</p> : null}
        <DatePicker
          showWeekNumbers
          filterDate={(date) => date.getDay() === 1}
          selected={date}
          onChange={(date) => handleChangeDate(date)}
          className='p-1 border w-full bg-yellow-300'
          showIcon
          scrollableYearDropdown
          popperClassName='react-datepicker__week-number'
          excludeDates={[
            new Date(),
            new Date(changeDateFormat(row?.original?.CURRENT_SW))
          ]}
          formatWeekDay={(day) => day.slice(0, 3)}
          calendarStartDay={1}
          highlightDates={highLightDateField(row?.original?.CURRENT_SW)}
          // openToDate={OpenSpecificDate(row?.original?.CURRENT_SW)}
          showYearDropdown
          ref={datePickerRef}
          onBlur={() => {
            setEditing(false)
          }}
          minDate={new Date()}

        // autoFocus
        />
      </div>
      {editArray?.some((c) => c?.SKU === row?.original?.SKU) &&
        editArray?.some((c) => c?.PO_NUMBER === row?.original?.PO_NUMBER) &&
        row?.original?.REQ_SW !== value && (
          <div className='w-full justify-end items-center flex'>
            <button onClick={() => resetValue()}>
              <svg
                className='w-6 h-6 text-gray-800 dark:text-white'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                viewBox='0 0 24 24'
              >
                <path
                  fillRule='evenodd'
                  d='M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
          </div>
        )}
    </div>
  ) : (
    <div>
      <p className='cursor-pointer' onClick={handleEdit}>
        {editArray?.some((c) => c?.SKU === row?.original?.SKU) &&
          editArray?.some((c) => c?.PO_NUMBER === row?.original?.PO_NUMBER)
          ? value
          : row?.original?.CURRENT_SW}
      </p>
      {editArray?.some((c) => c?.SKU === row?.original?.SKU) &&
        editArray?.some((c) => c?.PO_NUMBER === row?.original?.PO_NUMBER) &&
        row?.original?.CURRENT_SW !== value && (
          <div className='w-full justify-end items-center flex'>
            <button onClick={() => resetValue()}>
              <svg
                className='w-6 h-6 text-gray-800 dark:text-white'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                viewBox='0 0 24 24'
              >
                <path
                  fillRule='evenodd'
                  d='M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
          </div>
        )}
    </div>
  )
}

const EditableDc = ({ row, updatedRows, updateRowSelection }) => {
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState('')
  const [dcList, setDCList] = useState(
    JSON.parse(sessionStorage?.getItem('dcList'))
  )
  const user = JSON.parse(localStorage.getItem('userData'))
  const {
    state: { editArray },
    dispatch
  } = EditedRowState()

  const inputRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setEditing(false)
      }
    }

    document.body.addEventListener('click', handleClickOutside)

    return () => {
      document.body.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleEdit = () => {
    if (user?.userGroupName === 'OVERSEAS_ROLE') return
    if (
      editArray?.some((c) => c?.SKU === row?.original?.SKU) &&
      editArray?.some((c) => c?.PO_NUMBER === row?.original?.PO_NUMBER)
    ) {
      // alert(i18next.t('PopUpMessage.YouvealreadychangedavalueinthisPOLine'))
    } else if (row?.original?.HFT_REQUEST) {
      alert(i18next.t('PopUpMessage.chnagePOLine'))
    }
    // else if (checkPd(row?.original?.CURRENT_SW ?? row?.original?.REQ_SW)) {
    //   alert(i18next.t('PopUpMessage.POLinepastdue'))
    // }
    else if (
      row?.original?.CURRENT_SW == null &&
      row?.original?.ORG_SW === null
    ) {
      alert(i18next.t('PopUpMessage.POLinebyvendoryet'))
    } else {
      setEditing(true)
    }
  }

  const resetValue = () => {
    setValue(row?.original?.DC)
    console.log(row.id)
    dispatch({
      type: 'REMOVE_FROM_LIST',
      payload: { PO_SKU: row?.original?.PO_SKU }
    })
    updateRowSelection((prevSelection) => ({
      ...prevSelection,
      [row.id]: false
    }))
    setEditing(false)
  }

  const handleUpdate = (e) => {
    if (row?.original?.DC !== e?.target?.value) {
      const updatedRow = { ...row.original, UPDATED_DC: e?.target?.value }
      dispatch({
        type: 'ADD_TO_LIST',
        payload: updatedRow
      })
      const updatedRowId = row.id
      updateRowSelection((prevSelection) => ({
        ...prevSelection,
        [updatedRowId]: !prevSelection[updatedRowId]
      }))
      setValue(e?.target?.value)
      setEditing(false)
    } else {
      setEditing(false)
    }
  }

  useEffect(() => {
    setValue(row?.original?.DC)
  }, [row?.original?.DC])
  const { t } = useTranslation()
  const { OrgDC, SelectDC } = t('POList')

  return editing ? (
    <div className='flex flex-col items-center justify-between w-full '>
      <div className='flex flex-col gap-1'>
        <p className='bg-gray-400 text-white p-1'>
          {OrgDC} {row?.original?.DC}
        </p>
        <select
          name=''
          id='editDcDropDown'
          onChange={(e) => handleUpdate(e)}
          className='p-1 rounded-md border border-gray-300 outline-none bg-yellow-300'
          onBlur={() => setEditing(false)}
          autoFocus
        >
          <option value='' defaultValue>
            {SelectDC}
          </option>
          {dcList?.map((dc, index) => {
            return (
              <option
                key={'editDc' + dc?.DC_NAME}
                value={dc?.DC_NAME}
                disabled={dc?.DC_NAME === row?.original?.DC}
                className="bg-white text-black"

              >
                {dc?.DC_NAME}
              </option>
            )
          })}
        </select>
      </div>
      {editArray?.some((c) => c?.SKU === row?.original?.SKU) &&
        editArray?.some((c) => c?.PO_NUMBER === row?.original?.PO_NUMBER) &&
        row?.original?.DC !== value && (
          <div className='w-full justify-end items-center flex'>
            <button onClick={() => resetValue()}>
              <svg
                className='w-6 h-6 text-gray-800 dark:text-white'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                viewBox='0 0 24 24'
              >
                <path
                  fillRule='evenodd'
                  d='M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
          </div>
        )}
    </div>
  ) : (
    <div
      onClick={handleEdit}
      className='flex flex-col items-center justify-between w-full'
    >
      <p className='cursor-pointer'>
        {editArray?.some((c) => c?.SKU === row?.original?.SKU) &&
          editArray?.some((c) => c?.PO_NUMBER === row?.original?.PO_NUMBER)
          ? value
          : row?.original?.DC}
      </p>
      {editArray?.some((c) => c?.SKU === row?.original?.SKU) &&
        editArray?.some((c) => c?.PO_NUMBER === row?.original?.PO_NUMBER) &&
        row?.original?.DC !== value && (
          <div className='w-full justify-end items-center flex'>
            <button onClick={() => resetValue()}>
              <svg
                className='w-6 h-6 text-gray-800 dark:text-white'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                viewBox='0 0 24 24'
              >
                <path
                  fillRule='evenodd'
                  d='M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
          </div>
        )}
    </div>
  )
}

// ];
const FilterTypes = {
  ALL: 'All',
  NO_CR: 'No CR',
  CR: 'CR',
  PAST_DUE: 'Past Due'
}

const NewPoList = () => {
  const user = JSON.parse(localStorage.getItem('userData'))
  const {
    state: { editArray },
    dispatch
  } = EditedRowState()
  const navigate = useNavigate()
  const [showSelectColumn, setShowSelectColumn] = useState(false)
  const [searching, setSearching] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [showSWCRModal, setShowSWCRModal] = useState(false)
  const [showDCCRModal, setShowDCCRModal] = useState(false)
  const [selectedArray, setSelectedArray] = useState([])
  const [data, setData] = useState([])
  const [requestSW, setRequestSW] = useState('')
  const [requestDC, setRequestDC] = useState('')
  const [sorting, setSorting] = useState([])
  const [rowSelection, setRowSelection] = useState({})
  const [columnFilters, setColumnFilters] = useState([])
  const [showLegend, setShowLegend] = useState(false)
  const [date, setDate] = useState(new Date())
  const [cancelTokenSource, setCancelTokenSource] = useState(null)
  const [openVendorList, SetOpneVendorList] = useState(false)
  // const [vendorList, setVendorList] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [openpoNumberList, setOpenPonumberList] = useState(false)
  const [searchpoNumber, setSearchpoNumber] = useState('')
  const [selectedPOForMessageBox, setSelectedPOForMessageBox] = useState(null)
  const [selectedSKUForMessageBox, setSelectedSKUForMessageBox] = useState(null)
  const [messageCounts, setMessageCounts] = useState([])
  const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false)
  const [poNumberlist, setpoNumberList] = useState([])
  const [dcList, setDCList] = useState(
    JSON.parse(sessionStorage?.getItem('dcList'))
  )
  const [toggleColumnList, setToggleColumnList] = useState({})
  const [columnOrderList, setColumnOrderList] = useState([])
  const [userFilters, setUserFilters] = useState([])
  const [hasPreference, setHasPreference] = useState(false)
  const [columnOrder, setColumnOrder] = useState(
    JSON.parse(localStorage.getItem('po_list_order')) ?? []
  )
  const [showCreateLineRequestModal, setShowCreateLineRequestModal] =
    useState(false)
  const [showChangeRequestModal, setShowChangeRequestModal] = useState(false)
  const [showChangeRequestBanner, setShowChangeRequestBanner] = useState(false)
  const [showCreateRequestModal, setShowCreateRequestModal] = useState(false)

  const [requestPayload, setRequestPayload] = useState([])
  const [openunseenmsgBox, setUnseenBox] = useState(false)
  // const [columnVisibility, setColumnVisibility] = useState(
  //   JSON.parse(localStorage.getItem("toggle_columns")) ?? {}
  // );
  const [columnVisibility, setColumnVisibility] = useState({

  })
  const [initialFilter, setIntialFilter] = useState({
    REQ_SW_DATE: false,
    ORG_SW_DATE: false,
    CURRENT_SW_DATE: false,
    ONTARGET_SHIP_WK_DATE: false,
    UNIT_COST: user?.userGroupName === 'OVERSEAS_ROLE' ? false : true,
    EXTENDED_COST: user?.userGroupName === 'OVERSEAS_ROLE' ? false : true
  })
  const [firstRow, setFirstRow] = useState('')
  const [dcRow, setDcRow] = useState([])
  const [poRow, setPoRow] = useState(null)
  const [crIdResponse, setCrIdResponse] = useState([])
  const [crPoNumber, setCrPonumber] = useState([])
  const [crIdNumber, setCrIdNumber] = useState([])

  const [editedRows, setEditedRows] = useState([])
  const [buyerList, setBuyerList] = useState([])
  const [openbuyer, setCLoseByer] = useState(false)
  const [prefChanged, setPrefChanged] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [inputsw, setinputsw] = useState('')
  const updateEditedRows = (updatedRows) => {
    setEditedRows(updatedRows)
  }
  const [values, setValues] = useState([])
  const [rawData, setRawData] = useState([])
  const [dataFilter, setDataFilter] = useState(FilterTypes.ALL)

  const [columnResizeMode, setColumnResizeMode] = React.useState('onChange')

  const [columnResizeDirection, setColumnResizeDirection] =
    React.useState('ltr')
  const [ClearState, setClearState] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const {
    state: { buyerListData, vendorListData, skuListData },
    SearchBuyer,
    searchVendorORSKU,
    SearchSKU,
    addFilterData
  } = CommonAPICall()
  const [filter, setFilterData] = useState({})
  const [check, setcheck] = useState(false)
  const [tableName, setTableName] = useState('NewPoList')
  const [validSw, setValidSw] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')

  const getFirstNameColumnStyles = (column) => {
    return {
      boxShadow: '-4px 0 4px -4px gray inset',
      left: '0px',
      opacity: 0.95,
      position: 'sticky',
      width: column.getSize(),
      zIndex: 1,
      background: 'red !important' /* Background color for the header */
    }
  }

  const { t } = useTranslation()
  const {
    ActiveCR,
    CRHistory,
    PastDue,
    CurrentDC,
    Filters,
    PO,
    NoDataFound,
    SKU,
    Vendor,
    UPC,
    DC,
    InventoryAnalyst,
    VendorManager,
    ShippingOffice,
    SpecialSKUs,
    Loading1,
    ClearFilters,
    Columns,
    ToggleAll,
    ChangeShipweek,
    ChangeDC,
    Page,
    Gotopage,
    Show,
    HFTCR,
    SelectShipDate,
    SelectedShipWeekValue,
    Submit,
    CreateRequest,
    Cancel,
    CurrentShipWeek,
    CURSW
  } = t('POList')

  const headers = {
    PONUMBER: t('POList.PONUMBER'),
    SKU: t('POList.SKU'),
    DESCRIPTION: t('POList.DESCRIPTION'),
    UPC: t('POList.UPC'),
    MESSAGE: t('POList.MESSAGES'),
    REVNO: t('POList.REVISIONNUMBER'),
    REASONCODE: t('POList.REASONCODE'),
    DC: t('POList.DC'),
    // REQSW: t('POList.REQSW'),
    CHANGEDSW: t('POList.CHANGEDSW'),
    REQSW: t('POHeaderDetails.REQSW'),
    REQSWDate: t('POList.REQSWDate'),
    ORGSW: t('POList.ORGSW'),
    ORGSWDate: t('POList.ORGSWDate'),
    CURRSW: t('POList.CURRENTSW'),
    CURRSWDate: t('POList.CURRSWDate'),
    ONTIMETARGET: t('POList.ONTIMETARGET'),
    ONTIMETARGETDate: t('POList.ONTIMETARGETDate'),
    DELTA: t('POList.DELTA'),
    UNITCOST: t('POList.UNITCOST'),
    EXTENDEDCOST: t('POList.EXTENDEDCOST'),
    LSTUPDATED: t('POList.DATELASTUPDATED'),
    VENDOR: t('POList.VENDOR'),
    VENDORNAME: t('POList.VENDORNAME'),
    VENDORMANAGER: t('POList.VENDORMANAGER'),
    SHIPOFFICE: t('POList.SHIPPINGOFFICE'),
    SHIPqty: t('POList.SHIPPEDQUANTITY'),
    VENDORMESSAGE: t('POList.VENDORMESSAGE'),
    BUYERMESSAGE: t('POList.BUYERMESSAGE'),
    POSKU: t('POList.POSKU'),
    REQDC: t('POList.REQDC'),
    INVENTORYANALYST: t('POList.INVENTORYANALYST'),
    HEADERDESC: t('POList.POHEADERDESCRIPTION'),
    SPECIALSKU: t('POList.SPECIALSKU'),
    // STATUS: t('POList.STATUS'),
    POQUANTITY: t('POList.POQUANTITY'),
    ACTIONTYPE: t('POList.ACTIONTYPE'),
    OPENQUANTITY: t('POList.OPENQUANTITY'),
    FACTORYCOUNTRY: t('POList.FACTORYCOUNTRY')
  }
  const columns = useMemo(
    () => [
      {
        header: headers.ACTIONTYPE,
        accessorKey: 'REQUEST_TYPE',
        filterFn: (row, id, value) => {
          const cellValue = row.getValue(id)
          // console.log("value",value)
          // console.log("cellvalue",cellValue)

          // // Check if cellValue is null or undefined before calling toString()
          if (cellValue == null) {
            return false // or any other logic you want to apply when the value is null
          }
          let words = value.split(/[\s,]+/).map((word) => word.toLowerCase()) // Split by comma and spaces
          return words.includes(cellValue?.toLowerCase())
        }
      },

      {
        header: headers.POSKU,
        accessorKey: 'PO_SKU',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        },
        // size: 250,
        isPinned: 'left'
      },
      {
        header: headers.PONUMBER,
        accessorKey: 'PO_NUMBER',
        frozen: true,
        cell: (info) => {
          return (
            <p
              className='hover:underline cursor-pointer text-blue-500'
              onClick={() => handlePODetailsClick('buyer', info?.getValue())}
            >
              {info?.getValue()}
            </p>
          )
        },
        Filter: MultiSelectColumnFilter,
        filter: 'multiSelect',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.SKU,
        accessorKey: 'SKU',
        frozen: true,
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        },
        show: false
      },
      {
        header: headers.DESCRIPTION,
        accessorKey: 'DESCRIPTION',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        },
        size: 100
      },
      {
        header: headers.UPC,
        accessorKey: 'UPC',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.FACTORYCOUNTRY,
        accessorKey: 'FACTORY_COUNTRY',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.BUYERMESSAGE,
        accessorKey: 'BUYER_MESSAGE',
        filterFn: (row, id, value) => {
          const buyerMessage = row.getValue(id)
          return (
            buyerMessage && buyerMessage !== ' ' && value.includes(buyerMessage)
          )
        },
        size: 100
      },
      {
        header: headers.OPENQUANTITY,
        accessorKey: 'OPEN_QTY'
        // filterFn: (row, id, value) => {
        //   return value.includes(row.getValue(id))
        // }
      },
      // {
      //   header: headers.PREVIOUSW,
      //   accessorKey: 'PREVS_CURRENTSHIPWEEK'
      //   // filterFn: (row, id, value) => {
      //   //   return value.includes(row.getValue(id))
      //   // }
      // },
      {
        header: headers.VENDORMESSAGE,
        accessorKey: 'VENDOR_MESSAGE',
        filterFn: (row, id, value) => {
          const vendorMessage = row.getValue(id)
          return (
            vendorMessage &&
            vendorMessage !== ' ' &&
            value.includes(vendorMessage)
          )
        },
        size: 100
      },
      {
        header: headers.REVNO,
        accessorKey: 'REV#'
        // filterFn: (row, id, value) => {
        //   return value.includes(row.getValue(id))
        // },
        // Filter: MultiSelectColumnFilter,
        // filter: 'multiSelect'
        // filterFn: (row, id, value) => {
        //   return value.includes(row.getValue(id))
        // }
      },

      {
        header: headers.REASONCODE,
        accessorKey: 'REASON_CODE',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.DC,
        accessorKey: 'DC',
        cell: ({ row }) => (
          <EditableDc
            row={row}
            updatedRows={updateEditedRows}
            dispatch={dispatch}
            selectTableRow={selectTableRow}
            rowSelection={rowSelection}
            updateRowSelection={setRowSelection}
          />
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.REQDC,
        accessorKey: 'REQUESTED_DC',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.REQSW,
        accessorKey: 'REQ_SW',
        filterFn: (row, id, value) => {
          const reqSwData = row.original.REQ_SW // Assign "REQ_SW" data to another variable
          return value.includes(reqSwData)
        }
      },
      {
        header: headers.REQSWDate,
        accessorFn: (row) => row.REQ_SW,
        id: 'REQ_SW_DATE',
        cell: (info) =>
          info?.getValue() !== ' ' ? changeDateFormat(info?.getValue()) : '',
        filterFn: filterFnSWDate
      },
      {
        header: headers.ORGSW,
        accessorKey: 'ORG_SW',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.ORGSWDate,
        accessorFn: (row) => row.ORG_SW,
        id: 'ORG_SW_DATE',
        cell: (info) =>
          info?.getValue() !== ' ' ? changeDateFormat(info?.getValue()) : '',
        filterFn: filterFnSWDate
      },
      {
        header: headers.CURRSW,
        accessorKey: 'CURRENT_SW',
        cell: ({ row }) => (
          <EditableSw
            row={row}
            updatedRows={updateEditedRows}
            selectTableRow={selectTableRow}
            rowSelection={rowSelection}
            updateRowSelection={setRowSelection}
          />
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CURRSWDate,
        accessorFn: (row) => row.CURRENT_SW,
        id: 'CURRENT_SW_DATE',
        cell: (info) =>
          info?.getValue() !== ' ' ? changeDateFormat(info?.getValue()) : '',
        filterFn: filterFnSWDate
      },
      {
        header: headers.ONTIMETARGET,
        accessorKey: 'ONTARGET_SHIP_WK',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.ONTIMETARGETDate,
        accessorFn: (row) => row.ONTARGET_SHIP_WK,
        id: 'ONTARGET_SHIP_WK_DATE',
        cell: (info) =>
          info?.getValue() !== ' ' ? changeDateFormat(info?.getValue()) : '',
        filterFn: filterFnSWDate
      },
      {
        header: headers.DELTA,
        accessorKey: 'DELTA',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: 'CARGO READY SW',
        accessorKey: 'CARGO_READY_SW',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        },
        size: 55,
      },
      {
        header: 'BOOKING SW',
        accessorKey: 'BOOKING_SW',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.UNITCOST,
        accessorKey: 'UNIT_COST',
        cell: ({ getValue }) => `$ ${getValue()}`
        // enableHiding: user.userGroupName === "OVERSEAS_ROLE" ? true : false
      },
      // {
      //   header: headers.UNITCOST,
      //   accessorKey: 'UNIT_COST'
      //   // filterFn: (row, id, value) => {
      //   //   return value.includes(row.getValue(id))
      //   // },
      // },
      // user?.userGroupName!=="OVERSEAS_ROLE"&&
      {
        header: headers.EXTENDEDCOST,
        accessorKey: 'EXTENDED_COST',
        cell: ({ getValue }) => `$ ${getValue()}`
        // enableHiding: user.userGroupName === "OVERSEAS_ROLE" ? true : false,
        // filterFn: (row, id, value) => {
        //   return value.includes(row.getValue(id))
        // },
      },
      {
        header: headers.VENDOR,
        accessorKey: 'VENDOR#',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.VENDORNAME,
        accessorKey: 'VENDOR_NAME',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        },
        size: 100
      },
      {
        header: headers.VENDORMANAGER,
        accessorKey: 'VENDOR_MANAGER',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.SHIPOFFICE,
        accessorKey: 'SHIPPING_OFFICE',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.SHIPqty,
        accessorKey: 'SHIP_QTY'
        // filterFn: (row, id, value) => {
        //   return value.includes(row.getValue(id))
        // },
      },
      // {
      //   header: headers.SPECIALSKU,
      //   accessorKey: 'SPECIAL_SKUS',
      //   filterFn: (row, id, value) => {
      //     return value.includes(row.getValue(id))
      //   }
      // },
      {
        header: headers.INVENTORYANALYST,
        accessorKey: 'INVENTORY_ANALYST',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.HEADERDESC,
        accessorKey: 'PO_HEADER_DESC',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.POQUANTITY,
        accessorKey: 'QUANTITY'
        // filterFn: (row, id, value) => {
        //   return value.includes(row.getValue(id))
        // }
      },
      {
        header: headers.LSTUPDATED,
        accessorKey: 'DATE_LAST_UPDATED',
        cell: (info) =>
          info?.getValue() !== ' ' ? formatDateFit(info?.getValue()) : '',
        filterFn: DatefilterFn,
        enableResizing: true
      }
    ],
    // [t]
    Object.values(headers)
  )

  const table = useReactTable({
    data,
    columns,
    columnResizeMode,
    columnResizeDirection,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection,
      sorting,
      columnFilters,
      // columnOrder: columnOrder,
      columnVisibility
    },
    initialState: {
      columnPinning: {
        left: ['REQUEST_TYPE', 'PO_SKU']
      }
    },
    defaultColumn: {
      size: 50 //starting column size
      // minSize: 50, //enforced during column resizing
      // maxSize: 500, //enforced during column resizing
    },
    enableRowSelection: (row) =>
      row?.original?.HFT_REQUEST === null &&
      row?.original.CURRENT_SW !== null &&
      row?.original?.ORG_SW !== null,
    // !checkPd(row?.original?.CURRENT_SW ?? row?.original?.REQ_SW),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
    // onColumnOrderChange: (newColumnOrder) => {
    //   setColumnOrder(newColumnOrder)
    // }
    // debugTable: true,
  })
  const datePickerRef = useRef(null)

  // console.log(table);
  let columnBeingDragged
  const vendorstorage = JSON.parse(localStorage.getItem('vendorData'))
  const buyreStorage = JSON.parse(localStorage.getItem('buyerData'))
  const skuStorage = JSON.parse(localStorage.getItem('skuData'))
  //stroing temp value to show on dropdown in vendor list  as vendor name
  const [vendorName, setVendor] = useState(
    localStorage.getItem('vendorData')
      ? JSON.parse(localStorage.getItem('vendorData'))?.label
      : ''
  )
  const [fields, setFields] = useState({
    //if vendor data not sotred value will be empty
    vendorNumber: '',
    // for buyer and manger role dropdown willl be empty and for other it will show full name
    // if already stored on local thatn will take form there and show on dropdown
    inventoryAnalyst: ''
  })
  // console.log(fields?.inventoryAnalyst)
  //Commented this code bcz we are using save filter API to get toggle column
  // useEffect(() => {
  //   // Check if columnVisibility exists in localStorage
  //   const storedColumnVisibility = localStorage.getItem('toggle_columns')
  //   if (storedColumnVisibility) {
  //     setColumnVisibility(JSON.parse(storedColumnVisibility))
  //   } else {
  //     // Initialize localStorage with default visibility if it doesn't exist
  //     localStorage.setItem('toggle_columns', JSON.stringify(columnVisibility))
  //     setPrefChanged(true) // Indicate that preferences were changed
  //   }
  // }, [])

  // useEffect(() => {
  //   if (prefChanged) {
  //     // Update localStorage when columnVisibility changes
  //     localStorage.setItem('toggle_columns', JSON.stringify(columnVisibility))
  //     setPrefChanged(false) // Reset prefChanged state
  //   }
  // }, [columnVisibility, prefChanged])

  const handleColumnToggle = (columnName) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [columnName]: !prevVisibility[columnName]
    }))
    setPrefChanged(true) // Indicate that preferences were changed
  }

  const [globalFilter, setGlobalFilter] = useState('')

  const onDragStart = (e) => {
    columnBeingDragged = Number(e.currentTarget.dataset.columnIndex)
  }

  const onDrop = (e) => {
    e.preventDefault()
    const newPosition = Number(e.currentTarget.dataset.columnIndex)
    const currentCols = table.getVisibleLeafColumns().map((c) => c.id)
    if (columnBeingDragged !== newPosition) {
      const colToBeMoved = currentCols.splice(columnBeingDragged, 1)
      currentCols.splice(newPosition, 0, colToBeMoved[0])
      table.setColumnOrder(currentCols)
    }
  }

  function selectTableRow(rowId) {
    table.getRowById(rowId)?.toggleSelected()
  }

  const handleInputChange = (fieldName, value) => {
    setFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value
    }))
  }
  // const location = useLocation()
  // useEffect(() => {
  //   if (
  //     location?.pathname?.includes('/buyer/po_list') &&
  //     location?.state
  //   ) {
  //     setColumnFilters(location?.state)
  //   }
  // }, [data])

  const resetColumnOrderPl = () => {
    localStorage.setItem('po_list_order', [])
  }
  const [exportAllData, setExportAll] = useState([])
  const fetchPoData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/search-oracle-po-data`,
        ValidateAPI()
      )
      .then((response) => {
        console.log(`response from po data: `, response?.data)
        setExportAll(response?.data)
        // setData(response?.data)
        // setLoading(false)
      })
      .catch((error) => {
        console.log('Error from po data: ', error)
        setLoading(false)
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }
  useEffect(() => {
    if (user?.roleType === 'MANAGER') fetchPoData()
  }, [])
  const handleCRHistoryClick = (poNumber, sku) => {
    const detailsUrl = `/cr_history/${poNumber}/${sku}`
    const popupWindow = window.open(
      detailsUrl,
      '_blank',
      'width=950,height=800,resizable=yes,scrollbars=yes'
    )

    if (popupWindow) {
      popupWindow.focus()
    }
  }

  const handleCRDetailsClick = (vendor, value) => {
    const user = JSON.parse(localStorage.getItem('userData'))
    if (user?.userGroupName === 'OVERSEAS_ROLE') return
    console.log('cr id from hcrc: ', value)
    const detailsUrl = `/buyer/hft_buyer_change_requests/request_details/${value}`
    const popupWindow = window.open(
      detailsUrl,
      '_blank',
      'width=950,height=800,resizable=yes,scrollbars=yes'
    )
    if (popupWindow) {
      popupWindow.focus()
    }
  }

  // const handleSearch = () => {
  //   if ((user?.roleType === "MANAGER" || user?.userGroupName === "OVERSEAS_ROLE") &&
  //     (!fields?.vendorNumber && !fields?.inventoryAnalyst))
  //     return
  //   if (!fields?.vendorNumber && !fields?.inventoryAnalyst) {
  //     return alert("Please select at least one search criteria.")
  //   }
  //   const cancelTokenSource = axios.CancelToken.source()
  //   setCancelTokenSource(cancelTokenSource)
  //   setLoading(true)
  //   setSearching(true)
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_API_DOMAIN}:8088/api/search-oracle-po-data?vendorNumber=${fields?.vendorNumber}&inventoryAnalyst=${fields?.inventoryAnalyst}&cpiOffice=${getOverseasRoleType()}`,
  //        ValidateAPI(),
  //       {
  //         cancelToken: cancelTokenSource.token
  //       }
  //     )
  //     .then((response) => {
  //       setData(response?.data)
  //       setRawData(response?.data)
  //       setLoading(false)
  //       setSearching(false)
  //       setColumnFilters([])
  //       // getunseenMessageListAPI(fields?.inventoryAnalyst)
  //     })
  //     .catch((error) => {
  //       if (axios.isCancel(error)) {
  //         console.log('Request canceled:', error.message)
  //       } else {
  //         console.log('Error while fetching search results: ', error)
  //       }
  //       setLoading(false)
  //       setSearching(false)
  //       if (error.response.status === 401 || error.response.status === 403) {
  //         navigate('/login-error')
  //       }
  //     })
  // }

  const handleSearch = (loadAll = false) => {
    const isManager = user?.roleType === 'MANAGER'
    const isOverseasRole = user?.userGroupName === 'OVERSEAS_ROLE'

    // If the role is MANAGER or OVERSEAS_ROLE and no criteria are selected, allow the search (skip alert on initial load)
    // if (
    //   (isManager || isOverseasRole) &&
    //   !loadAll &&
    //   !isInitialLoad &&
    //   !fields.vendorNumber &&
    //   !fields.inventoryAnalyst
    // ) {
    //   return alert('Please select at least one search criteria.')
    // }
    if ((isManager || isOverseasRole) && (!fields.vendorNumber && !fields.inventoryAnalyst && !fields.sku)) { loadAll = false }
    if (loadAll && (!fields.inventoryAnalyst && !fields.vendorNumber && !fields.sku)) {
      return alert("Please enter atleast one search criteria")
    }
    const cancelTokenSource = axios.CancelToken.source()
    setCancelTokenSource(cancelTokenSource)
    setLoading(true)
    setSearching(true)

    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/search-oracle-po-data?vendorNum=${fields?.vendorNumber || ''}&inventoryAnalyst=${fields?.inventoryAnalyst || ''}&cpiOffice=${isOverseasRole ? getOverseasRoleType() : ''}&sku=${fields?.sku || ''}`,
        ValidateAPI(),
        {
          cancelToken: cancelTokenSource.token
        }
      )
      .then((response) => {
        setData(response?.data)
        setRawData(response?.data)
        setLoading(false)
        setSearching(false)
        setColumnFilters([])
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message)
        } else {
          console.log('Error while fetching search results: ', error)
        }
        setLoading(false)
        setSearching(false)
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        } else if (error?.response?.status === 500) {
          navigate('/server-error')
        }
      })
  }

  function getFilterData(userid, tableName) {
    setLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/filter/preference/getFilterDetails?hftUserId=${userid}&tableName=${tableName}`,
        ValidateAPI()
      )
      .then((response) => {
        setFilterData(response?.data?.data)
        // setLoading(false);
        const data = response?.data?.data
        const vendorName = data?.vendorName ? data?.vendorName?.value : ''
        const vendorId = data?.vendorName ? data?.vendorName?.id : ''
        const sku = data?.sku ? data?.sku?.id : ''
        const buyerName = data?.buyerName
          ? data?.buyerName?.value
          : user?.roleType === 'MANAGER' ||
            user.userGroupName === 'OVERSEAS_ROLE'
            ? ''
            : user?.fullName
        handleInputChange('inventoryAnalyst', buyerName)
        handleInputChange('vendorNumber', vendorId)
        handleInputChange('sku', sku)
        setVendor(vendorName)
        // setColumnVisibility(
        //   // data?.toggleColumn ? data?.toggleColumn:initialFilter
        //   !data?.toggleColumn || Object.keys(data?.toggleColumnbj).length === 0 ? initialFilter : data?.toggleColumn
        // )
        setColumnVisibility(
          data?.toggleColumn? data?.toggleColumn:initialFilter
        )
        setcheck(true)
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        } else if (error.response.status === 404) {
          setcheck(true)
          const buyer =
            user?.roleType === 'MANAGER' ||
              user.userGroupName === 'OVERSEAS_ROLE'
              ? ''
              : user?.fullName
          handleInputChange('inventoryAnalyst', buyer)
        }
      })
  }
  useEffect(() => {
    getFilterData(user?.hftOracleId, tableName)
  }, [])

  useEffect(() => {
    if (check) {
      handleSearch()
    }
  }, [check])
  const closeCRModal = () => {
    setRequestDC('')
    setRequestSW('')
    setDate(new Date())
    setShowSWCRModal(false)
    setShowDCCRModal(false)
    // getIsAllColumnsVisible(false)
    table.resetRowSelection()
  }

  const closeChangeRequestModal = () => {
    setShowChangeRequestModal(false)
  }
  const closePopUpModal = () => {
    setPoRow(false)
    setShowCreateRequestModal(false)
    setShowChangeRequestBanner(false)
  }

  const handleCreateChangeRequest = (e) => {
    e?.preventDefault()
    const req_payload = []
    if (requestSW?.length > 0 || requestDC?.length > 0) {
      selectedArray?.map((item) => {
        const currentSW = item?.ORIGINAL_SHIP_WEEK
        const shipReqType =
          currentSW !== requestSW
            ? requestSW?.length > 0
              ? getWeekDifferenceStatus(
                item?.CURRENT_SW ?? item?.ORG_SW,
                requestSW
              )
              : ''
            : ''
        const dcReqType =
          item?.DC !== requestDC ? (requestDC?.length > 0 ? 'Divert' : '') : ''
        const req_obj = {
          id_po_supplier_portal: 1,
          po_number: Number(item?.PO_NUMBER),
          po_line_number: 1,
          revision_number: Number(item?.['REV#']),
          sku: item?.SKU,
          upc: item?.UPC,
          vendor_number: item?.['VENDOR#'],
          vendor_name: item?.VENDOR_NAME,
          request_type: `${shipReqType + '' + dcReqType}`,
          description: item?.DESCRIPTION,
          original_dc: item?.DC,
          requested_dc: requestDC,
          reason_code_dc: '',
          original_ship_week: item?.ORG_SW,
          requested_ship_week: requestSW ? requestSW : item?.REQ_SW,
          reason_code_ship_week: '',
          current_ship_week: item?.CURRENT_SW,
          original_qty: item?.QUANTITY,
          requested_qty: item?.QUANTITY,
          reason_code_qty: '',
          hft_user_id: Number(user?.hftOracleId),
          supplier_contact_id: 123,
          supplier_response: 'Awaiting Response',
          status: 'OPEN',
          followups: 1,
          qtyOpen: item?.OPEN_QTY,
          inventory_analayst: item?.INVENTORY_ANALYST,
          vendor_manager: item?.VENDOR_MANAGER,
          created_by: user?.fullName,
          ontimetarget: item?.ONTARGET_SHIP_WK
            ? item?.ONTARGET_SHIP_WK
            : item?.CURRENT_SW,
          shipweekdelay: Math.abs(
            weeksInBetween(
              item?.ONTARGET_SHIP_WK
                ? item?.ONTARGET_SHIP_WK
                : item?.CURRENT_SW,
              item?.CURRENT_SW
            )
          )
        }
        //if we select ship week than ontime target will be requested sw and ship week delay will be (requestedsw -currentSw)
        //if we select than ontime target will be same as previous nad ship week delay will be 0
        // shipweekdelay: Math.abs(
        //   weeksInBetween(requestSW ? requestSW : item?.ONTARGET_SHIP_WK, item?.CURRENT_SW)
        // )
        req_payload?.push(req_obj)
      })

      console.log('Response before change Request: ', req_payload)
      console.log('Array payload: ', req_payload)

      setRequestPayload(req_payload)
      setShowChangeRequestModal(true)
    } else {
      alert(i18next.t('PopUpMessage.Therearenochangesmadetosubmit'))
    }
  }

  const handleConfirmOrder = () => {
    setSubmitting(true)
    closeChangeRequestModal()
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/create-request`,
        requestPayload,
        ValidateAPI()
      )
      .then((response) => {
        console.log('response after Change request:', response?.data)

        updateActionHistory('Created', response?.data)

        const poRow = requiredOutput()
        // console.log('row dc: ', poRow[0]?.PO_NUMBER)
        // console.log('selected actual data: ', requiredOutput())
        setPoRow(poRow[0]?.PO_NUMBER || 'NULL')

        if (response?.data) {
          const crIdResponse = response?.data?.map(
            (item) => item.id_open_request
          )
          console.log('crIdResponse:', crIdResponse)
          setCrIdResponse(crIdResponse)
        }
        setShowChangeRequestBanner(true)
        if (
          fields?.poNumber?.trim() !== '' ||
          fields?.sku?.trim() !== '' ||
          fields?.vendorNumber?.trim() !== '' ||
          fields?.upc?.trim() !== '' ||
          fields?.dc?.trim() !== '' ||
          fields?.inventoryAnalyst?.trim() !== '' ||
          fields?.vendorManager?.trim() !== '' ||
          fields?.shipOff?.trim() !== ''
        ) {
          handleSearch()
        }

        handleSearch()
        setRowSelection({})
        setSelectedArray([])
        setSubmitting(false)
        closeCRModal()
      })
      .catch((error) => {
        console.log('error while creating a CR: ', error)
        alert(
          i18next.t(
            'PopUpMessage.ErrorwhilecreatingaChangeRequestPleasetryagain'
          )
        )
        closeCRModal()
      })
  }

  const handleChangeDate = (date) => {
    setDate(date)
    setRequestSW(getWeek(date))
    setinputsw(getWeek(date))
  }

  const generateCSVData = (table) => {
    const data = table?.getFilteredRowModel()?.rows?.map((row) => {
      return row?.original
    })
    // console.log(data, 'dataaaa')
    return data
  }

  const SearchPONumber = (value) => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/notification/searchByPoString?poSearch=${value}`,
        ValidateAPI()
      )
      .then((response) => {
        setpoNumberList(response?.data)
      })
      .catch((error) => {
        // alert(error?.response?.data);
      })
  }

  useEffect(() => { }, [crIdResponse])

  useEffect(() => {
    applyFilters()
  }, [dataFilter, rawData])

  useEffect(() => {
    const fetchUnreadCounts = async () => {
      const currentPagePOs = table.getRowModel().rows.map((row) => ({
        poNumber: row.original.PO_NUMBER,
        poSku: row.original.SKU
      }))

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_DOMAIN}:8088/api/messages/count`,
          currentPagePOs,
          ValidateAPI()
        )
        setMessageCounts(response.data)
      } catch (error) {
        console.error('Error fetching unread message counts:', error)
      }
    }

    fetchUnreadCounts() // Initial fetch
    const intervalId = setInterval(fetchUnreadCounts, 5000) // Fetch every 5 seconds

    return () => clearInterval(intervalId) // Cleanup interval on unmount
  }, [table])

  const applyFilters = () => {
    let tempData = [...rawData]

    if (dataFilter === FilterTypes.NO_CR) {
      tempData = tempData.filter((item) => item.HFT_REQUEST === null)
    } else if (dataFilter === FilterTypes.CR) {
      tempData = tempData.filter((item) => item.HFT_REQUEST !== null)
    } else if (dataFilter === FilterTypes.PAST_DUE) {
      tempData = tempData.filter((item) => {
        if (item.CURRENT_SW) {
          const currentSW = item.CURRENT_SW.split('.').map(Number) // CURRENT_SW from data
          const presentSW = getPresentShipWeek().split('.').map(Number) // Today's Shipweek
          return (
            currentSW[1] < presentSW[1] ||
            (currentSW[1] === presentSW[1] && currentSW[0] < presentSW[0])
          )
        } else if (item?.REQ_SW) {
          const currentSW = item.REQ_SW.split('.').map(Number) // CURRENT_SW from data
          const presentSW = getPresentShipWeek().split('.').map(Number) // Today's Shipweek
          return (
            currentSW[1] < presentSW[1] ||
            (currentSW[1] === presentSW[1] && currentSW[0] < presentSW[0])
          )
        }
        return false
      })
    }
    setData(tempData)
  }

  const handleFilterChange = (filterName) => {
    setDataFilter(filterName)
  }

  useEffect(() => {
    // GetVendorNumber()
    SearchBuyer(getOverseasRoleType())
    // getVendorList()
    searchVendorORSKU(getOverseasRoleType())
    //getSKU()
    SearchSKU(getOverseasRoleType())
  }, [])

  useEffect(() => {
    // console.log('Updated Rows: ', editArray)
  }, [editArray])
  //implementing by save filter aPI
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const savedColumnOrder = JSON.parse(localStorage.getItem('po_list_order'))
  //     const savedColumnToggle = JSON.parse(
  //       localStorage.getItem('toggle_columns')
  //     )
  //     const savedColumnFilters = JSON.parse(
  //       localStorage.getItem('column_filters')
  //     )
  //     if (
  //       (savedColumnOrder && savedColumnOrder?.length > 0) ||
  //       (savedColumnToggle && Object.keys(savedColumnToggle)?.length > 0) ||
  //       (savedColumnFilters &&
  //         savedColumnFilters?.length > 0 &&
  //         savedColumnFilters?.find((e) => e !== null))
  //     ) {
  //       if (savedColumnOrder) {
  //         if (Array.isArray(savedColumnOrder)) {
  //           setColumnOrder(savedColumnOrder)
  //         } else {
  //           console.log('Invalid data in localStorage. Expected an array.')
  //         }
  //       } else if (savedColumnToggle) {
  //         setColumnVisibility(savedColumnToggle)
  //       } else if (savedColumnFilters) {
  //         if (Array.isArray(savedColumnFilters)) {
  //           setColumnFilters(savedColumnFilters)
  //         }
  //       }
  //       setHasPreference(true)
  //     } else {
  //       const userPrefData = await getUserPreference(user?.hftOracleId)
  //       if (userPrefData?.hftUserId) {
  //         setColumnOrder(userPrefData?.poListColumns)
  //         setColumnFilters(userPrefData?.clFilters)
  //         setColumnVisibility(userPrefData?.toggleColumns)
  //         setHasPreference(true)
  //       } else {
  //         const defaultColumnOrder = columns.map((column) => column.accessor)
  //         setColumnOrder(defaultColumnOrder)
  //         setHasPreference(false)
  //       }
  //     }
  //   }
  //   fetchData()
  // }, [columns, user?.hftOracleId])

  useEffect(() => {
    return () => {
      if (prefChanged) {
        console.log('clean up start')
        if (hasPreference) {
          console.log('clean up update func')
          updateUserPreference(user?.hftOracleId)
        } else {
          console.log('clean up create func')
          createUserPreference(user?.hftOracleId)
        }
      }
    }
  }, [prefChanged, hasPreference, user?.hftOracleId])

  useEffect(() => {
    localStorage.setItem('po_list_order', JSON.stringify(columnOrder))
    setPrefChanged(true)
  }, [columnOrder])

  // useEffect(() => {
  //   console.log(
  //     'On column togle: ',
  //     JSON.stringify(table.getState().columnVisibility)
  //   )
  //   localStorage?.setItem(
  //     'toggle_columns',
  //     JSON.stringify(table.getState().columnVisibility, null, 2)
  //   )
  //   setPrefChanged(true)
  // }, [columnVisibility])
  useEffect(() => {
    setPrefChanged(true)
  }, [columnFilters])

  const closeCreateLineRequestModal = () => {
    setShowCreateLineRequestModal(false)
  }

  const openCreateLineRequestModal = () => {
    setShowCreateLineRequestModal(true)
  }

  const handleMessageIconClick = (po, sku) => {
    setSelectedPOForMessageBox(po)
    setSelectedSKUForMessageBox(sku)
    setIsMessageBoxOpen(true)
  }

  const handleCloseMessageBox = () => {
    setSelectedPOForMessageBox(null)
    setSelectedSKUForMessageBox(null)
    setIsMessageBoxOpen(false)
  }

  const getUnreadCount = (po, sku) => {
    const item = messageCounts.find(
      (count) => count.poNumber === po && count.poSku === sku
    )
    return item ? item.unseenForBuyers : 0
  }

  const handleCreateLineRequests = (e) => {
    e?.preventDefault()
    const req_payload = []
    editArray?.map((item) => {
      if (item?.UPDATED_SW) {
        const shipReqType = getWeekDifferenceStatus(
          item?.CURRENT_SW ?? item?.ORG_SW,
          item?.UPDATED_SW
        )
        const req_obj = {
          id_po_supplier_portal: 1,
          po_number: Number(item?.PO_NUMBER),
          po_line_number: 1,
          revision_number: Number(item?.['REV#']),
          sku: item?.SKU,
          upc: item?.UPC,
          vendor_number: item?.['VENDOR#'],
          vendor_name: item?.VENDOR_NAME,
          request_type: shipReqType,
          description: item?.DESCRIPTION,
          original_dc: item?.DC,
          requested_dc: '',
          reason_code_dc: '',
          original_ship_week: item?.ORG_SW,
          requested_ship_week: item?.UPDATED_SW,
          reason_code_ship_week: '',
          current_ship_week: item?.CURRENT_SW,
          original_qty: item?.QUANTITY,
          requested_qty: item?.QUANTITY,
          reason_code_qty: '',
          hft_user_id: Number(user?.hftOracleId),
          supplier_contact_id: 123,
          supplier_response: 'Awaiting Response',
          status: 'OPEN',
          followups: 1,
          qtyOpen: item?.OPEN_QTY,
          inventory_analayst: item?.INVENTORY_ANALYST,
          vendor_manager: item?.VENDOR_MANAGER,
          created_by: user?.fullName,
          ontimetarget: item?.ONTARGET_SHIP_WK
            ? item?.ONTARGET_SHIP_WK
            : item?.CURRENT_SW,
          shipweekdelay: Math.abs(
            weeksInBetween(
              item?.ONTARGET_SHIP_WK
                ? item?.ONTARGET_SHIP_WK
                : item?.CURRENT_SW,
              item?.CURRENT_SW
            )
          )
        }
        req_payload?.push(req_obj)
      } else if (item?.UPDATED_DC) {
        const req_obj = {
          id_po_supplier_portal: 1,
          po_number: Number(item?.PO_NUMBER),
          po_line_number: 1,
          revision_number: Number(item?.['REV#']),
          sku: item?.SKU,
          upc: item?.UPC,
          vendor_number: item?.['VENDOR#'],
          vendor_name: item?.VENDOR_NAME,
          request_type: 'Divert',
          description: item?.DESCRIPTION,
          original_dc: item?.DC,
          requested_dc: item?.UPDATED_DC,
          reason_code_dc: '',
          original_ship_week: item?.ORG_SW,
          requested_ship_week: item?.REQ_SW,
          reason_code_ship_week: '',
          current_ship_week: item?.CURRENT_SW,
          original_qty: item?.QUANTITY,
          requested_qty: item?.QUANTITY,
          reason_code_qty: '',
          hft_user_id: Number(user?.hftOracleId),
          supplier_contact_id: 123,
          supplier_response: 'Awaiting Response',
          status: 'OPEN',
          followups: 1,
          qtyOpen: item?.OPEN_QTY,
          inventory_analayst: item?.INVENTORY_ANALYST,
          vendor_manager: item?.VENDOR_MANAGER,
          created_by: user?.fullName,
          ontimetarget: item?.ONTARGET_SHIP_WK,
          shipweekdelay: 0
        }
        req_payload?.push(req_obj)
      }
    })
    console.log('payload before change Request: ', req_payload)
    console.log('Array payload: ', req_payload)
    setShowDCCRModal(true)
    setSubmitting(true)
    closeCreateLineRequestModal()

    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/create-request`,
        req_payload,
        ValidateAPI()
      )
      .then((response) => {
        setShowDCCRModal(false)
        setSubmitting(false)
        console.log('response from CR line level:', response?.data)
        if (response?.data) {
          const crPoNumber = response.data.map((item) => item.po_number)
          const crIdNumber = response.data.map((item) => item.id_open_request)
          console.log('crponumber line level', crPoNumber)
          console.log('crIdNumber line level', crIdNumber)
          setCrIdNumber(crIdNumber)
          setCrPonumber(crPoNumber)
        }

        updateActionHistory('Created', response?.data)
        setShowCreateRequestModal(true)
        // alert(i18next.t('PopUpMessage.ChangeRequestupdated'))
        if (
          fields?.poNumber?.trim() !== '' ||
          fields?.sku?.trim() !== '' ||
          fields?.vendorNumber?.trim() !== '' ||
          fields?.upc?.trim() !== '' ||
          fields?.dc?.trim() !== '' ||
          fields?.inventoryAnalyst?.trim() !== '' ||
          fields?.vendorManager?.trim() !== '' ||
          fields?.shipOff?.trim() !== ''
        ) {
          handleSearch()
        }
        handleSearch()
        setRowSelection({})
        dispatch({
          type: 'CLEAR_LIST'
        })
      })
      .catch((error) => {
        console.log('error while creating a CR: ', error)
        alert(
          i18next.t(
            'PopUpMessage.ErrorwhilecreatingaChangeRequestPleasetryagain'
          )
        )
      })
  }
  useEffect(() => { }, [crIdNumber])

  const requiredOutput = () => {
    const selectedRowRaw = table?.getSelectedRowModel().flatRows
    console.log('selected raw data : ', selectedRowRaw)
    const requiredData = []
    selectedRowRaw?.map((row, index) => requiredData.push(row?.original))
    setSelectedArray(requiredData)
    return requiredData
  }

  useEffect(() => {
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('Component unmounted.')
      }
    }
  }, [])

  useEffect(() => {
    if (editArray?.length <= 0) {
      const firstROw = requiredOutput()
      const currentSW = firstROw[0]?.CURRENT_SW || ''

      // Check if the SW value is a single digit and add a leading zero if needed
      const formattedSW = currentSW.length === 1 ? `0${currentSW}` : currentSW
      setFirstRow(formattedSW)

      const dcRow = requiredOutput()
      setDcRow(dcRow)
    }

  }, [rowSelection])

  const allPopUp = React.useRef(null)

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (allPopUp.current && !allPopUp.current.contains(event.target)) {
        // Clicked outside the notification, close it
        setUnseenBox(false)
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const cleared = () => {
    const localStorageKey = 'all_selected_values'
    localStorage.removeItem(localStorageKey)
    setColumnFilters([])
    setClearState((prevState) => !prevState) // Toggle the state
    setFilterData({})
    addFilterData({
      hftUserId: user?.hftOracleId,
      tableName: tableName,
      clFilter: []
    })
  }

  const handleColumnResizeEnd = (header) => {
    const newWidth = header.getSize()
    const storedWidths = JSON.parse(localStorage.getItem('column-widths')) || {}
    storedWidths[header.id] = newWidth
    localStorage.setItem('column-widths', JSON.stringify(storedWidths))
  }

  const loadColumnWidths = (columns) => {
    const storedWidths = JSON.parse(localStorage.getItem('column-widths')) || {}
    columns.forEach((column) => {
      if (storedWidths[column.id]) {
        column.columnDef.size = Number(storedWidths[column.id])
      }
    })
  }

  useEffect(() => {
    loadColumnWidths(table.getAllColumns())
    // localStorage.removeItem('column-widths');
  }, [table])

  useEffect(() => {
    // Event listener to remove item from localStorage on window reload
    const handleBeforeUnload = (event) => {
      // Custom logic to determine if you want to clear localStorage on reload
      localStorage.removeItem('column-widths')
    }
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, []) // Empty dependency array ensures this effect runs only once on mount

  const getCommonPinningStyles = (column, columns) => {
    const isPinned = column.getIsPinned()
    const leftPinnedColumns = columns.filter(
      (col) => col.getIsPinned() === 'left'
    )
    const rightPinnedColumns = columns.filter(
      (col) => col.getIsPinned() === 'right'
    )
    const isLastLeftPinnedColumn =
      isPinned === 'left' &&
      leftPinnedColumns[leftPinnedColumns.length - 1]?.id === column.id
    const isFirstRightPinnedColumn =
      isPinned === 'right' && rightPinnedColumns[0]?.id === column.id

    return {
      boxShadow: isLastLeftPinnedColumn
        ? '-4px 0 4px -4px gray inset'
        : isFirstRightPinnedColumn
          ? '4px 0 4px -4px gray inset'
          : undefined,
      left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
      right: isPinned === 'right' ? `${column.getStart('right')}px` : undefined,
      opacity: isPinned ? 0.95 : 1,
      position: isPinned ? 'sticky' : 'relative',
      width: column.getSize(),
      zIndex: isPinned ? 1 : 0
    }
  }
  const RendorUnreadMessage = (data) => {
    console.log('message', data.poNumber)
    setColumnFilters([
      {
        id: 'PO_NUMBER',
        value: data.poNumber
      }
    ])
    setUnseenBox(false)
  }

  const [unseenList, setunseenlist] = useState([])

  const getunseenMessageListAPI = () => {
    //get unseen msg based on role and email
    //if don't pass email than it will fetch all unseddn mg based on role

    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/messages/unseen/count?roleType=${getUserRole()}&email=${fields?.inventoryAnalyst}  `,
        ValidateAPI()
      )
      .then((res) => {
        setunseenlist(res.data)
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }
  // const[name,setName]
  useEffect(() => {
    getunseenMessageListAPI()
    const intervalId = setInterval(getunseenMessageListAPI, 5000)
    return () => clearInterval(intervalId)
  }, [fields])

  useEffect(() => {
    const handleClickOutside = () => {
      setShowChangeRequestBanner(false)
      setShowCreateRequestModal(false)
    }
    if (showChangeRequestBanner || showCreateRequestModal) {
      window.addEventListener('click', handleClickOutside)
    }
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showChangeRequestBanner, showCreateRequestModal])

  // useEffect(() => {

  //   if (user?.userGroupName === "OVERSEAS_ROLE") {
  //     handleSearch(true);
  //   }
  //   setIsInitialLoad(false);
  // }, []);

  const getUserRole = () => {
    if (user?.roleType === 'MANAGER') return user?.roleType
    else if (user?.roleType === 'BUYER') return user?.roleType
    else return user?.roleType
  }
  const getOverseasRoleType = () => {
    if (user.userGroupRole === '5') return user?.roleType
    else return ''
  }
  const checkPastDue = (currSW) => {
    if (currSW) {
      const currentSW = currSW.split('.').map(Number) // CURRENT_SW from data
      const presentSW = getPresentShipWeek().split('.').map(Number) // Today's Shipweek
      return (
        currentSW[1] < presentSW[1] ||
        (currentSW[1] === presentSW[1] && currentSW[0] < presentSW[0])
      )
    }
    return false
  }

  //will call when select any buyer firm dropdown
  const handleChangeBuyer = (select) => {
    //store data for filter when come back on same screen from another page
    localStorage.setItem('buyerData', JSON.stringify(select))
    console.log({ id: select?.label, value: select?.value })
    // console.log(value);
    if (select === null) handleInputChange('inventoryAnalyst', '')
    else handleInputChange('inventoryAnalyst', select?.value)
    addFilterData({
      hftUserId: user?.hftOracleId,
      tableName: tableName,
      buyerName: { id: select?.label, value: select?.value }
    })
  }
  //will call when select any vendor firm dropdown
  const handleChangeVendor = (select) => {
    //store data for filter when come back on same screen from another page
    if (select === null) {
      handleInputChange('vendorNumber', '')
      setVendor('')
    } else {
      handleInputChange('vendorNumber', select?.value)
      setVendor(select?.label)
    }
    addFilterData({
      hftUserId: user?.hftOracleId,
      tableName: tableName,
      vendorName: { id: select?.value, value: select?.label }
    })
  }
  //will call when select any vendor firm dropdown
  const handleChangeSku = (select) => {
    //store data for filter when come back on same screen from another page
    localStorage.setItem('skuData', JSON.stringify(select))
    if (select === null) {
      handleInputChange('sku', '')
    } else {
      handleInputChange('sku', select?.value)
    }
    addFilterData({
      hftUserId: user?.hftOracleId,
      tableName: tableName,
      sku: { id: select?.value, value: select?.label }
    })
  }

  const [isChecked, setIsChecked] = useState(false)
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked)
    handletogglefn()
  }

  //To save Toggle List in bulk 
  const handletogglefn = () => {
    const allVisible = table?.getIsAllColumnsVisible()
    let toggle_List = {}
    table?.getAllLeafColumns().forEach((column) => {
      if (
        user?.userGroupName === 'OVERSEAS_ROLE' &&
        (column.id === 'UNIT_COST' || column.id === 'EXTENDED_COST')
      ) {
        column.toggleVisibility(false)
        toggle_List[column.id] = false;//Get the dynamic key  Set the value based on the checkbox state
      } else {
        if (!isChecked) column.toggleVisibility(true)
        else column.toggleVisibility(false)
        toggle_List[column.id] = !isChecked;//Get the dynamic key  Set the value based on the checkbox state
      }
    })
    addFilterData({
      hftUserId: user?.hftOracleId,
      tableName: tableName,
      toggle_column: { ...table?.getState().columnVisibility, ...toggle_List }
    })
  }
   //To save Toggle one by one  
  const saveToggleForEach = (e, column) => {
    const isChecked = e?.target?.checked
    column.toggleVisibility(isChecked)
    addFilterData({
      hftUserId: user?.hftOracleId,
      tableName: tableName,
      toggle_column: { ...table?.getState().columnVisibility, ...{ [column.id]: isChecked } }
    })
  }
  useEffect(() => {
    if (selectedArray?.length > 0) {
      setValidSw(false) // Clear error message
    }
  }, [selectedArray])
 
  return (
    <div className='w-full flex flex-col gap-4 p-3 h-full'>
      <div className='w-full flex justify-end text-xs font-medium items-center'>
        <div className='flex gap-2 rounded-md bg-blue-100 p-2 items-center justify-center text-xs font-medium'>
          <div className='flex items-center justify-center gap-2'>
            <div>
              {/* <h3 className="mb-1">INVENTORY ANALYST</h3> */}
              <SelectList
                List={buyerListData}
                handleChangeData={handleChangeBuyer}
                defaultName={fields?.inventoryAnalyst}
                placeholder='INVENTORY ANALYST'
                isClearable={true}
              />
            </div>
            <div>
              {/* <h3 className="mb-1">VENDOR</h3> */}
              <SelectList
                List={vendorListData}
                handleChangeData={handleChangeVendor}
                placeholder='VENDOR'
                defaultName={vendorName}
                isClearable={true}
              />
            </div>
            <div>
              <SelectList
                List={skuListData}
                handleChangeData={handleChangeSku}
                placeholder='SKU'
                defaultName={fields?.sku}
                isClearable={true}
              />
            </div>

            <button
              className='bg-[#415da1] px-4 h-[30px] rounded-md outline-none text-white min-w-max '
              disabled={searching}
              onClick={handleSearch}
            >
              <p>{Filters}</p>
            </button>
          </div>
          {Object.values(FilterTypes)?.map((buttonName) => {
            return (
              <button
                key={'type' + buttonName}
                className={`p-2 rounded-md min-w-max transition  h-[30px] ease-in-out delay-150 outline-none focus:outline-none ${dataFilter === buttonName
                  ? 'bg-blue-400 text-white'
                  : 'bg-white'
                  }`}
                onClick={() => handleFilterChange(buttonName)}
              >
                {buttonName}
              </button>
            )
          })}
          {unseenList.totalCount > 0 && (
            <div className='relative'>
              <button onClick={() => setUnseenBox(!openunseenmsgBox)}>
                <MessageIconRead />
                {unseenList.totalCount > 0 && (
                  <span className='absolute -mt-6 ml-1 rounded-full bg-red-600 px-[0.45em] py-[0.2em] text-[0.6rem] leading-none text-white'>
                    {unseenList?.totalCount}
                  </span>
                )}
              </button>
              {openunseenmsgBox && (
                <div
                  ref={allPopUp}
                  className='absolute z-30  bg-[#e0ecfc] border border-gray-300 rounded-lg shadow-xl right-0 top-7  min-w-[200px] justify-center max-h-[400px] overflow-y-auto '
                >
                  <h1 className='text-lg text-center  pt-2 sticky top-0  bg-[#e0ecfc]'>
                    New Message
                  </h1>
                  {unseenList?.messageCounts?.map((data) => {
                    return (
                      <div
                        className='flex items-center p-2 gap-1 cursor-pointer'
                        onClick={() => RendorUnreadMessage(data)}
                      >
                        <span className='text-red-500'>
                          <MessageIconUnread />
                        </span>
                        <h1 className='text-gray-600'>
                          PO Number : {data.poNumber}
                        </h1>
                        {/* <span className='p-1 rounded-xl bg-red-500 text-white'>{data.count}</span> */}
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className='w-full justify-between flex items-center text-xs gap-2'>
        <div className='relative flex justify-start items-center gap-2'>
          <div className='relative flex justify-start items-center'>
            <button
              className='font-medium text-white outline-none bg-[#415da1]  rounded-md p-2'
              onClick={() => setShowSelectColumn(!showSelectColumn)}
            >
              {Columns}
            </button>
            {showSelectColumn && (
              <div
                className={`${showSelectColumn ? 'transition opacity-100' : 'opacity-0'
                  } border border-black shadow rounded absolute top-10 h-[250px] w-[230px] overflow-y-auto bg-[#fff] z-20 flex flex-col gap-2`}
              >
                <div className='p-1 px-2 border-b border-black flex gap-2 items-center py-2'>
                  <input
                    type='checkbox'
                    id='column_toggle_all'
                    name='toggle_all_checkbox'
                    checked={isChecked}
                    // checked={() => {
                    //   // Toggle visibility of all columns
                    //   const allVisible = table.getIsAllColumnsVisible();
                    //   setColumnVisibility({
                    //     ...columnVisibility,
                    //     UNIT_COST: allVisible ? false : true,
                    //     EXPEDITED_COST: allVisible ? false : true,
                    //   });
                    // }}
                    onChange={handleCheckboxChange}
                  />
                  <label className='' htmlFor='column_toggle_all'>
                    {ToggleAll}
                  </label>
                </div>
                {table
                  ?.getAllLeafColumns()
                  // ?.filter(
                  //   (column) =>
                  //     column?.columnDef?.header !== 'PO-SKU' &&
                  //     column?.columnDef?.header !== 'ACTION TYPE'
                  // ) // Filter out the column with the header 'po-sku'
                  ?.map((column, index) =>
                    // condition to remove unit cost and extended cost for oversesas User
                    user?.userGroupName === 'OVERSEAS_ROLE' ? (
                      column?.id !== 'UNIT_COST' &&
                      column?.id !== 'EXTENDED_COST' && (
                        <div
                          key={column?.id + index}
                          className='px-2 py-1 flex gap-2 items-center'
                        >
                          <input
                            type='checkbox'
                            name='column_name'
                            id={column?.id}
                            checked={column?.getIsVisible()}
                            onChange={(e) => saveToggleForEach(e, column)}
                          />
                          <label htmlFor={column?.id}>
                            {column?.columnDef.header}
                          </label>
                        </div>
                      )
                    ) : (
                      <div
                        key={column?.id + index}
                        className='px-2 py-1 flex gap-2 items-center'
                      >
                        <input
                          type='checkbox'
                          name='column_name'
                          id={column?.id}
                          checked={column?.getIsVisible()}
                          onChange={(e) => saveToggleForEach(e, column)}
                        />
                        <label htmlFor={column?.id}>
                          {column?.columnDef.header}
                        </label>
                      </div>
                    )
                  )}
              </div>
            )}
          </div>

          <div className='flex gap-2 items-center justify-start'>
            <button
              className={`p-2 rounded-md outline-none text-white min-w-max ${editArray?.length >= 0 && selectedArray.length == 0
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-[#415da1]'
                }`}
              disabled={editArray?.length >= 0 && selectedArray.length === 0}
              onClick={() => {
                setShowSWCRModal(!showSWCRModal)
                setRequestDC('')
                setValidSw(false)
              }}
            >
              {ChangeShipweek}
            </button>

            <button
              className={`p-2 rounded-md outline-none text-white min-w-max ${editArray?.length >= 0 && selectedArray.length === 0
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-[#415da1]'
                }`}
              disabled={editArray?.length >= 0 && selectedArray.length === 0}
              onClick={() => {
                setShowDCCRModal(!showDCCRModal)
                setRequestSW('')
                setValidSw(false)
              }}
            >
              {ChangeDC}
            </button>

            <button
              className={`p-2 rounded-md outline-none text-white min-w-max ${editArray?.length <= 0 || validSw
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-[#415da1]'
                }`}
              disabled={editArray?.length <= 0 || validSw}
              onClick={openCreateLineRequestModal}
            >
              {CreateRequest}
            </button>

            <PopupModal
              isOpen={showCreateLineRequestModal}
              message={i18next.t(
                'PopUpMessage.Doyouwanttocreateachangerequest'
              )}
              onCancel={closeCreateLineRequestModal}
              cancelText={i18next.t('AcceptModal.Cancel')}
              onConfirm={handleCreateLineRequests}
              confirmText={i18next.t('AcceptModal.Confirm')}
            />
          </div>
        </div>
        <PopupBanner
          isOpen={showCreateRequestModal}
          message={i18next.t(
            'PopUpMessage.Changerequestissubmittedsuccessfully'
          )}
          onCancel={closePopUpModal}
          text={crPoNumber}
          text1={crIdNumber}
        />

        <div className='flex items-center gap-2'>
          {/* <pre>{JSON.stringify(filter, null, "\t")}</pre> */}
          {columnFilters?.length > 0 && (
            <button
              className='font-medium text-white outline-none bg-[#415da1] w-[90px] rounded-md p-2'
              onClick={() => {
                cleared()
              }}
            >
              {ClearFilters}
            </button>
          )}
          {/* this is for download csv based on filter  */}
          {data?.length !== 0 && downloadCSV('PO_list', generateCSVData(table))}
          {/* this is for download csv for all data only for manager  */}
          {user?.roleType === 'MANAGER' &&
            (exportAllData?.length > 0 ? (
              downloadCSV('PO_list', exportAllData, 'Export All')
            ) : (
              <Loader />
            ))}
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span className='min-w-max flex items-center gap-1'>
            <div>{Page}</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </strong>
          </span>
          <span className='min-w-max flex items-center gap-1'>
            | {Gotopage}
            <input
              type='number'
              id='pagination_input'
              name='pagination_input'
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                table.setPageIndex(page)
              }}
              className='border p-1 rounded w-16'
            />
          </span>
          <select
            className='border border-gray-300 rounded-md p-1 outline-none'
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {CalculateTableArray(
              table?.getFilteredRowModel()?.rows?.length
            ).map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {Show} {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* {validSw && (
        <p className='text-red-500 text-xs '>
         Please Select a PO
        </p>
      )} */}

      {!loading ? (
        <>
          <div
            className='w-full overflow-x-auto'
            style={{ minHeight: '70vh', minWidth: '660px' }}
          >
            <table className='text-xs border border-gray-300  table-auto'>
              <thead className='top-0 '>
                {table.getHeaderGroups()?.map((headerGroup, index) => (
                  <tr
                    key={headerGroup?.id + index}
                    className='divide-x-[1px] bg-[#e0ecfc] divide-gray-300 border-[#ccd9e4] border'
                  >
                    <th
                      className='font-medium px-2 py-2  frozen-column'
                      style={{ width: '100px' }}
                    >
                      {user?.userGroupName !== 'OVERSEAS_ROLE' && (
                        <IndeterminateCheckbox
                          checked={table.getIsAllPageRowsSelected()}
                          indeterminate={table.getIsSomePageRowsSelected()}
                          onChange={table.getToggleAllPageRowsSelectedHandler()}
                        />
                      )}
                    </th>
                    {headerGroup?.headers?.map((header) => (
                      <th
                        key={header?.id}
                        className={`font-medium px-2 py-2 top-0 ${header.id === 'PO_SKU' || header.id === 'REQUEST_TYPE' ? 'frozen-column' : 'unFrozen'}`}
                        {...{
                          key: header.id,
                          colSpan: header.colSpan,
                          style: {
                            width: header.getSize()
                          }
                        }}
                        draggable={
                          header.id !== 'PO_SKU' &&
                          header.id !== 'REQUEST_TYPE' &&
                          !table.getState().columnSizingInfo.isResizingColumn
                        }
                        data-column-index={header.index ?? -1}
                        onDragStart={
                          header.id !== 'PO_SKU' && header.id !== 'REQUEST_TYPE'
                            ? onDragStart
                            : undefined
                        }
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={
                          header.id !== 'PO_SKU' && header.id !== 'REQUEST_TYPE'
                            ? onDrop
                            : undefined
                        }
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler()
                            }}
                          >
                            <div className='flex justify-center h-9'>
                              {' '}
                              {/* Container with Flexbox */}
                              {/* <div className='text-center'> */}
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {/* </div> */}
                              &nbsp;&nbsp;
                              <div>
                                {{
                                  asc: (
                                    <svg
                                      className='w-3 h-4 text-gray-500'
                                      // className="w-4 h-5 text-gray-500" // Increased size with w-4 and h-5
                                      aria-hidden='true'
                                      xmlns='http://www.w3.org/2000/svg'
                                      fill='none'
                                      viewBox='0 0 10 14'
                                    // viewBox="0 0 14 20" // Adjusted viewBox for larger arrow
                                    >
                                      <path
                                        stroke='currentColor'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13V1m0 0L1 5m4-4 4 4'
                                      // d="M1 5h12m0 0-4 4m4-4L9 1"
                                      />
                                    </svg>
                                  ),
                                  desc: (
                                    <svg
                                      className='w-3 h-4 text-gray-500'
                                      // className="w-4 h-5 text-gray-500" // Increased size with w-4 and h-5
                                      aria-hidden='true'
                                      xmlns='http://www.w3.org/2000/svg'
                                      fill='none'
                                      viewBox='0 0 10 14'
                                    // viewBox="0 0 14 20" // Adjusted viewBox for larger arrow
                                    >
                                      <path
                                        stroke='currentColor'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 1v12m0 0 4-4m-4 4L1 9'
                                      // d="M13 5H1m0 0 4 4m-4-4 3-3m0 6V1m0 0 3 3m-3-3 3 3"
                                      />
                                    </svg>
                                  )
                                }[header.column.getIsSorted()] ?? (
                                    <svg
                                      className='w-3 h-4 text-gray-500'
                                      aria-hidden='true'
                                      xmlns='http://www.w3.org/2000/svg'
                                      fill='none'
                                      viewBox='0 0 16 20'
                                    >
                                      <path
                                        stroke='currentColor'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3'
                                      // d="M4 6h10m0 0-3-3m3 3-3 3m1 8H1m0 0 3-3m-3 3 3 3"
                                      />
                                    </svg>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter
                              column={header.column}
                              table={table}
                              columnFilters={columnFilters}
                              values={values}
                              setValues={setValues}
                              header={header}
                              ClearState={ClearState}
                              tableId={tableName}
                              hftid={user?.hftOracleId}
                              filterValue={filter}
                            />
                          </div>
                        ) : null}
                        {/* <div
                        onDoubleClick={() => header.column.resetSize()}
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`resizer ${table.options.columnResizeDirection} ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                        style={{
                          transform:
                            columnResizeMode === 'onEnd' && header.column.getIsResizing()
                              ? `translateX(${(table.options.columnResizeDirection === 'rtl' ? -1 : 1) *
                              (table.getState().columnSizingInfo.deltaOffset ?? 0)}px)`
                              : '',
                        }}
                      /> */}

                        {header.id !== 'REQUEST_TYPE' && (
                          <div
                            onDoubleClick={() => header.column.resetSize()}
                            onMouseDown={(e) => {
                              header.getResizeHandler()(e)
                              document.addEventListener(
                                'mouseup',
                                () => handleColumnResizeEnd(header),
                                { once: true }
                              )
                            }}
                            onTouchStart={(e) => {
                              header.getResizeHandler()(e)
                              document.addEventListener(
                                'touchend',
                                () => handleColumnResizeEnd(header),
                                { once: true }
                              )
                            }}
                            className={`resizer ${table.options.columnResizeDirection} ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                            style={{
                              transform:
                                columnResizeMode === 'onEnd' &&
                                  header.column.getIsResizing()
                                  ? `translateX(${(table.options.columnResizeDirection === 'rtl' ? -1 : 1) * (table.getState().columnSizingInfo.deltaOffset ?? 0)}px)`
                                  : ''
                            }}
                          />
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody>
                {table?.getRowModel().rows?.map((row, index) => {
                  const rowClassName =
                    index % 2 !== 0 ? 'bg-[#ecedf2]' : 'bg-[#ffffff]'
                  const unreadCount = getUnreadCount(
                    row.original?.PO_NUMBER,
                    row.original?.SKU
                  )
                  return (
                    <tr
                      key={'mypos-tr' + index}
                      className={`${rowClassName} text-center divide-x-[1px] divide-gray-300 border border-gray-300 
                    ${rowClassName} ${row.getIsSelected() ? 'selected' : ''}
                    `}
                    >
                      <td className='px-1.5 py-1.5 frozen-column'>
                        <div
                          className='w-full flex justify-start items-center gap-2 min-w-min'
                          style={{ width: '125px' }}
                        >
                          {user?.userGroupName !== 'OVERSEAS_ROLE' && (
                            <IndeterminateCheckbox
                              checked={row.getIsSelected()}
                              indeterminate={row.getIsSomeSelected()}
                              onChange={row.getToggleSelectedHandler()}
                              isdisabled={
                                row?.original?.HFT_REQUEST !== null ||
                                row?.original.CURRENT_SW === null ||
                                row?.original?.ORG_SW === null
                              }
                            />
                          )}
                          <span
                            className={`cursor-pointer ${unreadCount > 0 ? 'text-red-500' : 'text-gray-500'}`}
                            onClick={() =>
                              handleMessageIconClick(
                                row.original?.PO_NUMBER,
                                row.original?.SKU
                              )
                            }
                            title={
                              unreadCount > 0
                                ? `Unread messages: ${unreadCount}`
                                : 'No unread messages'
                            }
                          >
                            {unreadCount > 0 ? (
                              <MessageIconUnread />
                            ) : (
                              <MessageIconRead />
                            )}
                          </span>
                          <div className='flex gap-2 items-center justify-center'>
                            {row.original?.HFT_REQUEST !== null && (
                              <div className='flex relative group'>
                                <div
                                  className='relative group inline-block'
                                  title={`HFT: ${row.original?.HFT_REQUEST}`}
                                >
                                  <svg
                                    className='w-5 h-5 text-[#ffae42] cursor-pointer'
                                    title={'CR HISTORY'}
                                    aria-hidden='true'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 20 20'
                                    onClick={() =>
                                      handleCRDetailsClick(
                                        'vendor',
                                        row.original?.HFT_REQUEST
                                      )
                                    }
                                  >
                                    <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z' />
                                  </svg>
                                </div>
                              </div>
                            )}
                            {row?.original?.LATEST_CR_COUNT > 0 && (
                              <div
                                className='w-full flex items-center justify-center'
                              // title={`CR_HISTORY: ${row.original?.HFT_REQUEST}`}
                              >
                                <svg
                                  className='w-4 h-4 text-gray-900 cursor-pointer'
                                  aria-hidden='true'
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 20 20'
                                  onClick={() =>
                                    handleCRHistoryClick(
                                      row?.original?.PO_NUMBER,
                                      row?.original?.SKU
                                    )
                                  }
                                >
                                  <path
                                    stroke='currentColor'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='M18 5h1v12a2 2 0 0 1-2 2m0 0a2 2 0 0 1-2-2V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v15a2 2 0 0 0 2 2h14ZM10 4h2m-2 3h2m-8 3h8m-8 3h8m-8 3h8M4 4h3v3H4V4Z'
                                  />
                                </svg>
                              </div>
                            )}
                            {checkPastDue(
                              row?.original?.CURRENT_SW ?? row?.original?.REQ_SW
                            ) && (
                                <div
                                  className='w-full flex'
                                // title={`PAST_DUE: ${row.original?.HFT_REQUEST}`}
                                >
                                  <svg
                                    className='w-4 h-5 text-red-500'
                                    aria-hidden='true'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 20 20'
                                  >
                                    <path d='M0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm14-7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4Z' />
                                  </svg>
                                </div>
                              )}
                          </div>
                        </div>
                      </td>

                      {row?.getVisibleCells()?.map((cell) => (
                        <td
                          className={`px-1.5 py-1.5  ${cell.column.id === 'PO_SKU' || cell.column.id === 'REQUEST_TYPE' ? 'frozen-column' : ''} ${row.getIsSelected() ? 'bg-blue-200' : ''}`}
                          {...{
                            key: cell.id,
                            style: {
                              width: cell.column.getSize()
                            }
                          }}
                        >
                          {flexRender(
                            cell?.column?.columnDef?.cell,
                            cell?.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {isMessageBoxOpen && (
              <MessageBox
                po={selectedPOForMessageBox}
                sku={selectedSKUForMessageBox}
                name={user?.fullName}
                onClose={handleCloseMessageBox}
              />
            )}
          </div>
        </>
      ) : (
        <Loading />
      )}
      <dialog
        open={showSWCRModal}
        className='p-3 inset-0 fixed z-20 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          {submitting ? (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <Loading type='Submitting' />
            </div>
          ) : (
            <div className='w-full min-h-screen p-4 flex justify-center items-center'>
              <div className='sm:w-[400px] w-[280px] min-h-[300px] bg-white p-4 rounded-md flex flex-col gap-6 text-xs'>
                <div className='w-full flex justify-end items-center'>
                  <button
                    className='border-2 border-gray-300 p-2 rounded-md text-sm'
                    onClick={() => {
                      setShowSWCRModal(false)
                      setRequestSW('')
                      setRowSelection({})
                      setSelectedArray([])
                      setDate(new Date())
                      setinputsw('')
                      setValidSw('')
                    }}
                  >
                    ❌
                  </button>
                </div>
                <div>
                  <p className='font-medium text-base'>{ChangeShipweek}</p>
                </div>

                <form
                  onSubmit={handleCreateChangeRequest}
                  className='w-full flex flex-col gap-4 justify-between items-center'
                >
                  <div className='w-full flex flex-col  gap-4 justify-between items-center'>
                    <div className='flex items-center gap-5 text-xs font-medium text-left'>
                      <div>
                        <p>CUR SW</p>
                        <p className='mt-1 border p-1 w-[167px] bg-gray-300'>{firstRow}</p>
                      </div>
                      <div>
                        <p>Select New SW</p>
                        <input
                          type='number'
                          className='border p-1 mt-1 text-xs w-[167px] bg-yellow-300 placeholder-black'
                          placeholder='SW.YY(e.g.,00.00)'
                          value={inputsw}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9.]/g, '')
                            setinputsw(value)
                            // const checkSW = false
                            if (value.length === 5) {
                              const validation = validateShipWeekInput(
                                value,
                                firstRow
                              )

                              if (!validation.isValid) {
                                setValidSw(true)
                                setValidationMessage(validation.message)
                              } else {
                                setValidSw(false)
                                setValidationMessage('')
                                const sw = getPossibleShipWeek(value)
                                setRequestSW(sw)
                                setDate(getDateofMonday(sw))
                                datePickerRef.current.setFocus()
                              }
                            } else {
                              setValidSw(false) // Reset message if input is incomplete
                              setValidationMessage('')
                            }
                          }}
                        />
                      </div>
                    </div>
                    {/* above part */}
                    <div class='h-px my-2 bg-gray-500 border-1 w-full ' />
                    {/* below part */}
                    <div className='flex items-center  gap-5 text-xs font-medium text-left'>
                      <div>
                        <p>CURR SW Date:</p>
                        <p className='mt-1 border p-1 w-[167px] bg-gray-300'>
                          {changeDateFormat(firstRow)}
                        </p>
                      </div>
                      <div>
                        <p>Select New SW Date</p>
                        <DatePicker
                          showWeekNumbers
                          filterDate={(date) => date.getDay() === 1}
                          selected={date}
                          onChange={(date) => handleChangeDate(date)}
                          className='p-1 border mt-1 bg-yellow-300'
                          calendarStartDay={1}
                          showYearDropdown
                          minDate={new Date()}
                          excludeDates={[
                            new Date(),
                            new Date(changeDateFormat(firstRow))
                          ]}
                          highlightDates={highLightDateField(firstRow)}
                          ref={datePickerRef}
                        />
                      </div>
                    </div>
                    {validSw && (
                      <p className='text-red-500 test-xs'>Enter valid SW!</p>
                    )}
                  </div>
                  <div className='w-full flex justify-center items-center gap-6 text-white font-medium mt-5'>
                    <button
                      className={`px-4 h-[30px] bg-green-500 rounded-md ${validSw || requestSW?.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                      disabled={validSw || requestSW?.length === 0}
                      type='submit'
                    >
                      {Submit}
                    </button>
                    <button
                      className='px-4 h-[30px] bg-gray-500 text-white rounded-md'
                      onClick={() => {
                        setShowSWCRModal(false)
                        setRequestSW('')
                        setRowSelection({})
                        setSelectedArray([])
                        setDate(new Date())
                        setinputsw('')
                        setValidSw('')
                      }}
                    >
                      {Cancel}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </dialog>
      <dialog
        open={showDCCRModal}
        className='p-3 inset-0 fixed z-20 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          {submitting ? (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <Loading type='Submitting' />
            </div>
          ) : (
            <div className='w-full min-h-screen p-4 flex justify-center items-center'>
              <div className='sm:w-[400px] w-[280px] min-h-[300px] bg-white p-4 rounded-md flex flex-col gap-6 text-xs'>
                {/* Close Button */}
                <div className='w-full flex justify-end items-center'>
                  <button
                    className='border-2 border-gray-300 p-2 rounded-md text-sm'
                    onClick={() => {
                      setShowDCCRModal(false)
                      setRequestDC('')
                      setRowSelection({})
                      setSelectedArray([])
                    }}
                  >
                    ❌
                  </button>
                </div>

                {/* Main Content */}
                <div>
                  <p className='font-medium text-base'>{ChangeDC}</p>
                </div>

                {dcRow.every((item) => item.DC === dcRow[0]?.DC) ? (
                  <form
                    onSubmit={handleCreateChangeRequest}
                    className='w-full flex flex-col gap-4 justify-between items-center'
                  >
                    <div className='w-full flex justify-between items-center'>
                      <label htmlFor='dc_change_input' className='font-medium'>
                        {DC}
                      </label>
                      <select
                        id='dc_change_input'
                        name='dc_change_input'
                        className='p-1 border rounded-md border-gray-300 bg-yellow-300 outline-none'
                        onChange={(e) => setRequestDC(e?.target?.value)}
                        value={requestDC}
                      >
                        <option value='' disabled
                        className=''>
                          Select DC
                        </option>
                        {dcList?.map((data) => (
                          <option
                            value={data?.DC_NAME}
                            disabled={data?.DC_NAME === dcRow[0]?.DC}
                            className="bg-white text-black"
                          >
                            {data?.DC_NAME}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className='w-full flex justify-between items-center font-medium'>
                      <p className='mr-2'>{CurrentDC}</p>
                      <div className='font-medium p-1 px-2 border border-gray-300 rounded-md text-xs bg-gray-300'>
                        {dcRow[0]?.DC}
                      </div>
                    </div>

                    {requestDC === dcRow[0]?.DC && (
                      <p className='text-red-500'>There is no change in DC!</p>
                    )}

                    <div className='w-full flex justify-center items-center gap-6 text-white font-medium mt-5'>
                      <button
                        className={`px-4 h-[30px] bg-green-500 rounded-md ${requestDC === dcRow[0]?.DC ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                        type='submit'
                        disabled={requestDC === dcRow[0]?.DC}
                      >
                        {Submit}
                      </button>

                      <button
                        className='px-4 h-[30px] bg-gray-500 text-white rounded-md'
                        onClick={() => {
                          setShowDCCRModal(false)
                          setRequestDC('')
                          setRowSelection({})
                          setSelectedArray([])
                        }}
                      >
                        {Cancel}
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className='bg-gray-100 border border-gray-400 text-gray-700 p-4 rounded-md w-full text-center mt-5'>
                    <span className='font-semibold'>
                      Selected PO Lines have different DCs, unable to process
                      this.
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </dialog>
      <PopupModal
        isOpen={showChangeRequestModal}
        message={i18next.t('PopUpMessage.Doyouwanttocreateachangerequest')}
        onCancel={closeChangeRequestModal}
        cancelText={i18next.t('AcceptModal.Cancel')}
        onConfirm={handleConfirmOrder}
        confirmText={i18next.t('AcceptModal.Confirm')}
      />
      <PopupBanner
        isOpen={showChangeRequestBanner}
        message={i18next.t('PopUpMessage.Changerequestissubmittedsuccessfully')}
        onCancel={closePopUpModal}
        text={poRow}
        text1={crIdResponse}
      />
    </div>
  )
}

export default NewPoList
