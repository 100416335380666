import React, { useEffect, useMemo, useState } from 'react'
import UserProfile from '../../components/UserProfile'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { formatDateFit } from './BuyerRequestDetails'
import { DatefilterFn, Filter,  ValidateAPI, downloadCSV, handlePODetailsClick, MultiSelectColumnFilter, CalculateTableArray } from '../../utils/Utils'
import { useTranslation } from 'react-i18next'

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues
} from '@tanstack/react-table'

import i18next from 'i18next'

import { useLocation } from 'react-router-dom'
import PopupModal from '../../components/PopupModal'
import { IndeterminateCheckbox } from './NewPoList'
import PopupBanner from '../../components/PopupBanner'
import SelectList from '../../components/buyer/SelectList'
import { CommonAPICall } from '../../CommonAPiContext/context'

export const formatDate = (originalString) => {
  const year = originalString?.slice(0, 4)
  const month = originalString?.slice(4, 6)
  const day = originalString?.slice(6, 8)
  const date = new Date(`${year}-${month}-${day}`)
  const formattedDate = date.toISOString()?.slice(0, 10)

  return formattedDate
}
export const ViewDetail = ({ request }) => {
  const navigate = useNavigate()
  console.log('data received in viewDetail comp: ', request)
  return request?.request_type === 'URGENT' ? (
    <p
      className='hover:underline cursor-pointer text-blue-600'
      onClick={() =>
        navigate(
          `/buyer/urgent_orders/request_details/${request?.id_open_request}`,
          { state: request }
        )
      }
    >
      View Detail
    </p>
  ) : (
    <p
      className='hover:underline cursor-pointer text-blue-600'
      onClick={() =>
        navigate(
          `/buyer/hft_buyer_change_requests/request_details/${request?.id_open_request}`,
          { state: request }
        )
      }
    >
      View Detail
    </p>
  )
}

const BuyerRequest = () => {
  const [requestData, setRequestData] = useState([])
  const [loading, setLoading] = useState(false)
  const [showChangeRequestBanner, setShowChangeRequestBanner] = useState(false)
  const [poRow, setrowPO] = useState("")
  const [crIdResponse, setCrIdResponse] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const tableId = 'BuyerReqTable'; // Define tableId here
  const { HFTBuyerChangeRequests, Home, Loading1 } = t(
    'HFTBuyerChangeRequestsPage'
  )
  const user = JSON.parse(localStorage.getItem('userData'))
  // if naviagte from the dashboard than it store vendor name and it filter if already in localsotrgae take from 
  // there and if not than by selection from dropdown
  const [vendorNAme, setVendorName] = useState(
    '')
  // if naviagte from the dashboard than it store buyer name and it filter if already in localsotrgae take from 
  // there and if not than by selection from dropdown
  // if role is manger role than by default it will be empty and no api call made
  const [buyerNAme, setbuyerName] = useState(
    '')
  localStorage.setItem('saveBuyerName', buyerNAme)
  localStorage.setItem('saveVendorNAme', vendorNAme)
  const [filter, setFilterData] = useState({})
  const [check, setcheck] = useState(false)
  //this is for api call for buyer and vendor list
  const { state: { buyerListData, vendorListData }, SearchBuyer, searchVendorORSKU, addFilterData } = CommonAPICall()
  // Access translated strings outside useMemo
  const headers = {
    CR: HFTBuyerChangeRequests,
    PONUMBER: t('HFTBuyerChangeRequestsPage.PONUMBER'),
    SKU: t('HFTBuyerChangeRequestsPage.SKU'),
    SUPPLIERNAME: t('HFTBuyerChangeRequestsPage.SUPPLIERNAME'),
    STATUS: t('HFTBuyerChangeRequestsPage.STATUS'),
    REQUESTTYPE: t('HFTBuyerChangeRequestsPage.REQUESTTYPE'),
    SELECTEDSHIPWEEK: t('HFTBuyerChangeRequestsPage.SELECTEDSHIPWEEK'),
    REQUESTEDBY: t('HFTBuyerChangeRequestsPage.REQUESTEDBY'),
    CREATEDATE: t('HFTBuyerChangeRequestsPage.CREATEDATE'),
    UPDATEDATE: t('HFTBuyerChangeRequestsPage.UPDATEDATE'),
    CRSTATUS: t('HFTBuyerChangeRequestsPage.CRSTATUS'),
    VENDORRESPONSE: t('HFTBuyerChangeRequestsPage.VENDORRESPONSE'),
    // CHANGEDSW: t('HFTBuyerChangeRequestsPage.CHANGEDSW'),
    CHANGEDSW: t('POList.CHANGEDSW'),
    ORGSW: t('POList.ORGSW'),
    CURRSW: t('POList.CURRENTSW'),
    ONTIMETARGETDate: t('HFTBuyerChangeRequestsPage.ONTIMETARGETDate'),
    OPENQUANTITY:t('POList.OPENQUANTITY'),
    PREVIOUSW:t('POList.PREVIOUSW'),
    INVENTORYANALYST:t('POList.INVENTORYANALYST')
  }

  const columns = useMemo(
    () => [
      {
        header: headers.CR,
        accessorKey: 'id_open_request',
        cell: ({ row }) =>
          row?.original?.request_type === 'URGENT' ? (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() =>
                navigate(
                  `/buyer/urgent_orders/request_details/${row?.original.id_open_request}`,
                  { state: row?.original }
                )
              }
            >
              {row?.original?.id_open_request}
            </p>
          ) : (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() =>
                navigate(
                  `/buyer/hft_buyer_change_requests/request_details/${row?.original.id_open_request}`,
                  { state: row?.original }
                )
              }
            >
              {row?.original?.id_open_request}
            </p>
          ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.PONUMBER,
        accessorKey: 'po_number',
        cell: (info) => {
          return (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() => handlePODetailsClick('buyer', info?.getValue())}
            >
              {info?.getValue()}
            </p>
          )
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.SKU,
        accessorKey: 'sku',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        },
      },
      {
        header: headers.SUPPLIERNAME,
        accessorKey: 'vendor_name',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        },
        size: 100
      },
      {
        header: headers.SELECTEDSHIPWEEK,
        accessorKey: 'vendor_selected_sw',
        size: 80
      },
      {
        header: headers.CHANGEDSW,
        accessorKey: 'requested_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.ORGSW,
        accessorKey: 'original_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.PREVIOUSW,
        accessorKey: 'PREVS_CURRENTSHIPWEEK'
        // filterFn: (row, id, value) => {
        //   return value.includes(row.getValue(id))
        // }
      },
      {
        header: headers.CURRSW,
        accessorKey: 'current_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.ONTIMETARGETDate,
        accessorKey: 'on_time_target',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        },
        size: 80
      },
      {
        header: 'CARGO READY SW',
        accessorKey: 'cargoReadySW',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        },
        size: 55,
      },
      {
        header: 'BOOKING SW',
        accessorKey: 'bookingSW',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.VENDORRESPONSE,
        accessorKey: 'supplier_response',
        filterFn: (row, id, value) => {
          let value1 = value.toLowerCase()
          let value2 = row.getValue(id)?.toLowerCase()
          return value1.includes(value2)
        }
      },
      {
        header: headers.CRSTATUS,
        accessorKey: 'status',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.REQUESTTYPE,
        accessorKey: 'request_type',

        filterFn: (row, id, value) => {
          const cellValue = row.getValue(id);

          // Early return if cellValue is null or undefined
          // // Check if cellValue is null or undefined before calling toString()
          if (cellValue == null) {
            return false; // or any other logic you want to apply when the value is null
          }
          let words = value.split(/[\s,]+/).map(word => word.toLowerCase());  // Split by comma and spaces
          return words.includes(cellValue?.toLowerCase())
        }



      },
      {
        header: "CR AGE (DAY)",
        accessorKey: 'crAge',
        filterFn: (row, id, value) => {
          let cellValue = row.getValue(id)
          if (row.getValue(id) == '') {
            return false; // or any other logic you want to apply when the value is null
          }
          let getFromInputBox = value.split(/[\s,]+/).map(word => word);  // Split by comma and spaces
          return getFromInputBox.includes(cellValue)
        }
      },
      {
        header: headers.REQUESTEDBY,
        accessorKey: 'created_by',
        filterFn: (row, id, value) => {
          let value1 = value.toLowerCase()
          let value2 = row.getValue(id)?.toLowerCase()
          return value1.includes(value2)
        }
      },
      {
        header: headers.INVENTORYANALYST,
        accessorKey: 'inventory_analyst',
        filterFn: (row, id, value) => {
          let value1 = value.toLowerCase()
          let value2 = row.getValue(id)?.toLowerCase()
          return value1.includes(value2)
        }
      },
      {
        header: headers.OPENQUANTITY,
        accessorKey: 'OPEN_QTY',
        // filterFn: (row, id, value) => {
        //   return value.includes(row.getValue(id))
        // }
      },
      
      {
        header: headers.CREATEDATE,
        accessorKey: 'CREATION_DATE',
        cell: (info) =>
          info?.getValue() !== null ? formatDateFit(info?.getValue()) : '',
        filterFn: DatefilterFn
      },
      {
        header: headers.UPDATEDATE,
        accessorKey: 'updated_at',
        cell: (info) =>
          info?.getValue() !== null ? formatDateFit(info?.getValue()) : '',
        filterFn: DatefilterFn
      },
      
      // {
      //   header: "VIEW DETAILS",
      //   isPlaceholder: true,
      //   enableSorting: false,
      //   cell: (info) => <ViewDetail request={info?.row?.original} />,
      // },
    ],
    [headers, navigate]
  )

  const ChangeRequestTable = ({ tableId, tableData, columns, filterData, setFilterData }) => {
    const location = useLocation()
    const [data, setData] = useState(tableData ?? [])
    const paramname = useLocation()
    const setPathName = paramname?.pathname?.split('/')
    const [csvFileName, setFileName] = useState(setPathName[2])
    const [sorting, setSorting] = useState([])
    const [selectedArray, setSelectedArray] = useState([])
    const [showCancelOrderModal, setShowCancelOrderModal] = useState(false)
    const [globalFilter, setGlobalFilter] = useState('')
    const [columnFilters, setColumnFilters] = useState([])
    const { t } = useTranslation()
    const { Searchallcolumns, CreateChangeRequest, Page, Gotopage, Show } =
      t('BasicTable')
    const [values, setValues] = useState([])
    const [rowSelection, setRowSelection] = useState({})
    const { CancelOrder } = t('HFTBuyerPOChangeRequestDetailsPage')

    const [columnResizeMode, setColumnResizeMode] = React.useState('onChange')

    const [columnResizeDirection, setColumnResizeDirection] =
      React.useState('ltr')
    const [ClearState, setClearState] = useState(false)
    const [validSw, setValidSw] = useState(false)

    const user = JSON.parse(localStorage.getItem('userData'))

    const table = useReactTable({
      data,
      columns,
      columnResizeMode,
      columnResizeDirection,
      getCoreRowModel: getCoreRowModel(),
      state: {
        rowSelection,
        sorting,
        columnFilters,
        globalFilter
      },
      defaultColumn: {
        size: 50, //starting column size
        // minSize: 50, //enforced during column resizing
        // maxSize: 500, //enforced during column resizing
      },
      enableRowSelection: (row) => row?.original?.status === 'OPEN' && row?.original?.supplier_response === 'Awaiting Response',
      onRowSelectionChange: setRowSelection,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getSortedRowModel: getSortedRowModel(),
      onColumnFiltersChange: setColumnFilters,
      onSortingChange: setSorting,
      onGlobalFilterChange: setGlobalFilter,
      getFacetedRowModel: getFacetedRowModel(),
      getFacetedUniqueValues: getFacetedUniqueValues(),
      getFacetedMinMaxValues: getFacetedMinMaxValues()
    })

    const requiredOutput = () => {
      const selectedRowRaw = table?.getSelectedRowModel().flatRows
      console.log('selected raw data : ', selectedRowRaw)
      const requiredData = []
      selectedRowRaw?.map((row, index) => requiredData.push(row?.original))
      setSelectedArray(requiredData)
      return requiredData
    }

    useEffect(() => {
      // if (Object.keys(rowSelection)?.length > 0)
        requiredOutput()
    }, [rowSelection])

    // useEffect(() => {
    //   const localStorageKey = 'all_selected_values';
    //   localStorage.removeItem(localStorageKey);  // Clear localStorage
    //   setColumnFilters([]);
    //   // setFilterData([]);
    // }, []);

    // generate csv data

    const generateCSVData = (table) => {
      const data = table?.getFilteredRowModel()?.rows?.map((row) => {
        return row?.original
      })
      return data
    }

    const cleared = () => {
      // const localStorageKey = 'all_selected_values';
      // localStorage.removeItem(localStorageKey);
      setColumnFilters([]);
      setClearState(prevState => !prevState); // Toggle the state
      addFilterData({
        hftUserId: user?.hftOracleId,
        tableName: tableId,
        clFilter: []
      })
      setFilterData({})
    }

    useEffect(() => {
      if (
        location?.pathname?.includes('/buyer/hft_buyer_change_requests') &&
        location?.state
      ) {
        setColumnFilters([location?.state[0]]);
      }
    }, [location])


    const handleColumnResizeEnd = (header, tableId) => {
      const newWidth = header.getSize();
      const storedWidths = JSON.parse(localStorage.getItem('column-widths')) || {};
      if (!storedWidths[tableId]) {
        storedWidths[tableId] = {};
      }
      storedWidths[tableId][header.id] = newWidth;
      localStorage.setItem('column-widths', JSON.stringify(storedWidths));
    };

    const loadColumnWidths = (columns, tableId) => {
      const storedWidths = JSON.parse(localStorage.getItem('column-widths')) || {};
      if (storedWidths[tableId]) {
        columns.forEach(column => {
          if (storedWidths[tableId][column.id]) {
            column.columnDef.size = Number(storedWidths[tableId][column.id]);
          }
        });
      }
    };

    useEffect(() => {
      loadColumnWidths(table.getAllColumns(), tableId);
      // localStorage.removeItem('column-widths'); // Uncomment if you want to clear storage
    }, [table, tableId]);


    useEffect(() => {
      // Event listener to remove item from localStorage on window reload
      const handleBeforeUnload = (event) => {
        // Custom logic to determine if you want to clear localStorage on reload
        localStorage.removeItem('column-widths')
      }
      // Add event listener for beforeunload
      window.addEventListener('beforeunload', handleBeforeUnload)

      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload)
      }
    }, []) // Empty dependency array ensures this effect runs only once on mount

    const openCancelOrderModal = () => {
      setShowCancelOrderModal(true)
      
    }

    const closeCancelOrderModal = () => {
      setShowCancelOrderModal(false)
      setSelectedArray([])
      table?.resetRowSelection(); 
    }

    const handleCancelOrderRequests = (e) => {
      e?.preventDefault()
      const req_payload = []
      selectedArray?.map((item) => {
        const req_obj = {
          hftCrId: item?.id_open_request,
          createdBy: item?.created_by,
          vendorNumber: item?.vendor_number,
          po_number: item?.po_number,
          added_by_role_name:
            user?.roleName === 'SUPPLIER_ROLE' ? 'SUPPLIER' : 'BUYER',
          added_by_name:
            user?.roleName === 'SUPPLIER_ROLE'
              ? user?.supplierName
              : user?.fullName,
          comment: 'Change Request Cancelled',
          type: 'Action',
          request_type: item?.request_type,
          vendor_name: item?.vendor_name,
          sku: item?.sku,
          inventory_analayst: item?.inventory_analyst
        }
        req_payload?.push(req_obj)
      })
      console.log(req_payload);

      axios
        .post(
          `${process.env.REACT_APP_API_DOMAIN}:8088/api/cancel-change-request-list`,
          req_payload,  ValidateAPI()

        )
        .then((response) => {
          console.log('response after Change request:', response?.data)
          setrowPO(response?.data[0]?.po_number)
          setRowSelection({})
          if (response?.data) {
            const crIdResponse = response?.data?.map(
              (item) => item.id_open_request
            )
            console.log('crIdResponse:', crIdResponse)
            setCrIdResponse(crIdResponse)
          }
          setShowChangeRequestBanner(true)
          fetchAllRequests()
        })
        .catch((error) => {
          console.log('error while creating a CR: ', error)
          alert(
            i18next.t(
              'PopUpMessage.ErrorwhilecreatingaChangeRequestPleasetryagain'
            )
          )
        })
      closeCancelOrderModal()
    }

    const closePopUpBanner = () => {
      setShowChangeRequestBanner(false)
    }

    // useEffect(() => {
    //   if (selectedArray?.length > 0) {
    //     setValidSw(false); // Clear error message
    //   }
    // }, [selectedArray]);

    useEffect(() => {
      console.log("Selected Array:", selectedArray);
    }, [selectedArray]);
    

    return (
      <div className='w-full flex justify-start items-start text-xs flex-col gap-4 max-h-screen'>
        <div className='w-full justify-between flex items-center text-xs'>
          <div className='relative flex justify-start items-center gap-3'>
            <div>
              <input
                value={globalFilter ?? ''}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className='p-1 py-2 rounded-sm border border-gray-300 outline-none'
                placeholder={Searchallcolumns}
              />
            </div>
            
            <div className='flex gap-3 items-center justify-start'>
            <button
            className={`px-4 h-[30px] rounded-md outline-none min-w-max text-white 
              ${selectedArray?.length > 0 ? 'bg-red-500 cursor-pointer' : 'bg-gray-400 cursor-not-allowed'}`}
            disabled={selectedArray?.length === 0}
            onClick={openCancelOrderModal}
          >
            Cancel
          </button>


</div>
{validSw && (
  <p className='text-red-500 text-xs mt-2'>
    Please Select a PO.
  </p>
)}

            
            <PopupModal
              isOpen={showCancelOrderModal}
              message={i18next.t('PopUpMessage.Doyouwanttocancelorder')}
              onCancel={closeCancelOrderModal}
              cancelText={i18next.t('AcceptModal.Cancel')}
              onConfirm={handleCancelOrderRequests}
              confirmText={i18next.t('AcceptModal.Confirm')}
            />
            <PopupBanner
              isOpen={showChangeRequestBanner}
              message={'Change request cancelled successfully'}
              onCancel={closePopUpBanner}
              text={poRow}
              text1={crIdResponse}

            />
          </div>
          <div className='flex items-center gap-2'>
            {columnFilters.length > 0 && (
              <div>
                {/* {console.log(columnFilters)} */}
                <button
                  className='font-medium text-white outline-none bg-[#415da1] w-[90px] rounded-md h-[30px]'
                  onClick={() => {
                    cleared()
                  }}
                >
                  Clear Filters
                </button>
              </div>
            )}{' '}
            {data?.length !== 0 &&
              downloadCSV(csvFileName, generateCSVData(table))}
            <button
              className='border rounded p-1 font-medium text-sm'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className='border rounded p-1 font-medium text-sm'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className='border rounded p-1 font-medium text-sm'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className='border rounded p-1 font-medium text-sm'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
            <span className='min-w-max flex items-center gap-1'>
              <div>{Page}</div>
              <strong>
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
              </strong>
            </span>
            <span className='min-w-max flex items-center gap-1'>
              | {Gotopage}
              <input
                type='number'
                id='pagination_input'
                name='pagination_input'
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  table.setPageIndex(page)
                }}
                className='border p-1 rounded w-16'
              />
            </span>
            <select
              className='border p-1 rounded'
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {CalculateTableArray(table?.getFilteredRowModel()?.rows?.length).map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {Show} {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          className={`w-full overflow-x-auto ${window?.location?.pathname?.includes('/po_list/po_record') ? ' min-h-[5cm]' : 'min-h-[14cm]'} `}
        >
          <table className='text-xs border border-gray-300 relative w-full'>
            <thead className='sticky top-0 z-10'>
              {table?.getHeaderGroups()?.map((headerGroup, index) => (
                <tr
                  key={headerGroup?.id + index}
                  className='divide-x-[1px] bg-[#e0ecfc] divide-gray-300 border-[#ccd9e4] border'
                >
                  <th className='font-medium p-2 frozen-column' style={{ width: '50px' }}>
                    <IndeterminateCheckbox
                      checked={table?.getIsAllPageRowsSelected()}
                      indeterminate={table?.getIsSomePageRowsSelected()}
                      onChange={table?.getToggleAllPageRowsSelectedHandler()}
                    />
                  </th>
                  {headerGroup?.headers?.map((header) => (
                    <th
                      key={header?.id}
                      className='font-medium p-2 sticky top-0'
                      {...{
                        key: header.id,
                        colSpan: header.colSpan,
                        style: {
                          width: header.getSize()
                        }
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className='w-full flex flex-col items-center justify-center gap-2'
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler()
                          }}
                        >
                          <div className='flex justify-center h-10 text-center'>
                            {' '}
                            {/* Container with Flexbox */}
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            &nbsp;&nbsp;
                            <div>
                              {{
                                asc: (
                                  <svg
                                    className='w-3 h-4 text-gray-500'
                                    // className="w-4 h-5 text-gray-500" // Increased size with w-4 and h-5
                                    aria-hidden='true'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 10 14'
                                  // viewBox="0 0 14 20" // Adjusted viewBox for larger arrow
                                  >
                                    <path
                                      stroke='currentColor'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      strokeWidth='2'
                                      d='M5 13V1m0 0L1 5m4-4 4 4'
                                    // d="M1 5h12m0 0-4 4m4-4L9 1"
                                    />
                                  </svg>
                                ),
                                desc: (
                                  <svg
                                    className='w-3 h-4 text-gray-500'
                                    // className="w-4 h-5 text-gray-500" // Increased size with w-4 and h-5
                                    aria-hidden='true'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 10 14'
                                  // viewBox="0 0 14 20" // Adjusted viewBox for larger arrow
                                  >
                                    <path
                                      stroke='currentColor'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      strokeWidth='2'
                                      d='M5 1v12m0 0 4-4m-4 4L1 9'
                                    // d="M13 5H1m0 0 4 4m-4-4 3-3m0 6V1m0 0 3 3m-3-3 3 3"
                                    />
                                  </svg>
                                )
                              }[header.column.getIsSorted()] ?? (
                                  <svg
                                    className='w-3 h-4 text-gray-500'
                                    aria-hidden='true'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 16 20'
                                  >
                                    <path
                                      stroke='currentColor'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      strokeWidth='2'
                                      d='M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3'
                                    // d="M4 6h10m0 0-3-3m3 3-3 3m1 8H1m0 0 3-3m-3 3 3 3"
                                    />
                                  </svg>
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                      {header.column.getCanFilter() ? (
                        <div>
                          <Filter
                            tableId={tableId}
                            column={header.column}
                            table={table}
                            columnFilters={columnFilters}
                            values={values}
                            setValues={setValues}
                            header={header}
                            ClearState={ClearState}
                            filterValue={filterData}
                            hftid={user?.hftOracleId}
                          />
                        </div>
                      ) : null}
                      <div
                        onDoubleClick={() => header.column.resetSize()}
                        onMouseDown={(e) => {
                          header.getResizeHandler()(e);
                          document.addEventListener('mouseup', () => handleColumnResizeEnd(header, tableId), { once: true });
                        }}
                        onTouchStart={(e) => {
                          header.getResizeHandler()(e);
                          document.addEventListener('touchend', () => handleColumnResizeEnd(header, tableId), { once: true });
                        }}
                        className={`resizer ${table.options.columnResizeDirection} ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                        style={{
                          transform: columnResizeMode === 'onEnd' && header.column.getIsResizing()
                            ? `translateX(${(table.options.columnResizeDirection === 'rtl' ? -1 : 1) * (table.getState().columnSizingInfo.deltaOffset ?? 0)}px)`
                            : '',
                        }}
                      />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody>
              {table?.getRowModel().rows?.map((row, index) => {
                const rowClassName =
                  index % 2 !== 0 ? 'bg-[#ecedf2]' : 'bg-[#ffffff]'
                return (
                  <tr
                    key={row?.id + index}
                    className={`${rowClassName} text-center divide-x-[1px] divide-gray-300 border border-gray-300`}
                  >
                    <td className='px-2 py-2 frozen-column'>

                      <div className='w-full flex justify-center gap-2 min-w-min'>
                        <IndeterminateCheckbox
                          checked={row.getIsSelected()}
                          indeterminate={row.getIsSomeSelected()}
                          onChange={row.getToggleSelectedHandler()}
                          row={row.original}
                          isdisabled={row?.original?.status !== 'OPEN' || row?.original?.supplier_response !== 'Awaiting Response'}
                        />
                      </div>
                    </td>
                    {row?.getVisibleCells()?.map((cell) => (
                      <td
                        className='px-2 py-2'
                        {...{
                          key: cell.id,
                          style: {
                            width: cell.column.getSize()
                          }
                        }}
                      >
                        {flexRender(
                          cell?.column?.columnDef?.cell,
                          cell?.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }


  // api call for to get vendor and buyer list
  useEffect(() => {
    SearchBuyer()
    searchVendorORSKU()
  }, [])
  // set filter when come from dashboard 

  // const fetchAllRequests = () => {

  //   if(buyerNAme || vendorNAme){
  //     setLoading(true)  
  //     axios
  //     .get(`${process.env.REACT_APP_API_DOMAIN}:8088/api/search-create-requests?inventoryAnalyst=${buyerNAme}&vendorName=${vendorNAme}`,  ValidateAPI())
  //     .then((response) => {
  //       console.log('response from FCR: ', response?.data)
  //       setRequestData(response?.data)
  //       setLoading(false)
  //     })
  //     .catch((error) => {
  //       console.log(`error while FRC: `, error)
  //       setLoading(false)
  //       if (error.response.status === 401 || error.response.status === 403) {
  //         navigate('/login-error')
  //       }
  //     })
  //   }else
  //   return alert("Please enter at least one search criteria.")
  // }

  const fetchAllRequests = (applyFilter = true) => {
    // if role is manager and any input is empty api call will not have
    if (user?.roleType === "MANAGER" && (!buyerNAme && !vendorNAme)) { applyFilter = false }
    if (applyFilter && (!buyerNAme && !vendorNAme)) {
      return alert("Please select at least one search criteria.")
    }

    if (!applyFilter) {
      handleChangeBuyer(null)
      handleChangeVendor(null)
    }

    setLoading(true)
    let url = `${process.env.REACT_APP_API_DOMAIN}:8088/api/search-create-requests`

    if (applyFilter) {
      url += `?inventoryAnalyst=${buyerNAme}&vendorName=${vendorNAme}`
    }

    axios
      .get(url,  ValidateAPI())
      .then((response) => {
        setRequestData(response?.data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
        // else if (error?.response?.status === 500) {
        //   navigate('/server-error')
        // }
      })
  }
  function getFilterData(userid, tableName) {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/filter/preference/getFilterDetails?hftUserId=${userid}&tableName=${tableName}`,
         ValidateAPI()
      )
      .then((response) => {

        setFilterData(response?.data?.data)

        const data = response?.data?.data
        setbuyerName(data?.buyerName ?
          data?.buyerName?.value : user?.roleType === "MANAGER" ? '' : user?.fullName
        )
        // console.log(data);
        
        setVendorName(data?.vendorName ? data?.vendorName?.value : '')
        setcheck(true)
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        } else if (error.response.status === 404) {
          setcheck(true)
          const buyer = user?.roleType === "MANAGER" || user.userGroupName === "OVERSEAS_ROLE" ? '' : user?.fullName
          setbuyerName(buyer)
        }
      })
  }
  useEffect(() => {
    getFilterData(user?.hftOracleId, tableId)
  }, [])
  useEffect(() => {
    if (check) {
      fetchAllRequests()
    }
  }, [check])

  useEffect(() => {
    const handleClickOutside = () => {
      setShowChangeRequestBanner(false);
    };
    if (showChangeRequestBanner) {
      window.addEventListener('click', handleClickOutside);
    }
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [showChangeRequestBanner]);

  const handleChangeBuyer = (data) => {
    if (data === null)
      setbuyerName('')
    else
      setbuyerName(data?.value)
    addFilterData({
      hftUserId: user?.hftOracleId,
      tableName: tableId,
      buyerName: {
        id: data?.label,
        value: data?.value
      }
    })
  }
  const handleChangeVendor = (data) => {
    if (data === null)
      setVendorName('')
    else
      setVendorName(data?.label)
    addFilterData({
      hftUserId: user?.hftOracleId,
      tableName: tableId,
      vendorName: {
        id: data?.value,
        value: data?.label
      }
    })
  }
  return (
    <div className='w-full flex flex-col gap-6 justify-start items-start p-4'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl'>{HFTBuyerChangeRequests}</p>
        </div>
      </div>
      <div className='w-full text-xs flex justify-between items-center gap-1 font-medium text-gray-600'>
        <div className='flex'>
          <p className='flex gap-1 items-center justify-center'>
            <span
              className=' hover:underline cursor-pointer'
              onClick={() => navigate('/buyer')}
            >
              {Home}
            </span>
            <span>{'>'}</span>
          </p>
          <p className='flex gap-1 items-center justify-center'>
            <span>{HFTBuyerChangeRequests}</span>
            {/* <span>{">"}</span> */}
          </p>
        </div>
        <div className='flex gap-4 rounded-md bg-blue-100 p-2 items-center justify-center text-xs font-medium'>

          <div className='flex items-center justify-center gap-3 w-full'>
            <div className='flex flex-col'>
              {/* <h3 className="mb-1">INVENTORY ANALYST</h3> */}

              <SelectList
                List={buyerListData}
                handleChangeData={handleChangeBuyer}
                defaultName={buyerNAme}
                placeholder="INVENTORY ANALYST"
                isClearable={true}
              />
            </div>
            <div className='flex flex-col'>
              {/* <h3 className="mb-1">VENDOR</h3> */}
              <SelectList
                List={vendorListData}
                handleChangeData={handleChangeVendor}
                isClearable={true}
                defaultName={vendorNAme}
                placeholder="VENDOR"
              />
            </div>
            <button
              className='bg-[#415da1] px-4 h-[30px] rounded-md outline-none text-white min-w-max '
              onClick={() => fetchAllRequests(true)}
            // style={{ paddingTop: '22px' }}  // Adjust this value as needed
            >
              <p>Filter</p>
            </button>
            <button
              className='bg-[#415da1] px-4 h-[30px] rounded-md outline-none text-white min-w-max'
              onClick={() => fetchAllRequests(false)}
            // style={{ paddingTop: '22px' }}  // Adjust this value as needed
            >
              <p>ALL</p>
            </button>
          </div>

        </div>

      </div>
      {loading ? (
        <div className='w-full min-h-[300px] flex justify-center items-center'>
          <p>{Loading1}</p>
        </div>
      ) : (
        <div className='w-full overflow-x-auto'>
          <ChangeRequestTable tableId={tableId} tableData={requestData} columns={columns}
            setFilterData={setFilterData}
            filterData={filter} />
        </div>
      )}{' '}
    </div>
  )
}

export default BuyerRequest
