import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import Cookies from 'js-cookie'
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const navigate = useNavigate()
    const refreshToken = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}:8088/api/auth/refresh-token`,
                {
                    "refreshToken": Cookies.get('idcs_refresh_token'),
                    "role": Cookies.get('role')
                }
            );
            if (response?.status === 200) {
                console.log(response?.data?.statusCode);
                if (response?.data?.statusCode === 200) {
                    Cookies.set('idcs_user_assertion', response?.data?.data?.access_token)
                    Cookies.set('idcs_refresh_token', response?.data?.data?.refresh_token)
                    checkTokenValidity()
                }
            }
        } catch (error) {
            console.error("Failed to refresh token:", error, error.response.status);
            // Handle logout or redirect here
        }
    };
    const scheduleTokenRefresh = (expTime) => {
        const currentTime = new Date().getTime();
        const expirationTimeInMs = new Date(expTime).getTime();
        const timeUntilExpiration = expirationTimeInMs - currentTime;

        if (timeUntilExpiration > 30*1000) {
            setTimeout(() => {
                refreshToken();
            }, timeUntilExpiration - 2 * 60 * 1000); // Refresh 2 minutes before expiration
        } else {
            navigate('/login-error'); // Redirect if the token is already near expiry
        }
    };
    const checkTokenValidity = async () => {
        console.log("refreshed");

        try { 
            const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}:8088/api/auth/check-token`,
                {
                    "token": Cookies.get('idcs_user_assertion'),
                    "role": Cookies.get('role')
                }
            );
            console.log(response);
            if (response?.status === 200) {
                scheduleTokenRefresh(response?.data?.expirationTime)
                localStorage.setItem('sessionExpTime',response?.data?.expirationTime)
            }
        } catch (error) {

        }
    }
    useEffect(() => {
        // After load the page this funtion calls and cheks token validity if we have more than 1 min of expire time 
        // it will set up a call refresh token funtion that call to generate new token
        checkTokenValidity()
    }, [navigate]);

    return (
        <AuthContext.Provider value={{ token, setToken }}>
            {children}
        </AuthContext.Provider>
    );
}

