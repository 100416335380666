import axios from 'axios'
import { NotificationReducer, inititalState } from './Reducer'
import { useContext, createContext, useReducer, useEffect } from 'react'
import {  ValidateAPI, getallDcList } from '../utils/Utils'

const Notify = createContext()

const Context = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('userData'))
  const [state, dispatch] = useReducer(NotificationReducer, inititalState)
  function getNotificationCountForBuyer(email) {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/notification/notificationListByUserCount?hFtUserId=${email}`,
          ValidateAPI()
      )
      .then((responce) => {
        dispatch({
          type: 'ADDCOUNT',
          payload: responce?.data
        })
      })
      .catch((err) => {
        // return alert("some thing issue ", err)
      })
  }
  function getNotificationListForBuyer(email) {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/notification/notificationListByUser?hFtUserId=${email}`,  ValidateAPI()
      )
      .then((responce) => {
        dispatch({
          type: 'ADDLIST',
          payload: responce?.data
        })
      })
      .catch((err) => {
        // alert("some thing issue ", err)
      })
  }
  //Notification Count
  function getNotificationcountForSupplier(supplierId) {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/notification/notificationListBySupplierCount?supplierId=${supplierId}`,  ValidateAPI()
      )
      .then((responce) => {
        dispatch({
          type: 'ADDCOUNT',
          payload: responce?.data
        })
      })
      .catch((err) => {
        // return alert("some thing issue ", err);
      })
  }
  function getNotificationListForBuyer(email) {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/notification/notificationListByUser?hFtUserId=${email}`,  ValidateAPI()
      )
      .then((responce) => {
        dispatch({
          type: 'ADDLIST',
          payload: responce?.data
        })
      })
      .catch((err) => {
        // alert("some thing issue ", err);
      })
  }
  //Notification Count
  function getNotificationcountForSupplier(supplierId) {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/notification/notificationListBySupplierCount?supplierId=${supplierId}`,  ValidateAPI()
      )
      .then((responce) => {
        dispatch({
          type: 'ADDCOUNT',
          payload: responce?.data
        })
      })
      .catch((err) => {
        // alert("some thing issue ", err);
      })
  }
  // Notification List For Supplier
  function getNotificationListForSupplier(supplierId) {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/notification/notificationListBySupplier?supplierId=${supplierId}`,  ValidateAPI()
      )
      .then((responce) => {
        dispatch({
          type: 'ADDLIST',
          payload: responce?.data
        })
      })
      .catch((err) => {
        // alert("some thing issue ", err);
      })
  }
  // Chnage the count stat us of notification
  function changeNotificationStaus(HFTID) {
    console.log(HFTID)
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/notification/notificationStatus?notificationId=${HFTID}`,  ValidateAPI()
      )
      .then((responce) => {
        if (user?.roleName === 'BUYER_ROLE') {
          getNotificationCountForBuyer(user?.email)
          getNotificationListForBuyer(user?.email)
        } else {
          console.log(responce?.status === 200)
          getNotificationcountForSupplier(user?.supplierId)
          getNotificationListForSupplier(user?.supplierId)
        }
      })
      .catch((err) => {
        // alert("some thing issue ", err);
      })
  }
  function updateDataCount(supplierId) {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/get-counts-email-table?vendorNumber=${supplierId}`,  ValidateAPI()
      )
      .then((response) => {
        dispatch({
          type: 'UPDATECOUNT',
          payload: response?.data
        })
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
        }
      })
  }
  useEffect(() => {
    if (!sessionStorage.getItem('dcList')) {
      getallDcList()
    }
    
  }, [])
  return (
    <Notify.Provider
      value={{
        state,
        changeNotificationStaus,
        getNotificationCountForBuyer,
        getNotificationListForBuyer,
        getNotificationcountForSupplier,
        getNotificationListForSupplier,
        updateDataCount
      }}
    >
      {children}
    </Notify.Provider>
  )
}

export default Context

export const CartState = () => {
  return useContext(Notify)
}
