import axios from 'axios'
import { addDays, isAfter, startOfWeek, parse } from 'date-fns'
import React, { useEffect, useMemo, useState, useRef } from 'react'
import { CSVLink } from 'react-csv'
import ConfirmActionComp from '../components/vendor/ConfirmActionComp'
import ReconfirmActionComp from '../components/vendor/ReconfirmActionComp'
import ExpediteActionComp from '../components/vendor/ExpediteActionComp'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { useReactTable } from '@tanstack/react-table'
import { weeksInBetween } from '../screens/vendor/ConfirmPo'
import { CSSProperties, FunctionComponent } from 'react'
import Select, { ClearIndicatorProps, components } from 'react-select'
import { CSSObject } from '@emotion/serialize'
import DatePicker from 'react-datepicker' // Import DatePicker from react-datepicker
import 'react-datepicker/dist/react-datepicker.css' // Import DatePicker styles
import { format } from 'date-fns'
import { isValid } from 'date-fns'
import Cookies from 'js-cookie'
import { CartState } from '../Context/Context'
import { useNavigate } from 'react-router'
import { FixedSizeList as List } from 'react-window';
import { formatDateFit } from '../screens/buyer/BuyerRequestDetails'
import { CommonAPICall } from '../CommonAPiContext/context'

export const handleDownload = async (poNumber) => {

  try {
    // Define the download link with query parameters
    const downloadLink = `${process.env.REACT_APP_API_DOMAIN}:8088/api/download-pdf?poNumber=${poNumber}`;

    // Make the API request using Axios
    const response = await axios.get(downloadLink, {
      responseType: 'blob', // Important to specify the response type as blob
      headers: {
        'Content-Type': 'application/pdf',
        'Authorization': `Bearer ${Cookies.get('idcs_user_assertion')}`, // Add the bearer token to the headers
        'Role':  Cookies.get('role')
      },
    });

    // Create a URL for the blob
    const url = window.URL.createObjectURL(new Blob([response.data]));

    // Create a link element
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'purchase_history.pdf'; // Set the file name

    // Append the link to the body
    document.body.appendChild(anchor);

    // Programmatically click the link to trigger the download
    anchor.click();

    // Remove the link from the document
    document.body.removeChild(anchor);

    // Release the URL object
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error fetching or downloading the PDF:', error);
  }
}

export const downloadCSV = (fileName, data, tagName = "Download CSV") => {
  const downloadCSVfile = () => {
    const csvData = convertToCSV(data)
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'data.csv'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  const convertToCSV = (data) => {
    console.log(data);
    const header = Object.keys(data[0]).join(',')
    // console.log(header)
    const body = data.map((row) => Object.values(row).join(',')).join('\n')
    return header + '\n' + body
  }
  return (
    // <CSVLink
    //   data={data}
    //   filename={fileName}
    //   // headers={headers}
    //   className="bg-[#415da1] border rounded p-2 font-medium text-xs text-white  ">

    //   {i18next.t('UtilPage.DownloadCSV')}
    // </CSVLink>
    <button
      class="bg-[#415da1] p-2 rounded-md outline-none text-white  min-w-max"

      onClick={downloadCSVfile}

    >
      {tagName}
    </button>
  )
}
export const formatDate = (reqDate) => {
  const year = reqDate?.slice(0, 4)
  const month = reqDate?.slice(4, 6)
  const day = reqDate?.slice(6, 8)
  const date = new Date(`${year}-${month}-${day}`)
  // console.log(date.toLocaleDateString());
  const formattedDate = date.toISOString()?.slice(0, 10)

  return formattedDate
}
export const dateformatDDMMYEAR = (reqDate) => {
  const year = reqDate?.slice(0, 4)
  const month = reqDate?.slice(4, 6)
  const day = reqDate?.slice(6, 8)
  const formattedDate = month + '/' + day + '/' + year

  return formattedDate
}

export const reasonCodes = [
  i18next.t('ReasonCodeList.CapacityorProductionSlowDown'),
  i18next.t('ReasonCodeList.EquipmentMoldMaintenance'),
  i18next.t('ReasonCodeList.LCLShipmentConsolidation'),
  i18next.t('ReasonCodeList.MaterialorLaborShortage'),
  i18next.t('ReasonCodeList.PublicHoliday'),
  i18next.t('ReasonCodeList.QualityIssueProductChange'),
  i18next.t('ReasonCodeList.ForceMajeureWeatherEvents'),
  i18next.t('ReasonCodeList.FreightForwarderConsolidation'),
  i18next.t('ReasonCodeList.SpaceConstraints'),
  i18next.t('ReasonCodeList.VesselDelay'),
  i18next.t('ReasonCodeList.Other')
]
export const reasonCodesEN = [
  'Capacity or Production Slow Down',
  'Equipment/Mold Maintenance',
  'LCL Shipment Consolidation',
  'Material or Labor Shortage',
  'Public Holiday',
  'Quality Issue / Product Change',
  'Force Majeure / Weather Events',
  'Freight Forwarder Consolidation',
  'Space Constraints',
  'Vessel Delay',
  'Other'
]
export const reasonCodesMN = [
  '产能或生产放缓',
  '设备/模具维护',
  '拼箱货物整合',
  '材料或劳动力短缺',
  '公共假期',
  '质量问题/产品变更',
  '不可抗力/天气事件',
  '货运代理整合',
  '空间限制',
  '船舶延迟',
  '其他'
]

export const handlePODetailsClick = (user_role, value) => {
  // const navigate  = useNavigate()
  const detailsUrl = `/${user_role}/po_list/po_record/${value}`
  const popupWindow = window.open(
    detailsUrl,
    '_blank',
    'width=1100,height=800,resizable=yes,scrollbars=yes'
  )
  // Focus the new popup window (optional)
  // window.location.href=detailsUrl
  if (popupWindow) {
    popupWindow.focus()
  }
}

export const handleCRHistoryClick = (poNumber, sku) => {
  const detailsUrl = `/cr_history/${poNumber}/${sku}`
  const popupWindow = window.open(
    detailsUrl,
    '_blank',
    'width=950,height=800,resizable=yes,scrollbars=yes'
  )

  if (popupWindow) {
    popupWindow.focus()
  }
}

export const handleCRDetailsClick = (user_role, value) => {
  // console.log("cr id from hcrc: ", value);
  const detailsUrl = `/${user_role}/po_change_requests/request_details/${value}`
  const popupWindow = window.open(
    detailsUrl,
    '_blank',
    'width=1100,height=800,resizable=yes,scrollbars=yes'
  )

  // Focus the new popup window (optional)
  if (popupWindow) {
    popupWindow.focus()
  }
}

export function checkPd(weekString) {
  if (weekString === null || weekString === undefined) return true
  const [week, year] = weekString.split('.').map(Number)
  const isoWeekStart = startOfWeek(new Date(year + 2000, 0, 1), {
    weekStartsOn: 1
  })
  const calculatedDate = addDays(isoWeekStart, week * 7)
  const today = new Date()
  return isAfter(today, calculatedDate)
}

export function Filter({
  filterValue = {}, column, table, headers, headerGroup, columnFilters,
  values, setValues, HeaderText, width, header, tableId, ClearState, hftid
}) {

  const [selectedValue, setSelectedValue] = useState([]); // State to manage selected values
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchBarValue, setSearchBarValue] = useState(''); // State to manage selected values
  const [searchResult, setSearchResult] = useState(null);
  const [batchSize, setBatchSize] = useState(200); // Default batch size
  const { addFilterData } = CommonAPICall()
  const handleDateChange = (dates) => {
    const [start, end] = dates;

    if (start != null && end != null) {
      try {
        const startFormatted = format(start, 'ww.yy');
        const endFormatted = format(end, 'ww.yy');
        const dateRangeString = `${startFormatted}-${endFormatted}`;

        column.setFilterValue(dateRangeString);
        setStartDate(start);
        setEndDate(end);

        const localStorageKey = 'all_selected_values';
        let allSelectedValues = JSON.parse(localStorage.getItem(localStorageKey)) || [];
        allSelectedValues = allSelectedValues.filter(item => item.columnId !== column.id);
        allSelectedValues.push({
          columnId: column.id,
          values: dateRangeString
        });
        try {
          localStorage.setItem(localStorageKey, JSON.stringify(allSelectedValues));
        } catch (error) {
          console.error("Error storing data in localStorage:", error);
        }
      } catch (error) {
        console.error('Error formatting date range:', error);
      }
    } else if (start != null && end == null) {
      setStartDate(start);
      setEndDate(null);
    } else if (start == null && end != null) {
      setEndDate(end);
      setStartDate(null);
    } else {
      setStartDate(null);
      setEndDate(null);
      column.setFilterValue('');
    }
  };
  const handlechangedateFormat = (dates) => {
    const [start, end] = dates;
    console.log(start, end);
    if (start != null && end != null) {
      try {
        // const startFormatted = format(start, 'ww.yy');
        // const endFormatted = format(end, 'ww.yy');
        // const dateRangeString = `${startFormatted}-${endFormatted}`;
        // console.log('dateRangeString', dateRangeString);

        setStartDate(start);
        setEndDate(end);
        column.setFilterValue({ start, end });

        const localStorageKey = 'all_selected_values';
        let allSelectedValues = JSON.parse(localStorage.getItem(localStorageKey)) || [];
        allSelectedValues = allSelectedValues.filter(item => item.columnId !== column.id);
        allSelectedValues.push({
          columnId: column.id,
          values: { start, end }
        });
        try {
          localStorage.setItem(localStorageKey, JSON.stringify(allSelectedValues));
        } catch (error) {
          console.error("Error storing data in localStorage:", error);
        }
      } catch (error) {
        console.error('Error formatting date range:', error);
      }
    } else if (start != null && end == null) {
      setStartDate(start);
      setEndDate(null);
    } else if (start == null && end != null) {
      setEndDate(end);
      setStartDate(null);
    } else {
      setStartDate(null);
      setEndDate(null);
      column.setFilterValue('');
    }
  };

  useEffect(() => {
    if (startDate && endDate && columnFilters.length === 0) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [columnFilters]);



  useEffect(() => {
    // console.log("check",filterValue);

    if (hftid && tableId) {

      if (filterValue?.clFilter?.some(item => item.column === column.id)) {
        // console.log(filterValue?.clFilter?.find((data) => data?.column === column.id)?.values);
        const value = filterValue?.clFilter?.find((data) => data?.column === column.id)?.values
        console.log(value?.length,);
        if (value.some(item => typeof item === 'number')) {
          column.setFilterValue(value);
        }
        else if (value?.length && value[0] !== '') {
          const filterarray = value?.map((data) => {
            return {
              label: data,
              value: data
            }
          })


          setSelectedValue(filterarray);
          const selectedValuesAsString = filterarray.map(option => option.value).join(', ')
          column.setFilterValue(selectedValuesAsString);
          setSearchBarValue(selectedValuesAsString);
        }
        

      }
      else {
        setSelectedValue([]);
        column.setFilterValue('');
      }

    }
    //code commeted added feature for save filter in api 
    // const localStorageKey = 'all_selected_values';
    // const storedValues = JSON.parse(localStorage.getItem(localStorageKey)) || {};

    // const storedSelectedValues = storedValues[`${tableId}_${column.id}`];
    // if (storedSelectedValues) {
    //   if (Array.isArray(storedSelectedValues)) {
    //     setSelectedValue(storedSelectedValues);
    //     const selectedValuesAsString = storedSelectedValues.map(option => option.value).join(', ');
    //     column.setFilterValue(selectedValuesAsString);
    //     setSearchBarValue(selectedValuesAsString);
    //   } else {
    //     console.warn("storedSelectedValues is not an array");
    //   }
    // }
    // else {
    //   setSelectedValue([]);
    //   column.setFilterValue('');
    // }

  }, [tableId, column.id, ClearState, filterValue]);

  const handleChange = (selectedOptions) => {

    setSelectedValue(selectedOptions);



    const selectedValuesAsString = selectedOptions.map(option => option.value)?.join(', ');
    // console.log(selectedValuesAsString);

    column.setFilterValue(selectedValuesAsString);
    setSearchBarValue(selectedValuesAsString);
    // const localStorageKey = 'all_selected_values';
    // let allSelectedValues = JSON.parse(localStorage.getItem(localStorageKey)) || {};

    // allSelectedValues[`${tableId}_${column.id}`] = selectedOptions;

    // try {
    //   localStorage.setItem(localStorageKey, JSON.stringify(allSelectedValues));
    // } catch (error) {
    //   console.error("Error storing data in localStorage:", error);
    // }
    const arrayVAlue = selectedValuesAsString?.split(',')
    if (hftid && tableId) {
      addFilterData(
        {
          hftUserId: hftid,
          tableName: tableId,
          clFilter: [
            {
              column: column.id,
              values: arrayVAlue?.length && arrayVAlue[0] !== '' ? arrayVAlue : []
            }
          ]

        }

      )
    }

  };

  const searchInBatches = (query) => {
    let found = false;
    let batchIndex = 0;
    const data = column.getFacetedUniqueValues().keys();

    while (!found && batchIndex * batchSize < data.length) {
      const currentData = Array.from(data).slice(batchIndex * batchSize, (batchIndex + 1) * batchSize);
      const result = currentData.find(item => item.includes(query));

      if (result) {
        setSearchResult(result);
        found = true;
      } else {
        batchIndex++;
      }
    }

    if (!found) {
      setSearchResult(null);
    }
  };

  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();
  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );

  const { t } = useTranslation();
  const headerWidth = header.getSize();
  const handleSearch = (value) => {
    console.log(value);
    return sortedUniqueValues.slice(0, 100).map((value) => ({
      value,
      label: value
    }))
  }
  const saveFilter=(id,hftid,tableId)=>{
    console.log(column.getFilterValue());
    
    setTimeout(() => {
      console.log(column.getFilterValue());
  
      addFilterData({
        hftUserId: hftid,
        tableName: tableId,
        clFilter: [
          {
            column: id,
            values: column?.getFilterValue(),
          },
        ],
      });
    }, 0); 
  }
  return (

    <>
      <div className="flex justify-center items-center relative">
        {typeof firstValue === 'number' ? (
          <div>
            <div className='flex gap-1' style={{ height: '38px' }}>
              <DebouncedInput
                type='number'
                value={columnFilterValue?.[0] ?? ''}
                placeholder='Min'
                onChange={(value) => {
                  if(!value)
                    return
                  
                  const minValue = value === '' ? '' : Number(value);
                  const maxValue = columnFilterValue?.[1] ?? '';

                  // If min is greater than max, clear the max value
                  if (minValue !== '' && minValue > (maxValue === '' ? Infinity : maxValue)) {
                    column.setFilterValue([minValue, '']);
                  } else {
                    column.setFilterValue([minValue, maxValue]);
                  }
                  // saveFilter(column.id,hftid,tableId)
                }}
                className='border shadow rounded'
                hftid={hftid}
                tableId={tableId}
                column={column.id}
              />
              <DebouncedInput
                type='number'
                value={columnFilterValue?.[1] ?? ''}
                placeholder='Max'
                onChange={(value) => {
                  if(!value)
                    return
                  const maxValue = value === '' ? '' : Number(value);
                  const minValue = columnFilterValue?.[0] ?? '';

                  // If max is less than min, clear the min value
                  if (maxValue !== '' && maxValue < (minValue === '' ? -Infinity : minValue)) {
                    column.setFilterValue(['', maxValue]);
                  } else {
                    column.setFilterValue([minValue, maxValue]);
                  }
                  // saveFilter(column.id,hftid,tableId)
                }}
                className='border shadow rounded'
                hftid={hftid}
                tableId={tableId}
                column={column.id}
              />
            </div>



            <div className='' />
          </div>
        ) : column.id === 'REQ_SW_DATE' ||
          column.id === 'ORG_SW_DATE' ||
          column.id === 'CURRENT_SW_DATE' ||
          column.id === 'ONTARGET_SHIP_WK_DATE' ? (
          <div style={{ width: headerWidth > 70 ? headerWidth : 70 }} className='mt-1'>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
              isClearable={true}
              className={`h-9 border border-gray-400 rounded-none w-full`}
              popperClassName='react-datepicker__week-number'
              wrapperClassName="w-full"
              placeholderText='Select Date Range'
              showWeekNumbers
              showIcon
              scrollableYearDropdown
              calendarStartDay={1}
              filterDate={(date) => date.getDay() === 1}
              showYearDropdown
              dateFormat='dd/MM/yyyy'
            />
            <div className='h-1' />
          </div>
        ) : column.id === 'created_at' ||
          column.id === 'updated_at' ||
          column.id === 'DATE_LAST_UPDATED' ||
          column.id === 'date' || column.id === 'createDate' ||
          column.id ===  'CREATION_DATE'|| column.id ===  'UPDATEDAT' 
          ? (
          <div style={{ width: headerWidth > 70 ? headerWidth : 70 }}>
            <DatePicker
              key={headerWidth}
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={handlechangedateFormat}
              isClearable={true}
              className={`h-9 border border-gray-400 rounded w-full items-center flex`}
              wrapperClassName="w-full items-center flex"
              popperClassName='react-datepicker__week-number custom-calendar  '
              calendarClassName="custom-calendar"
              placeholderText='Select Date Range'
              showWeekNumbers
              showIcon
              popperPlacement="bottom-start"
              scrollableYearDropdown
              calendarStartDay={1}
              // filterDate={(date) => date.getDay() === 1}
              showYearDropdown
              // dateFormat='dd/MM/yyyy'
            />
            <div className='h-1' />
          </div>
        ) : (
          <>
            <Select
              closeMenuOnSelect={false}
              isMulti
              placeholder={`Search`}
              options={sortedUniqueValues.map((value) => ({
                value,
                label: value
              }))}
              value={columnFilters.length === 0 ? '' : selectedValue}
              onChange={handleChange}
              className='border border-gray-300 border-solid rounded h-9 focus:border-blue-100 focus:ring-blue-300 css-b62m3t-container'
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  boxShadow: state.isFocused ? 'none' : 'none',
                }),
                input: (provided) => ({
                  ...provided,
                  maxHeight: '200px',
                  overflowY: 'auto',
                  width: headerWidth,
                  minWidth: headerWidth,
                }),
                menu: (provided) => ({
                  ...provided,
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  width: '100%',
                }),
                menuList: (provided) => ({
                  ...provided,
                  maxHeight: '200px',
                  overflowY: 'auto',
                }),
              }}
            />
            <div className='h-1' />
          </>
        )}
      </div>
    </>
  );
}



export function TestFilterComp() {
  return (
    <div>
      <input type='text' placeholder='Test Filter' />
    </div>
  )
}

export function CustomFilter({ selectedValues, setSelectedValues, options }) {
  return (
    <select
      multiple
      value={selectedValues}
      onChange={(e) => {
        const selectedOptions = Array.from(
          e.target.selectedOptions,
          (option) => option.value
        )
        setSelectedValues(selectedOptions)
      }}
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

export default CustomFilter

export const MultiSelectColumnFilter = ({
  column: { filterValue = [], setFilter, preFilteredRows, id }
}) => {
  const options = useMemo(() => {
    const uniqueOptions = new Set()
    preFilteredRows.forEach((row) => {
      uniqueOptions.add(row.values[id])
    })
    return [...uniqueOptions]
  }, [id, preFilteredRows])

  // Handle change in selected options
  const handleChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    )
    setFilter(selectedOptions)
  }

  return (
    <select multiple value={filterValue} onChange={handleChange}>
      <option value=''>All</option>
      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

export function DebouncedInput({
  value: initialValue,
  type,
  onChange,
  debounce = 1500,
  width,
  placeholder = '',hftid,tableId,column,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue)
  const { addFilterData } = CommonAPICall()
  const resetValue = () => {
    setValue('')
    addFilterData({
      hftUserId: hftid,
      tableName: tableId,
      clFilter: [
        {
          column: column,
          values: [],
        },
      ],
    });
  
  }
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  // console.log(value);

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  return (
    <>
      <div className='flex'>
        <input
          type={type}
          {...props}
          value={value}
          // onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
          onChange={handleChange}
          className={`p-1 px-2 border-2 border-solid rounded font-medium outline-none border-width: ${type === 'number' ? 'w-[55px]' : 'min-w-[110px]'
            } focus:border-blue-400`}
          style={{
            width: width,
            borderColor: 'hsl(0, 0%, 80%)'
          }}
        />

        {value && (
          <button onClick={() => resetValue()}>
            <svg
              className='w-6 h-6 text-gray-800 dark:text-white'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              viewBox='0 0 24 24'
            >
              <path
                fillRule='evenodd'
                d='M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z'
                clipRule='evenodd'
              />
            </svg>
          </button>
        )}
      </div>
    </>
  )
}

export function DebouncedSelect({
  value: initialValue,
  options,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue)

  const resetValue = () => {
    setValue('')
  }

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  const handleChange = (selectedOptions) => {
    setValue(selectedOptions)
  }

  return (
    <>
      <Select
        {...props}
        options={options}
        value={value}
        onChange={handleChange}
      />
      {value && (
        <button onClick={resetValue}>
          <svg
            className='w-6 h-6 text-gray-800 dark:text-white'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            viewBox='0 0 24 24'
          >
            <path
              fillRule='evenodd'
              d='M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z'
              clipRule='evenodd'
            />
          </svg>
        </button>
      )}
    </>
  )
}

export const getMondayDate = (weekYearString) => {
  const [week, year] = weekYearString.split('.').map(Number)

  if (!week || !year) {
    return null // Invalid input
  }

  const januaryFirst = new Date(year, 0, 1)
  const daysToMonday = ((8 - januaryFirst.getDay()) % 7) + (week - 1) * 7

  const mondayDate = new Date(januaryFirst)
  mondayDate.setDate(januaryFirst.getDate() + daysToMonday)

  return mondayDate
}

export const actionCompSelector = (requestData, setShowChangeRequestBanner, setMessageForBanner, setShowAlert) => {
  // console.log('data received from CR details: ', requestData)
  let actionTaken = false
  if (requestData?.status?.toLowerCase() === 'open') {
    if (requestData?.supplier_response === 'Awaiting Response') {
      if (requestData?.request_type?.toLowerCase() === 'confirm') {
        return <ConfirmActionComp requestData={requestData}
          setShowChangeRequestBanner={setShowChangeRequestBanner} setMessageForBanner={setMessageForBanner} />
      } else if (requestData?.request_type?.toLowerCase() === 'reconfirm') {
        return <ReconfirmActionComp requestData={requestData}
          setShowChangeRequestBanner={setShowChangeRequestBanner} setMessageForBanner={setMessageForBanner} />
      } else if (
        requestData?.request_type?.toLowerCase() === 'expedite' ||
        requestData?.request_type?.toLowerCase() === 'divert' ||
        requestData?.request_type?.toLowerCase() === 'defer'
      ) {
        return <ExpediteActionComp requestData={requestData}
          setShowChangeRequestBanner={setShowChangeRequestBanner}
          setMessageForBanner={setMessageForBanner} setShowAlert={setShowAlert} />
      }
    } else if (requestData?.supplier_response === 'ACCEPTED') {
      actionTaken = true
    } else if (requestData?.supplier_response === 'REJECTED') {
      actionTaken = true
    }
  } else if (
    requestData?.status?.toLowerCase() === 'closed' ||
    requestData?.status?.toLowerCase() === 'canceled'
  ) {
    actionTaken = true
  }
  // console.log('actionTaken', requestData?.supplier_response)

  return actionTaken ? (
    <div className='flex justify-center items-center'>
      {requestData?.supplier_response === 'ACCEPTED' && (
        <svg
          className='w-6 h-6 text-green-600'
          title="Action"
          aria-hidden='true'
          xmlns='http://www.w3.org/2000/svg'
          fill='currentColor'
          viewBox='0 0 20 20'

        >
          <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
        </svg>

      )}
      {requestData?.supplier_response === 'REJECTED' && (
        <svg
          className='w-6 h-6 text-red-500'
          aria-hidden='true'
          xmlns='http://www.w3.org/2000/svg'
          fill='currentColor'
          viewBox='0 0 20 20'
        >
          <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z' />
        </svg>
      )}
    </div>
  ) : (
    <></>
  )
}

// export const getPresentShipWeek = () => {
//   var today = new Date()
//   // Calculate today's week no
//   // Make Sunday's day number 7
//   today.setUTCDate(today.getUTCDate() + 4 - (today.getUTCDay() || 7))
//   // Get first day of year
//   var yearStart = new Date(Date.UTC(today.getUTCFullYear(), 0, 1))
//   // Calculate full weeks to nearest Thursday
//   var currWeek = Math.ceil(((today - yearStart) / 86400000 + 1) / 7)
//   var year = today.getFullYear().toString().slice(-2)
//   return currWeek.toString() + '.' + year
// }

// If requested ship week in data is greater than today's ship day return requested ship day
// If today's week has passed requested ship week, return current week
// export const getPossibleShipWeek = (weekString) => {
//   if (!weekString)
//     return
//   const [requestedWeek, requestedYear] = weekString.split('.').map(Number);
//   const presentShipWeek = getPresentShipWeek();
//   const [presentWeek, presentYear] = presentShipWeek.split('.').map(Number);

//   if (presentYear < requestedYear || (presentYear === requestedYear && presentWeek < requestedWeek)) {
//     return weekString;
//   }
//   return presentShipWeek;
// };

export const getPresentShipWeek = () => {
  const today = new Date();
  const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
  const weekNumber = Math.ceil(((today - firstDayOfYear) / (7 * 24 * 60 * 60 * 1000)) + 1);
  const year = today.getFullYear() % 100;

  return `${weekNumber}.${year}`;
};

export const getPossibleShipWeek = (weekString) => {
  if (!weekString) return null;

  const [requestedWeek, requestedYear] = weekString.split('.').map(Number);
  const presentShipWeek = getPresentShipWeek();
  const [presentWeek, presentYear] = presentShipWeek.split('.').map(Number);

  if (
    presentYear < requestedYear ||
    (presentYear === requestedYear && presentWeek < requestedWeek)
  ) {
    return weekString;
  }

  return presentShipWeek;
};





// for diferent screen size
export function isScreenTooSmall() {
  return window.innerWidth < 480; // You can adjust the pixel value as needed
}


export function isScreenBigForSideBar() {
  return window.innerWidth > 845; // You can adjust the pixel value as needed
}

// export const getStartDate = (weekString, weekOffset = 0) => {
//   if (!weekString)
//     return
//   let [week, year] = weekString?.split('.').map(Number)
//   week = week + weekOffset
//   const januaryFirst = new Date(year, 0, 1)
//   const dayOffset = (8 - januaryFirst.getDay()) % 7
//   const calculatedDate = new Date(
//     januaryFirst.getTime() +
//     (week - 1) * 7 * 24 * 60 * 60 * 1000 +
//     dayOffset * 24 * 60 * 60 * 1000
//   )
//   calculatedDate.setFullYear(year + 2000)
//   return calculatedDate
// }
export const getStartDate = (weekString, weekOffset = 0) => {
  if (!weekString) return null;

  let [week, year] = weekString.split('.').map(Number);
  week += weekOffset;

  // Adjust for two-digit year format
  year += 2000;

  // January 1st of the given year
  const januaryFirst = new Date(year, 0, 1);

  // Calculate the offset to the first Monday of the year
  const dayOffset = (8 - januaryFirst.getDay()) % 7;

  // Calculate the start date for the specified week
  const calculatedDate = new Date(
    januaryFirst.getTime() +
    (week - 1) * 7 * 24 * 60 * 60 * 1000 + // weeks to milliseconds
    dayOffset * 24 * 60 * 60 * 1000 // offset to first Monday
  );

  return calculatedDate;
};


export const nullOriginal = (requestedShipWeek) => {
  const [requestedWeek, requestedYear] = requestedShipWeek
    ?.split('.')
    ?.map(Number)
  const newWeek = requestedWeek + 4
  const originalShipWeek = `${newWeek}.${requestedYear}`
  return getStartDate(originalShipWeek)
}

export const updateActionHistory = (action, requestData) => {
  const user = JSON.parse(localStorage.getItem('userData'))
  const action_payload = []

  console.log('UpdateAction ran and reqestData:', requestData)

  requestData?.map((request) => {
    const payload = {
      hftcrid:
        request?.potype === 'URGENT' && user?.roleName === 'SUPPLIER_ROLE'
          ? request?.idopenrequest
          : request?.id_open_request,
      po_number: request?.po_number,
      added_by_role_name: user?.roleName?.split('_')?.[0],
      added_by_name:
        user?.roleName?.split('_')?.[0]?.toLowerCase() === 'buyer'
          ? user?.fullName
          : user?.supplierName,
      comment: `Change Request ${action}`,
      type: 'Action',
      SKU: request?.sku
    }
    action_payload?.push(payload)
    return action_payload
  })
  //create_cr_activity uncommented.16-Oct-2024
  // create-cr-activity commented on 8-nov-24
  //  axios
  //    .post(
  //      `${process.env.REACT_APP_API_DOMAIN}:8088/api/create-cr-activity`,
  //      action_payload, ValidateAPI
  //    )
  //    .then((response) => {
  //      // console.log("response from submitting action: ", response?.data);
  //    })
  //    .catch((error) => {
  //      console.log('error from comments submission: ', error)
  //    })
}

export const createUserPreference = (id) => {
  const clmnVisibility = JSON.parse(localStorage.getItem('toggle_columns'))
  const clmnFilter = JSON.parse(localStorage.getItem('column_filters'))
  const clmnOrder = JSON.parse(localStorage.getItem('po_list_order'))
  const payload = {
    hftUserId: id,
    poListColumns: clmnOrder,
    toggleColumns: clmnVisibility,
    clFilters: clmnFilter
  }

  axios
    .post(
      `${process.env.REACT_APP_API_DOMAIN}:8088/api/create-user-preference`,
      payload, ValidateAPI()
    )
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log('error while creating User pref: ', error)
    })
}

export const updateUserPreference = (id) => {
  const clmnVisibility = JSON.parse(localStorage.getItem('toggle_columns'))
  const clmnFilter = JSON.parse(localStorage.getItem('column_filters'))
  const clmnOrder = JSON.parse(localStorage.getItem('po_list_order'))
  const payload = {
    hftUserId: id,
    poListColumns: clmnOrder,
    toggleColumns: clmnVisibility,
    clFilters: clmnFilter
  }
  // axios
  //   .put(
  //     `${process.env.REACT_APP_API_DOMAIN}:8088/api/edit-user-preference`,
  //     payload, ValidateAPI()
  //   )
  //   .then((response) => {
  //     return response?.data
  //   })
  //   .catch((error) => {
  //     console.log('error while updating User pref: ', error)
  //   })
}

export const getUserPreference = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-user-preference-list?hftUserId=${id}`, ValidateAPI()
    )
    localStorage.setItem(
      'po_list_order',
      JSON.stringify(response?.data?.poListColumns)
    )
    localStorage.setItem(
      'column_filters',
      JSON.stringify(response?.data?.clFilters)
    )
    localStorage?.setItem(
      'toggle_columns',
      JSON.stringify(response?.data?.toggleColumns)
    )
    return response?.data
  } catch (error) {
    console.log('error while fetching User pref: ', error)
    return null
  }
}

// export const handleAcceptExpediteDeferRequests = (
//   setRequestData,
//   selectedRowArray,
//   loadingState,
//   rejectModal,
//   onS

// ) => {
//   console.log('data recvd in accept request: ', selectedRowArray)
//   loadingState(true)
//   const req_payload = []
//   const user = JSON.parse(localStorage.getItem('userData'))
//   selectedRowArray?.map((requestData, index) => {
//     const payload = {
//       id_open_request: requestData?.id_open_request,
//       id_po_supplier_portal: Number(requestData?.id_po_supplier_portal),
//       original_dc: requestData?.original_dc,
//       requested_dc: requestData?.requested_dc,
//       original_ship_week: Number(requestData?.original_ship_week),
//       requested_ship_week: Number(requestData?.requested_ship_week),
//       original_qty: requestData?.original_qty,
//       requested_qty: requestData?.requested_qty,
//       inventory_analyst_id: 1,
//       current_ship_week: requestData?.requested_ship_week,
//       vendor_selected_sw: Number(requestData?.requested_ship_week),
//       supplier_contact_id: user?.vendorContactOrcaleId,
//       on_time_target: requestData?.requested_ship_week,
//       ship_week_delay: Math.abs(
//         weeksInBetween(
//           requestData?.current_ship_week,
//           requestData?.current_ship_week
//         )
//       )
//     }
//     req_payload?.push(payload)
//     return req_payload
//   })

//   axios
//     .post(
//       `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-accept-request`,
//       req_payload, ValidateAPI()
//     )
//     .then((response) => {
//       console.log('Response from accpt CR: ', response?.data)
//       selectedRowArray?.map((requestData) => {
//         return updateActionHistory('Accepted', [requestData])
//       })
//       rejectModal(false)
//       loadingState(false)
//       alert(i18next.t('PopUpMessage.ChangeRequestacceptedsuccessfully'))
//       window.location.reload()
//     })
//     .catch((err) => {
//       console.log('err while accepting CR: ', err)
//       rejectModal(false)
//       loadingState(false)
//       alert(
//         i18next.t('PopUpMessage.ErrorwhileacceptingChangeRequestPleasetryagain')
//       )
//       window.location.reload()
//     })
// }
export const handleAcceptExpediteDeferRequests = (
  setRequestData,
  selectedRowArray,
  loadingState,
  rejectModal,
  onSuccessCallback
) => {
  console.log('data recvd in accept request: ', selectedRowArray);
  loadingState(true);
  const req_payload = [];
  const user = JSON.parse(localStorage.getItem('userData'));

  selectedRowArray?.map((requestData) => {
    const payload = {
      id_open_request: requestData?.id_open_request,
      id_po_supplier_portal: Number(requestData?.id_po_supplier_portal),
      original_dc: requestData?.original_dc,
      requested_dc: requestData?.requested_dc,
      original_ship_week: Number(requestData?.original_ship_week),
      requested_ship_week: Number(requestData?.requested_ship_week),
      original_qty: requestData?.original_qty,
      requested_qty: requestData?.requested_qty,
      inventory_analyst_id: 1,
      current_ship_week: requestData?.requested_ship_week,
      vendor_selected_sw: Number(requestData?.requested_ship_week),
      supplier_contact_id: user?.vendorContactOrcaleId,
      on_time_target: requestData?.requested_ship_week,
      ship_week_delay: Math.abs(
        weeksInBetween(
          requestData?.current_ship_week,
          requestData?.current_ship_week
        )
      )
    };
    req_payload?.push(payload);
    return req_payload;
  });

  axios
    .post(
      `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-accept-request`,
      req_payload, ValidateAPI()
    )
    .then((response) => {
      console.log('Response from accept CR: ', response?.data);
      selectedRowArray?.map((requestData) => {
        return updateActionHistory('Accepted', [requestData]);
      });
      rejectModal(false);
      loadingState(false);
      if (onSuccessCallback) onSuccessCallback(true, response.data);
      // window.location.reload();

    })
    .catch((err) => {
      console.log('err while accepting CR: ', err);
      rejectModal(false);
      loadingState(false);
      if (onSuccessCallback) onSuccessCallback(false);
      alert(i18next.t('PopUpMessage.ErrorwhileacceptingChangeRequestPleasetryagain'));
      // window.location.reload();
    });
};


export const handleRejectExpediteDeferDivertRequest = (
  setRequestData,
  selectedRowArray,
  reasonCodeValue,
  setShowRejectModal,
  setreasonCodeValue,
  loadingState,
  onSuccessCallback,
) => {
  if (!reasonCodeValue?.length > 0)
    return alert(i18next.t('PopUpMessage.Pleaseselectareasoncode'))
  loadingState(true)
  const user = JSON.parse(localStorage.getItem('userData'))
  const req_payload = []
  selectedRowArray?.map((requestData, index) => {
    const payload = {
      id_open_request: requestData?.id_open_request,
      id_po_supplier_portal: requestData?.id_po_supplier_portal,
      original_dc: requestData?.original_dc,
      requested_dc: requestData?.requested_dc,
      original_ship_week: requestData?.original_ship_week,
      requested_ship_week: requestData?.requested_ship_week,
      reason_code_ship_week: requestData?.request_type !== 'Divert' ? reasonCodeValue : '',
      reason_code_dc: requestData?.request_type === "Divert" ? reasonCodeValue : '',
      original_qty: requestData?.original_qty,
      requested_qty: requestData?.original_qty,
      reason_code_qty: '',
      inventory_analyst_id: 1,
      supplier_contact_id: user?.vendorContactOrcaleId,
      current_ship_week: requestData?.current_ship_week,
      on_time_target: requestData?.on_time_target,
      status: 'Closed'
    }
    req_payload?.push(payload)
    return req_payload
  })

  console.log('payload before reject request: ', req_payload)
  axios
    .post(
      `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-reject-request`,
      req_payload, ValidateAPI()
    )
    .then((response) => {
      console.log('response from reject request: ', response?.data)

      selectedRowArray?.map((requestData) => {
        return updateActionHistory('Rejected', [requestData])
      })
      setRequestData(response?.data)
      loadingState(false)
      setShowRejectModal(false)
      setreasonCodeValue('')
      if (onSuccessCallback) onSuccessCallback(true, response.data)
      // alert(i18next.t('PopUpMessage.ChangeRequestrejectedsuccessfully'))
      // window.location.reload()
    })
    .catch((error) => {
      console.log('error while rejecting req: ', error)
      if (onSuccessCallback) onSuccessCallback(true)
      alert(
        i18next.t('PopUpMessage.ErrorwhilerejectingChangeRequestPleasetryagain')
      )
      loadingState(false)
      setShowRejectModal(false)
      // window.location.reload();
    })
}
const token = Cookies.get('idcs_user_assertion')
const role = Cookies.get('role')
const getToken = ()=>{
  return Cookies.get('idcs_user_assertion')
}
// export const ValidateAPI = {
  
//   headers: {
//     'Content-Type': 'application/json',
//     'Authorization': `Bearer ${getToken()}`,
//     'Role':  Cookies.get('role')
//   }
// }
export const ValidateAPI = () => ({
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${Cookies.get('idcs_user_assertion')}`, // Always fetch the latest token
    'Role': Cookies.get('role'),
  },
});
export const getallDcList = async () => {
  let getvaLue = false

  axios
    .get(`${process.env.REACT_APP_API_DOMAIN}:8088/api/get-dc-list`, ValidateAPI())
    .then((response) => {
      // console.log(response?.data);
      sessionStorage.setItem('dcList', JSON.stringify(response?.data))
      console.log(response?.data)
    })
    .catch((error) => {
      // alert(error?.response?.data);
    })
  return getvaLue
}
export const checkstatus = (value) => {
  if (value === 'N') return 'Not Processed'
  else if (value === 'I') return 'In Progress'
  else if (value === 'P') return 'Processed'
  else if (value === 'E') return 'Closed With Exception'
  else if (value === 'NA') return 'Not Applicable'
}

export function getCookie(name) {
  return Cookies.get(name);
}

export function setCookie(name, value, options = {}) {
  Cookies.set(name, value, options);
}

export function deleteCookie(name) {
  Cookies.remove(name);
}

export function getLocalStorageItem(key) {
  return localStorage.getItem(key);
}

export function clearLocalStorage() {
  localStorage.clear();
}
export function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}
export const DatefilterFn = (row, id, value) => {
  // const start = value.start
  // const end = value.end
  // if(row?.original?.created_at)
  // {
  //   const data = new Date(formatDateFit(row?.original?.created_at))
  // return data >= start && data <= end;
  // }
  // getting date format like 08/26/2024 10:34:50 AM from API 
  const start = new Date(value.start)
  const end = new Date(value.end)
  const paramValue = row.getValue(id)
  // if (!paramValue) return ''
  
  const day = paramValue?.slice(3,5)
  const month = paramValue?.slice(0, 2)
  const year = paramValue?.slice(6, 10)
  const formattedDate = `${month}-${day}-${year}`
  const data = new Date(formattedDate)
  console.log(paramValue);
  
  return data >= start && data <= end;
}
// calculate ship week no of the year
export function getWeekOfTheYear(date) {
  const currentDate = new Date(date)
  const currentYear = currentDate.getFullYear()
  const firstDayOfYear = new Date(currentYear, 0, 1)
  const days = Math.round(
    (currentDate - firstDayOfYear) / (24 * 60 * 60 * 1000)
  )
  let weekNumber = Math.ceil((days + firstDayOfYear.getDay() + 1) / 7)
  weekNumber = weekNumber === 53 ? 1 : weekNumber
  return `${weekNumber}.${currentYear % 100}`
}


export function changeDateFormat(inputWeekYear) {
  if (!inputWeekYear)
    return
  const [isoWeek, isoYear] = inputWeekYear.split('.')
  const fullYear = `20${isoYear.padStart(2, '0')}`
  const firstThursday = new Date(fullYear, 0, 4);
  firstThursday.setDate(firstThursday.getDate() + 3 - (firstThursday.getDay() + 6) % 7);

  // // Calculate the date of the first day of the ISO week
  const daysFromFirstThursday = (isoWeek - 1) * 7;

  // // The first day of the given ISO week
  const date = new Date(firstThursday);
  date.setDate(firstThursday.getDate() + daysFromFirstThursday - 3); // Adjusting to get Monday of that week
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;

}
export function filterFnSWDate(row, id, value) {

  if (!value || typeof value !== 'string') {
    // Handle the case where value is null, undefined, or not a string
    return false // Or return any default value based on your logic
  }
  const [startRange, endRange] = value.split('-') // Assuming value is in "startRange-endRange" format
  if (!startRange || !endRange) {
    // Handle the case where startRange or endRange is missing
    return false // Or return any default value based on your logic
  }
  const reqSwDate = row.getValue(id) // Assuming the column value is in ww.yy format
  if (!reqSwDate) {
    // Handle the case where reqSwDate is null or undefined
    return false // Or return any default value based on your logic
  }
  const [reqWeek, reqYear] = reqSwDate.split('.')
  const reqWeekYear = parseInt(reqWeek) + parseInt(reqYear) * 100

  const [startWeek, startYear] = startRange.split('.')
  const startWeekYear = parseInt(startWeek) + parseInt(startYear) * 100

  const [endWeek, endYear] = endRange.split('.')
  const endWeekYear = parseInt(endWeek) + parseInt(endYear) * 100

  return reqWeekYear >= startWeekYear && reqWeekYear <= endWeekYear

}



export function CalculateTableArray(limit, arr = []) {
  let temp = 10; // Starting value for temp
  const value = (limit, arr = []) => {
    if (temp > limit) {
      arr.push(limit); // Add the limit to the array when temp exceeds limit
      return arr; // Return the array
    }
    if (temp > 100) {
      arr.push(limit); // Add the limit to the array when temp exceeds limit
      return arr;
    }
    arr.push(temp); // Store the current value of temp in the array
    temp += 10; // Increment temp by 10
    return value(limit, arr);
  } // Recursive call with the updated array
  return value(limit)
};
export function highLightDateField(currSW) {
  const value = [currSW && { 'bg-green-500 rounded-md': [new Date(changeDateFormat(currSW))] }]
  return value

}
export function OpenSpecificDate(currSW) {
  return currSW && getStartDate(getPossibleShipWeek(currSW))
}


export function getDateofMonday(inputWeekYear) {

  if (!inputWeekYear)
    return
  const [isoWeek, isoYear] = inputWeekYear.split('.')
  const fullYear = `20${isoYear.padStart(2, '0')}`
  const firstThursday = new Date(fullYear, 0, 4);
  firstThursday.setDate(firstThursday.getDate() + 3 - (firstThursday.getDay() + 6) % 7);

  // // Calculate the date of the first day of the ISO week
  const daysFromFirstThursday = (isoWeek - 1) * 7;

  // // The first day of the given ISO week
  const date = new Date(firstThursday);
  date.setDate(firstThursday.getDate() + daysFromFirstThursday - 3); // Adjusting to get Monday of that week
  return date;


}

export const validateShipWeekInput1 = (value, currentShipWeek ) => {
  if (value.length !== 5 || !/^\d{2}\.\d{2}$/.test(value)) {
    // Return as valid if format is incomplete
    return { isValid: true, message: "" };
  }

  const [weekStr, yearStr] = value.split('.');
  const week = parseInt(weekStr, 10);
  const year = parseInt(yearStr, 10) + 2000; // Convert YY to YYYY

  const [currentWeekStr, currentYearStr] = currentShipWeek.split('.');
  const currentWeek = parseInt(currentWeekStr, 10);
  const currentYear = parseInt(currentYearStr, 10) + 2000;
  const presentShipWeek = getPresentShipWeek();
  console.log(presentShipWeek);
  const [presentWeek, presentYear] = presentShipWeek.split('.').map(Number);
  const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  const maxWeek = isLeapYear ? 53 : 52;
 
  if (week > maxWeek) {
    return { isValid: false, message: '' };
  }

  if (year === currentYear && week === currentWeek) {
    return { isValid: false };
  } else if (year < currentYear || (year === currentYear && week <= currentWeek)) {
    return { isValid: false };

  } else if (weekStr < presentWeek && yearStr == presentYear) {
    // console.log("exe");
    return { isValid: false }
  } else {
    return { isValid: true, message: "" };
  }
};


export const validateShipWeekInput = (value, currentShipWeek) => {
  if (value.length !== 5 || !/^\d{2}\.\d{2}$/.test(value)) {
    return { isValid: true, message: "" }; // Assume valid if format is incomplete
  }


  const [weekStr, yearStr] = value.split('.');
  const week = parseInt(weekStr, 10);
  const year = parseInt(yearStr, 10) + 2000; // Convert YY to YYYY


  const [currentWeekStr, currentYearStr] = currentShipWeek.split('.');
  const currentWeek = parseInt(currentWeekStr, 10);
  const currentYear = parseInt(currentYearStr, 10) + 2000;


  const today = new Date();
  const todayYear = today.getFullYear();
  const startOfYear = new Date(todayYear, 0, 1);
  const daysDifference = Math.floor((today - startOfYear) / (24 * 60 * 60 * 1000));
  const todayWeek = Math.ceil((daysDifference + startOfYear.getDay() + 1) / 7);


  const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  const maxWeek = isLeapYear ? 53 : 52;


  if (week > maxWeek) {
    return { isValid: false, message: "" };
  }
  if (year === currentYear && week === currentWeek) {
    return { isValid: false, message: "" };
  }
  if (year < todayYear || (year === todayYear && week <= todayWeek)) {
    return { isValid: false, message: "" };
  }


  return { isValid: true, message: "" };
};

export const multipleReasonCode = [
  {
    type: 'common',
    value: 'Capacity or Production Slow Down'
  },
  {
    type: 'common',
    value: 'Equipment/Mold Maintenance'
  },
  {
    type: 'common',
    value: 'LCL Shipment Consolidation'
  },
  {
    type: 'common',
    value: 'Material or Labor Shortage'
  },
  {
    type: 'common',
    value: 'Public Holiday'
  },
  {
    type: 'common',
    value: 'Quality Issue / Product Change'
  },
  {
    type: 'common',
    value: 'Force Majeure / Weather Events'
  },
  {
    type: 'common',
    value: 'Freight Forwarder Consolidation'
  },
  {
    type: 'common',
    value: 'Vessel Delay'
  },
  {
    type: 'Divert',
    value: 'Packaging Printed'
  },
  {
    type: 'Divert',
    value: 'Shipping Space Booked'
  },
  {
    type: 'Divert',
    value: 'Vessel Departure'
  },
  {
    type: 'Divert',
    value: 'Custom Clearance Completed'
  },
  {
    type: 'ConfirmQTY',
    value: 'Master Carton QTY'
  },
  {
    type: 'ConfirmQTY',
    value: 'MOQ'
  },
  {
    type: 'ConfirmQTY',
    value: 'FCL'
  }
]






