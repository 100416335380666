import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import UserProfile from '../../components/UserProfile'
import ConfirmTable from '../../components/ConfirmTable'
import axios from 'axios'
import { formatDate } from '../buyer/BuyerRequest'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  DatefilterFn,
   ValidateAPI,
  changeDateFormat,
  getDateofMonday,
  getPossibleShipWeek,
  getStartDate,
  handlePODetailsClick,
  reasonCodes,
  reasonCodesEN,
  reasonCodesMN,
  updateActionHistory,
  validateShipWeekInput,
  validateShipWeekInput1
} from '../../utils/Utils'
import { getWeek, getWeekDifferenceStatus } from '../buyer/NewPoList'
import Loading from '../../components/Loading'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import PopupModal from '../../components/PopupModal'
import PopupBanner from '../../components/PopupBanner'
import { CartState } from '../../Context/Context'
import { formatDateFit } from '../buyer/BuyerRequestDetails'
import ReasonCodeList, { ReasonCodeView } from '../../components/ReasonCodeList'
export const weeksInBetween = (startWeekYear, endWeekYear) => {
  console.log(`startWeekYear: ${startWeekYear} and endWeekYear: ${endWeekYear}`)

  const [startWeek, startYear] = startWeekYear?.split('.').map(Number)
  const [endWeek, endYear] = endWeekYear?.split('.').map(Number)

  if (!startYear || !startWeek || !endYear || !endWeek) {
    return 0
  }

  const startWeeks = startYear * 52 + startWeek
  const endWeeks = endYear * 52 + endWeek

  const difference = endWeeks - startWeeks

  return difference
}

const ConfirmPo = () => {
  const navigate = useNavigate()
  const [inputsw, setinputsw] = useState('')
  const [showConfirmOrdersModal, setShowConfirmOrdersModal] = useState(false)
  const [showChangeRequestBanner, setShowChangeRequestBanner] = useState(false)
  const [showConfirmOrdersBanner, setshowConfirmOrdersBanner] = useState(false)
  const [bannerMessage, setBannerMessage] = useState('')
  const [editCrIdResponse, setEditCrIdResponse] = useState('')
  const [editPoRow, setEditPORow] = useState('')
  const [poRow, setPoRow] = useState('')
  const [crIdResponse, setCrIdResponse] = useState('')
  const [showRequestChangeModal, setShowRequestChangeModal] = useState(false)
  const user = JSON.parse(localStorage.getItem('userData'))
  const [selectedRowArray, setSelectedRowArray] = useState([])
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [showReasonCode, setShowReasonCode] = useState(false)
  const [reasonCodeValue, setreasonCodeValue] = useState('')
  const [date, setDate] = useState(new Date())
  const [secondReason, setSecondReason] = useState('')
  const [requestSW, setRequestSW] = useState('')
  const [requestDC, setRequestDC] = useState('')
  const [comment, setComment] = useState('')
  const [dc_list, setDCList] = useState(
    JSON.parse(sessionStorage.getItem('dcList'))
  )
  const [submitting, setSubmitting] = useState(false)
  const [rowSelection, setRowSelection] = useState({})
  const { updateDataCount } = CartState()
  const { t } = useTranslation()
  const datePickerRef = useRef(null)
  const [validSw, setValidSw] = useState(false)
  const [poMessage, setPoMessage] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')
  const tableId = 'ConfirmTable' // Define tableId here
  const {
    SelectDC,
    SelectedSW,
    Comment_load,
    POConfirmOrders,
    Home,
    ConfirmPOOrders,
    RequestChange,
    ConfirmOrders,
    Loading1,
    PleaseselectReasoncodefortheselectedorders,
    ReasonCode,
    Selectareason,
    Changetype,
    Selectatype,
    DC,
    Shipweek,
    Price,
    Quantity,
    // RequestChange:"请求更改",
    Cancel,
    Submitting_load,
    Typehereindetail
  } = t('ConfirmPOpage')
  const handleCRDetailsClick = (value) => {
    console.log('cr id from hcrc: ', value)
    const detailsUrl = `/vendor/po_change_requests/request_details/${value}`
    const popupWindow = window.open(
      detailsUrl,
      '_blank',
      'width=1100,height=800,resizable=yes,scrollbars=yes'
    )
    if (popupWindow) {
      popupWindow.focus()
    }
  }
  const headers = {
    CR: t('ConfirmPOpage.CR'),
    PONUMBER: t('ConfirmPOpage.PONUMBER'),
    SKU: t('ConfirmPOpage.SKU'),
    DESCRIPTION: t('ConfirmPOpage.DESCRIPTION'),
    // REQSW: t('ConfirmPOpage.REQSW'),
    CHANGEDSW: t('POList.CHANGEDSW'),
    // CURSW: t('ConfirmPOpage.CURSW'),
    CURRSW: t('POList.CURRENTSW'),
    DC: t('ConfirmPOpage.DC'),
    QUANTITY: t('ConfirmPOpage.QUANTITY'),
    VENDORRESPONSE: t('ConfirmPOpage.VENDORRESPONSE'),
    CREATEDAT: t('ConfirmPOpage.CREATEDAT'),
    REQDC: t('ConfirmPOpage.REQDC'),
    STATUS: t('ConfirmPOpage.STATUS'),
    ORGSW: t('ConfirmPOpage.ORGSW')
  }
  const columns = useMemo(
    () => [
      {
        header: headers.CR,
        accessorKey: 'id_open_request',
        cell: (info) => (
          <p
            className='underline cursor-pointer text-blue-500'
            onClick={() => handleCRDetailsClick(info?.getValue())}
          >
            {info?.getValue()}
          </p>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.PONUMBER,
        accessorKey: 'po_number',
        cell: (info) => {
          return (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() => handlePODetailsClick('vendor', info?.getValue())}
            >
              {info?.getValue()}
            </p>
          )
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.SKU,
        accessorKey: 'sku',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.DESCRIPTION,
        accessorKey: 'description',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        },
        size: 100
      },
      {
        header: headers.CHANGEDSW,
        accessorKey: 'requested_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CURRSW,
        accessorKey: 'current_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.ORGSW,
        accessorKey: 'original_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.DC,
        accessorKey: 'original_dc',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.REQDC,
        accessorKey: 'requested_dc',
        cell: (info) => info?.getValue() ?? '',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: 'Inventory Analyst',
        accessorKey: 'inventory_analyst',
        cell: (info) => info?.getValue() ?? '',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        },
        size: 80
      },

      {
        header: headers.QUANTITY,
        accessorKey: 'original_qty'
      },
      {
        header: headers.VENDORRESPONSE,
        accessorKey: 'supplier_response',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.STATUS,
        accessorKey: 'status',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CREATEDAT,
        accessorKey: 'created_at',
        cell: (info) => formatDateFit(info?.getValue()),
        filterFn: DatefilterFn
      }
    ],
    [headers]
  )

  const handleChangeDate = (date) => {
    console.log(date)
    setDate(date)
    setRequestSW(getWeek(date))
    setValidSw(false)
    setinputsw(getWeek(date))
  }

  const renderBlock = (reason) => {
    if (reason === 'dc') {
      return (
        <div className='flex flex-col space-y-2'>
          <label htmlFor='dc delect' className='text-xs font-medium'>
            Please Select DC
          </label>
          <select
            className='w-full border border-gray-300 p-2 rounded-md text-xs'
            onChange={(e) => setRequestDC(e?.target?.value)}
          >
            <option value='' selected>
              Select DC
            </option>
            {dc_list?.map((data, index) => (
              <option value={data?.DC_NAME} key={data + index}>
                {data?.DC_NAME}
              </option>
            ))}
          </select>
          <div className='flex items-center p-2'>
            <p className='text-xs'>REQ DC : </p>
            <p className='text-xs ml-2'>
              <span className='font-medium p-1 px-2 border border-gray-300 rounded-md text-xs'>
                {selectedRowArray[0].original_dc}
              </span>
            </p>
          </div>
        </div>
      )
    } else if (reason === 'sw') {
      return (
        <div className='w-full flex flex-col  gap-4 justify-between items-center'>
          <div className='flex items-start gap-5 text-xs font-medium w-full'>
            <div className='w-1/2'>
              <p>REQ SW:</p>
              <p className='mt-1 border p-1 text-xs w-full bg-gray-300'>
                {selectedRowArray[0].requested_ship_week}
              </p>
            </div>
            <div className='w-1/2'>
              <p>Select New SW</p>
              <input
                type='text'
                className="p-1 mt-1  border text-xs w-full  bg-yellow-300 placeholder-black"
                placeholder='SW.YY(e.g.,00.00)'
                value={inputsw}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, '')
                  setinputsw(value)

          if (value.length === 5) {
            const validation = validateShipWeekInput1(
              value,
              selectedRowArray[0].requested_ship_week
            );

            if (!validation.isValid) {
              setValidSw(true);
              setValidationMessage(validation.message);
            } else {
              setValidSw(false);
              setValidationMessage("");
              const sw = getPossibleShipWeek(value);
              setRequestSW(sw);
              setDate(getDateofMonday(sw));
              datePickerRef.current.setFocus();
            }
          } else {
            setValidSw(false);
            setValidationMessage("");
          }
        }}
      />
    </div>
  </div>

  {/* Divider */}
  <div className="h-px my-2 bg-gray-500 w-full" />

  {/* Lower Section */}
  <div className="flex items-start gap-5 text-xs font-medium w-full">
    {/* REQ SW Date */}
    <div className="w-1/2">
      <p>REQ SW Date</p>
      <p className="mt-1 border p-1 text-xs w-full bg-gray-300">
        {changeDateFormat(selectedRowArray[0].requested_ship_week)}
      </p>
    </div>
    {/* Select New SW Date */}
    <div className="w-1/2">
      <p>Select New SW Date:</p>
      <DatePicker
        showWeekNumbers
        filterDate={(date) => date.getDay() === 1}
        selected={date}
        onChange={(date) => handleChangeDate(date)}
        className="p-1 mt-1 px-2 border text-xs w-full bg-yellow-300"
        calendarStartDay={1}
        ref={datePickerRef}
        showYearDropdown
        minDate={getStartDate(
          getPossibleShipWeek(selectedRowArray[0].requested_ship_week)
        )}
        excludeDates={[
          new Date(),
          new Date(changeDateFormat(requestSW)),
        ]}
      />
    </div>
  </div>

  {/* Validation Message */}
  {validSw && <p className="text-red-500 text-xs">Enter valid SW!</p>}
</div>

      )
    } else {
      return (
        <div className='w-full flex flex-col gap-2'>
          <label htmlFor='sr_comment' className='text-xs font-medium'>
            {Comment_load}
          </label>
          <textarea
            id='sr_comment'
            className='w-full border border-gray-300 p-2 rounded-md text-xs bg-yellow-300 placeholder-black'
            placeholder={t('ConfirmActionComp.Typehereindetail')}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      )
    }
  }

  const fetchData = () => {
    setLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-supplier-confirm-po?vendorNumber=${user?.supplierId}`,
         ValidateAPI()
      )
      .then((response) => {
        setTableData(response?.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log('Error while fetching data: ', error)
        setLoading(false)
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }

  const closeConfirmOrdersModal = () => {
    setShowConfirmOrdersModal(false)
  }

  const openConfirmOrdersModal = () => {
    setShowConfirmOrdersModal(true)
  }
  const openChangeRequestBanner = () => {
    if (selectedRowArray?.length === 1) {
      setShowChangeRequestBanner(true)
      setshowConfirmOrdersBanner(true)
    }
  }

  const closePopUpModal = () => {
    // setPoRow(false)
    // setShowConfirmOrdersModal(false)
    setshowConfirmOrdersBanner(false)
    setShowChangeRequestBanner(false)
  }

  const closeRequestChangeModal = () => {
    setShowRequestChangeModal(false)
  }

  const openRequestChangeModal = () => {
    setShowRequestChangeModal(true)
  }
  const setBannerData = (data) => {
    if (data) {
      const crIdResponse = data.map((item) => item.id_open_request)
      const poRow = data.map((item) => item.po_number)
      setCrIdResponse(crIdResponse)
      setPoRow(poRow)
    }
  }
  const handleConfirmOrders = () => {
    const request_payload = []
    selectedRowArray?.map((row) => {
      const payload = {
        id_open_request: row?.id_open_request,
        vendorNumber: Number(row?.vendor_number),
        vendorName: row?.vendor_name,
        createdBy: row?.inventory_analyst,
        current_ship_week: row?.requested_ship_week,
        original_ship_week: row?.requested_ship_week,
        hft_user_id: 0,
        supplier_contact_id: user?.vendorContactOrcaleId
      }
      request_payload?.push(payload)
    })
    console.log('Confirm Po payload: ', request_payload)
    setSubmitting(true)
    setShowReasonCode(true)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-confirm-po`,
        request_payload,
         ValidateAPI()
      )
      .then((response) => {
        console.log('response from confirm po: ', response?.data)
        setBannerData(response?.data)
        setshowConfirmOrdersBanner(true)
        setBannerMessage('Change Request Accepted Successfully')
        fetchData()
        setShowReasonCode(false)
        // to update order count in side bar
        updateDataCount(user?.supplierId)
        setSubmitting(false)
        setSelectedRowArray([])
        updateActionHistory('Accepted', selectedRowArray)
      })
      .catch((error) => {
        console.log('error while confirming PO: ', error)
        setShowReasonCode(false)
        setSubmitting(false)
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
    closeConfirmOrdersModal()
  }
  const rejectPop = () => {
    setShowReasonCode(true)
    closeRequestChangeModal()
  }

  const editConfirm = () => {
    const req_payload = []
    if (reasonCodeValue?.length > 0) {
      // const reasons = reasonCodeValue.join(', ')
      if (requestSW?.length > 0 || requestDC?.length > 0) {
        selectedRowArray?.map((item) => {
          const req_obj = {
            hft_cr_id: item?.id_open_request,
            requested_dc: requestDC,
            reason_code_dc: secondReason === 'dc' ? reasonCodeValue : '',
            requested_ship_week: item?.requested_ship_week ?? '',
            reason_code_ship_week: secondReason === 'sw' ? reasonCodeValue : '',
            supplier_response: 'ACCEPTED',
            current_ship_week: requestSW
              ? requestSW
              : item?.requested_ship_week,
            status: 'OPEN',
            followups: 1,
            vendor_selected_ship_week: requestSW,
            original_ship_week: requestSW
              ? requestSW
              : item?.requested_ship_week,
            ship_week_delay: Math.abs(
              weeksInBetween(requestSW ?? '', item?.requested_ship_week)
            ),
            vendorName: item?.vendor_name,
            createdBy: item?.inventory_analyst,
            requestType: 'Confirm',
            hft_user_id: 0,
            supplier_contact_id: user?.vendorContactOrcaleId
          }
          return req_payload?.push(req_obj)
        })
        console.log('payload before edit confirm: ', req_payload)
        setSubmitting(true)
        axios
          .put(
            `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-edit-confirm-po`,
            req_payload,
             ValidateAPI()
          )
          .then((response) => {
            console.log('response from editconfirm: ', response?.data)
            setBannerData(response?.data)
            setshowConfirmOrdersBanner(true)
            setBannerMessage('Change Request Changed Successfully')
            setRequestDC('')
            setRequestSW('')
            setSubmitting(false)
            setShowReasonCode(false)
            setSecondReason('')
            setreasonCodeValue('')
            // setShowChangeRequestBanner(true)
            // to update order count in side bar
            updateDataCount(user?.supplierId)
            openChangeRequestBanner()
            fetchData()
            updateActionHistory('Accepted', selectedRowArray)

            // alert("changes made successfully.");
            // alert(i18next.t('VendorPopUp.changesmadesuccessfully'))
          })
          .catch((error) => {
            console.log('error while submitting edit confirm: ', error)
            setSubmitting(false)
            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              navigate('/login-error')
            }
          })
      } else {
        alert(i18next.t('VendorPopUp.Pleaseaddnewvaluebeforesubmitting'))
      }
    } else {
      alert(
        i18next.t(
          'VendorPopUp.PleaseselectReasonCodeChangeTypebeforesubmitting'
        )
      )
    }
  }

  const handleRejectPos = () => {
    const request_payload = []
    const comments_payload = []
    if (!reasonCodeValue?.length > 0)
      return alert(i18next.t('VendorPopUp.Pleaseselectareasoncode'))
    if (!comment)
      return alert(i18next.t('VendorPopUp.Pleaseaddcommentbeforesubmitting'))

    selectedRowArray?.map((row) => {
      const payload = {
        id_open_request: row?.id_open_request,
        vendorNumber: Number(row?.vendor_number),
        reasonCodeShipWeek: reasonCodeValue,
        vendorName: row?.vendor_name,
        createdBy: row?.inventory_analyst,
        original_ship_week: '',
        vendorComment: comment
      }
      const comment_indi = {
        hftcrid: row?.id_open_request,
        po_number: row?.po_number,
        added_by_role_name: user?.roleName?.split('_')?.[0],
        added_by_name: user?.supplierName,
        comment: comment,
        type: 'Comment',
        SKU: row?.sku
      }
      request_payload?.push(payload)
      comments_payload?.push(comment_indi)
    })

    console.log('Reject Po payload: ', request_payload)
    console.log('Reject comments payload: ', comments_payload)
    setSubmitting(true)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-confirm-reject-po`,
        request_payload,
         ValidateAPI()
      )
      .then((response) => {
        console.log('response from reject po: ', response?.data)
        if (response?.data) {
          const editCrIdResponse = response.data.map(
            (item) => item.id_open_request
          )
          const editPoRow = response.data.map((item) => item.po_number)
          console.log('editCrIdResponse:', editCrIdResponse)
          console.log('editpo response', editPoRow)
          setEditPORow(editPoRow)
          setEditCrIdResponse(editCrIdResponse)
          setshowConfirmOrdersBanner(true)
            setBannerMessage('Change Request Rejected Successfully')
        }
        setShowReasonCode(false)
        setSelectedRowArray([])
        setreasonCodeValue('')
        // to update order count in side bar
        updateDataCount(user?.supplierId)
        // setTableData(response?.data)
        fetchData()
        setComment('')
        console.log('response from submitting comment: ', response?.data)
        setSubmitting(false)
        // setShowChangeRequestBanner(true)
        openChangeRequestBanner()
        setBannerData(response?.data)
        setshowConfirmOrdersBanner(true)
        setBannerMessage('Change Request Rejected Successfully')
        // setPopupMessage(i18next.t('VendorPopUp.Orderschangedsuccessfully'));
        updateActionHistory('Rejected', selectedRowArray)
        // axios
        //   // .post(
        //   //   `${process.env.REACT_APP_API_DOMAIN}:8088/api/create-cr-activity`,
        //   //   comments_payload,
        //   //    ValidateAPI()
        //   // )
        //   .then((response) => {

        //   })
        //   .catch((error) => {
        //     console.log('error from comments submission: ', error)
        //     setSubmitting(false)
        //     if (
        //       error.response.status === 401 ||
        //       error.response.status === 403
        //     ) {
        //       navigate('/login-error')
        //     }
        //   })

        // alert(i18next.t('VendorPopUp.Orderschangedsuccessfully'))
      })
      .catch((error) => {
        console.log('error while reject PO: ', error)
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }

  const [lang, setlang] = useState(window.localStorage?.getItem('language'))
  // console.log(lang);

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    console.log('Selection Array has been updated: ', selectedRowArray)
  }, [selectedRowArray])

  useEffect(() => {
    const handleClickOutside = () => {
      setshowConfirmOrdersBanner(false)
    }
    if (showConfirmOrdersBanner) {
      window.addEventListener('click', handleClickOutside)
    }
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showConfirmOrdersBanner])
  useEffect(() => {
    if (selectedRowArray?.length > 0) {
      setPoMessage(false) // Hide the error message as soon as an order is selected
    }
  }, [selectedRowArray])

  const selectType = (event) => {
    setreasonCodeValue('')
    const value = event?.target?.value
    if (value === 'price') {
      setSecondReason(value)
      setreasonCodeValue(
        'If price discrepancy reach out to vendor manager via email'
      )
    } else {
      setSecondReason(value)
    }
  }
  return (
    <div className='w-full flex flex-col gap-6 justify-start items-start p-4'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl'>{POConfirmOrders}</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/vendor')}
          >
            {Home}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span>{ConfirmPOOrders}</span>
          {/* <span>{">"}</span> */}
        </p>
        {/* <p className="flex gap-1 items-center justify-center">
            <span>PO Details - {id}</span>
          </p> */}
      </div>

      <div>
        <div className='flex w-full justify-start items-center font-medium text-xs gap-3'>
        <button
            className='px-3 rounded-md bg-green-500 text-white h-[32px]'
            onClick={() => {
              if (selectedRowArray?.length > 0) {
                openConfirmOrdersModal()
                setPoMessage(false)
              } else {
                setPoMessage(true)
              }
            }}
            // disabled={selectedRowArray?.length === 0}
          >
            Confirm
          </button>
          <PopupModal
            isOpen={showRequestChangeModal}
            message={i18next.t('PopUpMessage.Doyouwanttocreateachangerequest')}
            onCancel={closeRequestChangeModal}
            cancelText={i18next.t('AcceptModal.Cancel')}
            onConfirm={rejectPop}
            confirmText={i18next.t('AcceptModal.Confirm')}
          />
          
          <button
            className='px-3 rounded-md bg-[#ff9500] text-white h-[32px]'
            onClick={() => {
              if (selectedRowArray?.length > 0) {
                openRequestChangeModal()
                setPoMessage(false)
              } else {
                setPoMessage(true)
              }
            }}
            // disabled={selectedRowArray?.length === 0}
          >
            Change
          </button>
          <PopupModal
            isOpen={showConfirmOrdersModal}
            message={i18next.t('PopUpMessage.Doyouwanttoconfirmtheseorders')}
            onCancel={closeConfirmOrdersModal}
            cancelText={i18next.t('AcceptModal.Cancel')}
            onConfirm={handleConfirmOrders}
            confirmText={i18next.t('AcceptModal.Confirm')}
          />
        </div>
        {poMessage && (
          <p className='text-red-500 text-xs mt-2'>Please select a change request.</p>
        )}
      </div>

      {loading ? (
        <div className='w-full min-h-[300px] flex justify-center items-center'>
          <Loading />
        </div>
      ) : (
        <div className='w-full overflow-x-auto'>
          <ConfirmTable
            tableId={tableId}
            tableData={tableData}
            selection={setSelectedRowArray}
            columns={columns}
            updateRowSelectionProp={setRowSelection}
            rowSelectionProp={rowSelection}
          />
        </div>
      )}
      <dialog
        open={showReasonCode}
        className='p-3 inset-0 fixed z-10 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          {submitting ? (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <Loading type={Submitting_load} />
            </div>
          ) : (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <p className='w-full text-center'>
                {PleaseselectReasoncodefortheselectedorders}.
              </p>
              {/* This is to show reasonCodeValue once user selected from list  */}
              <ReasonCodeView
                reasonCodeValue={reasonCodeValue}
                setreasonCodeValue={setreasonCodeValue}
              />
              {/* View End */}
              <div className='w-full flex flex-col gap-2'>
                <label htmlFor='change_type_dd' className='text-xs font-medium'>
                  {Changetype}
                </label>
                <select
                  onChange={selectType}
                  id='change_type_dd'
                  className='p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium'
                  value={secondReason}
                >
                  <option value='' disabled>
                    {Selectatype}
                  </option>
                  {/* <option value='dc'>{DC}</option> */}
                  <option value='sw'>{Shipweek}</option>
                  <option value='price'>{Price}</option>
                  <option value='qty'>{Quantity}</option>
                </select>
              </div>
              {secondReason === 'price' && (
                <div className='w-full flex flex-col items-start gap-2'>
                  <label
                    htmlFor='reason_code_select'
                    className='text-xs font-medium'
                  >
                    {ReasonCode}
                  </label>
                  <select
                    onChange={(e) => setreasonCodeValue(e.target.value)}
                    name='reason_code_select'
                    id='reason_code_select'
                    className='p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium'
                    value={reasonCodeValue}
                  >
                    <option
                      selected
                      value='If price discrepancy reach out to vendor manager via email'
                    >
                      If price discrepancy reach out to vendor manager via email
                    </option>
                  </select>
                </div>
              )}
              {secondReason?.length > 0 && secondReason !== 'price' && (
                //  ReasonCode List Component to view all list and select
                <ReasonCodeList
                  reasonCodeValue={reasonCodeValue}
                  setreasonCodeValue={setreasonCodeValue}
                  type={secondReason === 'qty' ? 'ConfirmQTY' : 'common'}
                />
              )}
              {/* End Component */}
              <div className='w-full'>
                {secondReason?.length > 0 &&
                  reasonCodeValue?.length > 0 &&
                  renderBlock(secondReason)}
              </div>
              <div className='w-full flex justify-center items-center gap-2 mt-4'>
                {secondReason &&
                  (secondReason === 'dc' || secondReason === 'sw' ? (
                    <button
                      className={`text-xs font-medium px-2 rounded-md  ${validSw || requestSW?.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'} h-[38px] text-white bg-[#ff9500] outline-none`}
                      onClick={editConfirm}
                      disabled={validSw || requestSW?.length === 0}
                    >
                      {/* {RequestChange} */}Request
                    </button>
                  ) : (
                    <button
                      className={`text-xs font-medium px-2 rounded-md  ${validSw || requestSW?.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'} h-[38px] bg-[#ff9500] border text-white outline-none`}
                      onClick={handleRejectPos}
                      disabled={validSw || requestSW?.length === 0}
                    >
                      {/* {RequestChange} */}Request
                    </button>
                  ))}
                <button
                  className='text-xs font-medium px-2 rounded-md h-[38px] text-white bg-gray-500 outline-none'
                  onClick={() => {
                    setShowReasonCode(false)
                    setreasonCodeValue('')
                    setRequestSW('')
                    setSecondReason('')
                    setinputsw('')
                    setValidSw('')
                  }}
                >
                  {Cancel}
                </button>
              </div>
            </div>
          )}
        </div>
      </dialog>
      <PopupBanner
        isOpen={showConfirmOrdersBanner}
        message={bannerMessage}
        onCancel={closePopUpModal}
        text={poRow}
        text1={crIdResponse}
      />
    </div>
  )
}

export default ConfirmPo
